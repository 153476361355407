import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { GradientSettings, OutputFormat } from "@progress/kendo-angular-inputs";
import { TemplatesService } from "../../services/templates.service";
import { AuthService } from "../../services/auth.service";
import { SurveyObject } from "../../shared/survey";
import { SurveyLabelsObject } from "../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../shared/utils";
import { SYS_LANGAUGES } from "../../shared/language";

@Component({
  selector: "app-template-mailing-info",
  templateUrl: "./template-mailing-info.component.html",
  styleUrls: ["./template-mailing-info.component.css"],
})
export class TemplateMailingInfoComponent implements OnInit {
  surveyObj = new SurveyObject();
  labelsObj = new SurveyLabelsObject();

  templateKey: string = "mailings";
  initialized: boolean = false;
  selected: any = {
    replytoname: "",
    replytoemail: "",
    subject: "",
    invitation: "",
    mailhtml: "",
    mailtext: "",
    footerhtml: "",
    footertext: "",
    remindersubject: "",
    reminderinvitation: "",
    remindermailhtml: "",
    remindermailtext: "",
    reminderfooterhtml: "",
    reminderfootertext: "",
  };
  language: string = "en";
  languages: any = SYS_LANGAUGES;
  usecustom: boolean = false;
  extensions: any = [".html"];
  importbuttons: boolean = false;
  color: any = "#fe413b";
  settings: GradientSettings = { opacity: false };
  format: OutputFormat = "hex";
  composition: any = {};
  attached: any = {};

  constructor(
    private templatesService: TemplatesService,
    private authService: AuthService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["template"];

    this.InitializeTemplateInfo();
    this.InitializeTemplateComposition();

    this.attached["mailing"] = this.surveyObj.id;
  }

  InitializeTemplateInfo() {
    this.templatesService
      .getTemplateInfo(this.templateKey, this.surveyObj.id)
      .valueChanges()
      .subscribe((info) => {
        if (info) {
          this.surveyObj.info = info;
          this.surveyObj.items = [];

          if (this.surveyObj.info.hasOwnProperty("usecustom"))
            this.usecustom = this.surveyObj.info["usecustom"];

          if (this.surveyObj.info.hasOwnProperty("customcolor"))
            this.color = this.surveyObj.info["customcolor"];
        }
      });
  }

  InitializeTemplateComposition() {
    this.templatesService
      .getTemplateMailingComposition(this.templateKey, this.surveyObj.id)
      .valueChanges()
      .subscribe((composition) => {
        if (composition) {
          this.composition = composition;
        }
        if (!this.composition.hasOwnProperty(this.language)) {
          this.composition[this.language] = {
            replytoname: "",
            replytoemail: "",
            subject: "",
            invitation: "",
            mailhtml: "",
            mailtext: "",
            footerhtml: "",
            footertext: "",
            remindersubject: "",
            reminderinvitation: "",
            remindermailhtml: "",
            remindermailtext: "",
            reminderfooterhtml: "",
            reminderfootertext: "",
          };
        }

        this.initialized = true;
      });
  }

  onItemSelected(item: any) {}

  onChangeColor(event: any) {}

  onPickColor(event: any) {
    this.templatesService.updateTemplateMailingColor(
      this.templateKey,
      this.surveyObj.id,
      this.color,
    );
  }

  onSelectLanguage(event: any) {
    if (!this.composition.hasOwnProperty(this.language)) {
      this.composition[this.language] = {
        replytoname: "",
        replytoemail: "",
        subject: "",
        invitation: "",
        mailhtml: "",
        mailtext: "",
        footerhtml: "",
        footertext: "",
        remindersubject: "",
        reminderinvitation: "",
        remindermailhtml: "",
        remindermailtext: "",
        reminderfooterhtml: "",
        reminderfootertext: "",
      };
    }
  }

  onUseCustomTemplateChange(event: any) {
    this.templatesService.updateTemplateMailingCustom(
      this.templateKey,
      this.surveyObj.id,
      this.usecustom,
    );
  }

  doUpdateTemplate() {
    this.templatesService.updateTemplateMailingComposition(
      this.templateKey,
      this.surveyObj.id,
      this.language,
      this.composition[this.language],
    );
  }

  onGoToTemplatesList() {
    this.router.navigate(["/templates"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
