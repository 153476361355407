<div class="contentblock">
  <h3>Settings</h3>

  <div *ngIf="item.type == 'SUCCESSFACTOR'">
    <div class="form-group row">
      <label for="buildWorcloud" class="col-sm-2 col-form-label"
        >Build Worcloud</label
      >
      <div class="col-sm-10">
        <kendo-switch
          name="buildWorcloud"
          [(ngModel)]="item.buildWorcloud"
          (valueChange)="onChangeSuccessfactor()"
        ></kendo-switch>
      </div>
    </div>
    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Is Navigator</label>
      <div class="col-sm-10">
        <kendo-switch
          name="isNavigator"
          [(ngModel)]="item.isNavigator"
          (valueChange)="onChangeSettings()"
        ></kendo-switch>
      </div>
    </div>
  </div>

  <div *ngIf="item.type == 'INDICATOR'">
    <div class="form-group row">
      <label for="buildWorcloud" class="col-sm-2 col-form-label"
        >Build Worcloud</label
      >
      <div class="col-sm-10">
        <kendo-switch
          name="buildWorcloud"
          [(ngModel)]="item.buildWorcloud"
          (valueChange)="onChangeSettings()"
        ></kendo-switch>
      </div>
    </div>
  </div>

  <div *ngIf="item.type == 'QUESTION'">
    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Is compliment</label>
      <div class="col-sm-10">
        <kendo-switch
          name="isCompliment"
          [(ngModel)]="item.isCompliment"
          (valueChange)="onChangeSettings()"
        ></kendo-switch>
      </div>
    </div>
    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Is tip</label>
      <div class="col-sm-10">
        <kendo-switch
          name="isTip"
          [(ngModel)]="item.isTip"
          (valueChange)="onChangeSettings()"
        ></kendo-switch>
      </div>
    </div>
    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Is anonymity</label>
      <div class="col-sm-10">
        <kendo-switch
          name="isAnonymity"
          [(ngModel)]="item.isAnonymity"
          (valueChange)="onChangeSettings()"
        ></kendo-switch>
      </div>
    </div>
    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Is segment</label>
      <div class="col-sm-10">
        <kendo-switch
          name="isSegment"
          [(ngModel)]="item.isSegment"
          (valueChange)="onChangeSettings()"
        ></kendo-switch>
      </div>
    </div>

    <div *ngIf="item.form == 'TEXT'">
      <div class="form-group row">
        <label for="label" class="col-sm-2 col-form-label"
          >Is Promoter comment</label
        >
        <div class="col-sm-10">
          <kendo-switch
            name="isPromoterComment"
            [(ngModel)]="item.isPromoterComment"
            (valueChange)="onChangeSettings()"
          ></kendo-switch>
        </div>
      </div>
      <div class="form-group row">
        <label for="label" class="col-sm-2 col-form-label"
          >Is Passive comment</label
        >
        <div class="col-sm-10">
          <kendo-switch
            name="isPassiveComment"
            [(ngModel)]="item.isPassiveComment"
            (valueChange)="onChangeSettings()"
          ></kendo-switch>
        </div>
      </div>
      <div class="form-group row">
        <label for="label" class="col-sm-2 col-form-label"
          >Is Detractor comment</label
        >
        <div class="col-sm-10">
          <kendo-switch
            name="isDetractorComment"
            [(ngModel)]="item.isDetractorComment"
            (valueChange)="onChangeSettings()"
          ></kendo-switch>
        </div>
      </div>
      <div class="form-group row">
        <label for="label" class="col-sm-2 col-form-label"
          >Build Worcloud</label
        >
        <div class="col-sm-10">
          <kendo-switch
            name="buildWorcloud"
            [(ngModel)]="item.buildWorcloud"
            (valueChange)="onChangeSettings()"
          ></kendo-switch>
        </div>
      </div>
    </div>
  </div>
</div>
