<div class="contentblock">
  <h3>Order</h3>

  <div *ngIf="item.items">
    <kendo-sortable
      [kendoSortableBinding]="item.items"
      (dragEnd)="onDragEnd($event)"
    >
      <ng-template let-subitem="item">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon> {{ subitem.text }}
      </ng-template>
    </kendo-sortable>

    <button type="button" (click)="AutoSort()">Autosort</button>
  </div>
</div>
