import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private firebase: AngularFireDatabase) {}

  getUsersList(): AngularFireList<any> {
    return this.firebase.list("/Users");
  }

  getUser(user: string): AngularFireObject<any> {
    return this.firebase.object("/Users/" + user);
  }

  updateUserInfo(id: any, info: any) {
    let userRef = this.firebase.object("/Users/" + id + "/info");
    return userRef.set(info);
  }

  updateUserSettings(id: any, settings: any) {
    let userRef = this.firebase.object("/Users/" + id + "/settings");
    return userRef.set(settings);
  }

  deleteUser(user: string): Promise<void> {
    let userRef = this.firebase.object("/Users/" + user);
    if (userRef) return userRef.remove();
  }

  getUserNotifications(user: any): AngularFireList<any> {
    return this.firebase.list("/Notifications/" + user);
  }

  deleteUserNotification(user: any, notification: any) {
    let notificationRef = this.firebase.object(
      "/Notifications/" + user + "/" + notification,
    );
    if (notificationRef) return notificationRef.remove();
  }

  deleteAllUserNotification(user: any) {
    let notificationsRef = this.firebase.object("/Notifications/" + user);
    if (notificationsRef) return notificationsRef.remove();
  }

  markNotificationAsRead(user: any, notification: any) {
    let notificationRef = this.firebase.object(
      "/Notifications/" + user + "/" + notification + "/status/",
    );
    return notificationRef.set("READ");
  }
}
