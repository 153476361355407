import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { User } from "firebase";
import { Observable } from "rxjs";
import { tap, map, take, switchMap } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { guid, generatePassword } from "../shared/utils";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isLogged: boolean;
  user$: Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private fireAuth: AngularFireAuth,
    private firebase: AngularFireDatabase,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.user$ = this.fireAuth.authState;

    this.authStatusListener();
  }

  SignUp(sysuser) {
    let password = generatePassword(12);

    return this.fireAuth
      .createUserWithEmailAndPassword(sysuser.email, password)
      .then((credential) => {
        let info = {
          id: credential.user.uid,
          name: sysuser.name,
          email: sysuser.email,
          role: sysuser.role,
        };
        let userRef = this.firebase.object("/Users/" + info.id + "/info");
        return userRef.set(info);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  SignIn(email, password) {
    return this.fireAuth
      .signInWithEmailAndPassword(email, password)
      .then((credential) => {
        this.SetCurrentUser(credential.user.uid);

        credential.user.getIdToken(true).then((jwtoken) => {
          localStorage.setItem("session", jwtoken);
        });

        this.fireAuth.onIdTokenChanged(async (user) => {
          credential.user.getIdToken(true).then((jwtoken) => {
            localStorage.setItem("session", jwtoken);
          });
        });

        this.InitializeSystemUser(credential.user.uid);
      })
      .catch((error) => {
        if (
          error.code == "auth/wrong-password" ||
          error.code == "auth/invalid-email"
        )
          window.alert(
            "The username or password is incorrect. \n Tip: Please make sure you did not copy any spaces",
          );
        else window.alert(error.message);
      });
  }

  SignOut() {
    return this.fireAuth.signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("userMetaData");
      localStorage.removeItem("session");
      this.router.navigate(["login"]);
    });
  }

  async SendPasswordResetEmail(passwordResetEmail) {
    await this.fireAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert("Password reset email sent, check your inbox.");
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  GetCurrentUser(): string {
    return localStorage.getItem("user");
  }

  SetCurrentUser(user: string) {
    localStorage.setItem("user", user);
  }

  SetCurrentUserMetaData(metadata: any) {
    localStorage.setItem("userMetaData", metadata);
  }

  GetCurrentSession(): string {
    return localStorage.getItem("session");
  }

  GetCurrentUserMetaData(): any {
    return JSON.parse(localStorage.getItem("userMetaData"));
  }

  isAuthenticated(): Observable<boolean> {
    return this.user$.pipe(
      take(1),
      map((authState) => !!authState),
      tap((isLogged) => {
        if (isLogged) {
          if (!this.GetCurrentUser()) {
            this.SignOut();
          }

          let sessionId = this.GetCurrentSession();

          if (sessionId) {
            const params = new HttpParams().set("session", sessionId);

            this.httpClient
              .get<any>(environment.host + "/session/", { params })
              .subscribe((valid) => {
                if (!valid) {
                  this.SignOut();
                }
              });
          }
        }
        if (!isLogged) {
        }
      }),
    );
  }

  InitializeSystemUser(user: string) {
    this.firebase
      .object(`/Users/${user}/`)
      .valueChanges()
      .subscribe((user: any) => {
        if (user) {
          this.SetCurrentUserMetaData(JSON.stringify(user.info));

          this.ngZone.run(() => {
            this.router.navigate(["surveys"]);
          });
        }
      });
  }

  authStatusListener() {
    this.fireAuth.onAuthStateChanged((credential) => {
      if (credential) {
        console.log("Authorization: Status changed");
      } else {
        console.log("Authorization: User is logged out");
      }
    });
  }

  NavigateToURL(url: string) {
    this.router.navigate([url]);
  }
}
