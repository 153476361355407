import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { AuthService } from "../../../../services/auth.service";
import { JobsService } from "../../../../services/jobs.service";
import {
  GridDataResult,
  PageChangeEvent,
  FilterService,
} from "@progress/kendo-angular-grid";
import {
  SortDescriptor,
  CompositeFilterDescriptor,
  orderBy,
  filterBy,
  distinct,
} from "@progress/kendo-data-query";
import {
  guid,
  generatePassword,
  b64_encode_autologin,
} from "../../../../shared/utils";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-survey-respondents",
  templateUrl: "./survey-respondents.component.html",
  styleUrls: ["./survey-respondents.component.css"],
})
export class SurveyRespondentsComponent implements OnInit {
  @Input() survey: any;
  @Input() columns: any;

  initialized: boolean = false;
  respondents: any = [];
  selected: any = null;
  respondentWindowActive: boolean = false;
  loginIndex: number = 1;
  sort: SortDescriptor[] = [{ field: "loginname", dir: "asc" }];
  pageSize: number = 500;
  skip: number = 0;
  gridView: GridDataResult;
  filter: CompositeFilterDescriptor = { logic: "or", filters: [] };
  surveyUrl: string = environment.surveyUrl;

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    private jobsService: JobsService,
  ) {}

  ngOnInit(): void {
    this.InitializeSurveyRespondents();
    this.InitializeSurveyLoginNamesIndex();
  }

  InitializeSurveyRespondents() {
    this.surveysService
      .getSurveyRespondents(this.survey.id)
      .subscribe((respondents) => {
        this.respondents = respondents;

        this.loadRespondents();

        this.initialized = true;
      });
  }

  InitializeSurveyLoginNamesIndex() {
    this.surveysService
      .getSurveyLoginNamesIndex(this.survey.id)
      .valueChanges()
      .subscribe((survey) => {
        if (survey) this.loginIndex = survey.current;
      });
  }

  GetDateFormat(datetime: any): string {
    let inputdate = new Date(Number(datetime));
    return inputdate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  segmentChange(
    values: any,
    filterService: FilterService,
    column: string,
  ): void {
    const thisFilter: CompositeFilterDescriptor = {
      filters: [{ field: column, operator: "eq", value: values.text }],
      logic: "and",
    };
    filterService.filter(thisFilter);
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridView = {
      data: filterBy(this.respondents, filter),
      total: this.respondents.length,
    };
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadRespondents();
  }

  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.loadRespondents();
  }

  loadRespondents(): void {
    this.gridView = {
      data: orderBy(this.respondents, this.sort).slice(
        this.skip,
        this.skip + this.pageSize,
      ),
      total: this.respondents.length,
    };
  }

  onRespondentArchive(respondent: any) {
    if (confirm("Are you sure you want to archive this respondent?") == true) {
      this.jobsService.addJob({
        type: "archive_respondent",
        state: "WAITING_FOR_PROCESS",
        data: {
          survey: this.survey.id,
          user: this.authService.GetCurrentUser(),
          respondent: respondent.id,
        },
      });
    }
  }

  onAddNewRespondent() {
    this.selected = {};
    this.respondentWindowActive = true;
  }

  onOpenRespondentWindow() {
    this.respondentWindowActive = true;
  }

  onCloseRespondentWindow() {
    this.respondentWindowActive = false;
  }

  getRespondentLoginname(): string {
    if (this.loginIndex < 10)
      return this.survey.info.prefix + "_00000" + this.loginIndex;
    if (this.loginIndex < 100)
      return this.survey.info.prefix + "_0000" + this.loginIndex;
    if (this.loginIndex < 1000)
      return this.survey.info.prefix + "_000" + this.loginIndex;
    if (this.loginIndex < 10000)
      return this.survey.info.prefix + "_00" + this.loginIndex;
    if (this.loginIndex < 100000)
      return this.survey.info.prefix + "_0" + this.loginIndex;
    if (this.loginIndex < 1000000)
      return this.survey.info.prefix + "_" + this.loginIndex;
  }

  onRespondentUpdate(item: any) {
    this.respondentWindowActive = false;

    let isNewRespondent = false;

    if (!item.item.hasOwnProperty("id")) {
      isNewRespondent = true;

      item.item["id"] = guid();
      item.item["loginname"] = this.getRespondentLoginname();
      item.item["password"] = generatePassword(12);
      item.item["autologin"] = b64_encode_autologin(
        item.item["loginname"] + "##" + item.item["password"],
      );
      item.item["systime"] = Date.now();

      this.loginIndex = this.loginIndex + 1;
    }

    this.surveysService.updateSurveyRespondent(this.survey.id, item.item);

    if (isNewRespondent) {
      this.surveysService.updateSurveyLoginNamesIndex(
        this.survey.id,
        this.loginIndex,
      );
      this.jobsService.addJob({
        type: "create_authorization",
        state: "WAITING_FOR_PROCESS",
        data: {
          survey: this.survey.id,
          user: this.authService.GetCurrentUser(),
          newuser: item.item.id,
        },
      });
    }
  }
}
