import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-required",
  templateUrl: "./ctrl-item-required.component.html",
  styleUrls: ["./ctrl-item-required.component.css"],
})
export class CtrlItemRequiredComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  constructor() {}

  ngOnChanges(): void {
    if (this.item.type == "SUCCESSFACTOR" || this.item.type == "INDICATOR") {
      if (!this.item.hasOwnProperty("required")) {
        this.item["required"] = {};
      }
      for (let subindicator of ["E", "I", "P", "C"]) {
        if (!this.item["required"].hasOwnProperty(subindicator)) {
          this.item["required"][subindicator] = false;
        }
      }
    }
    if (this.item.type == "QUESTION") {
      if (!this.item.hasOwnProperty("required")) {
        this.item["required"] = false;
      }
    }
  }

  public onChangeSuccessfactor(subindicator: any) {
    if (this.item.type == "SUCCESSFACTOR") {
      for (let subitem of this.item["items"]) {
        if (!subitem.hasOwnProperty("required")) {
          subitem["required"] = {};
        }
        for (let subindicator of ["E", "I", "P", "C"]) {
          if (!subitem["required"].hasOwnProperty(subindicator)) {
            subitem["required"][subindicator] = false;
          }
        }
        subitem["required"][subindicator] = this.item["required"][subindicator];
      }
    }
    this.itemChanged.emit({ item: this.item });
  }

  public onChangeRequired() {
    this.itemChanged.emit({ item: this.item });
  }
}
