import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-html-editor",
  templateUrl: "./html-editor.component.html",
  styleUrls: ["./html-editor.component.css"],
})
export class HtmlEditorComponent implements OnInit {
  @Input() model: any;
  @Output() modelChanged = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
