<div class="contentblock">
  <h3>Style</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Style</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="style"
        [data]="styles"
        [textField]="'name'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="item.style"
        (valueChange)="onChangeStyle()"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row" *ngIf="item.style == 'TEXTAREA'">
    <label for="label" class="col-sm-2 col-form-label">Columns</label>
    <div class="col-sm-10">
      <input
        type="number"
        name="columns"
        [(ngModel)]="item.columns"
        (change)="onChangeStyle()"
      />
    </div>
  </div>

  <div class="form-group row" *ngIf="item.style == 'TEXTAREA'">
    <label for="label" class="col-sm-2 col-form-label">Rows</label>
    <div class="col-sm-10">
      <input
        type="number"
        name="rows"
        [(ngModel)]="item.rows"
        (change)="onChangeStyle()"
      />
    </div>
  </div>
</div>
