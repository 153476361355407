import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { SurveysService } from "../../../../services/surveys.service";
import { JobsService } from "../../../../services/jobs.service";
import { AuthService } from "../../../../services/auth.service";
import { SurveyObject } from "../../../../shared/survey";
import { SurveyLabelsObject } from "../../../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-dashboard",
  templateUrl: "./survey-dashboard.component.html",
  styleUrls: ["./survey-dashboard.component.css"],
})
export class SurveyDashboardComponent implements OnInit {
  surveyObj = new SurveyObject();

  selectedItem: any = null;
  selectedItemParent: any = null;
  initialized: boolean = false;
  importWindowActive: boolean = false;
  exportWindowActive: boolean = false;
  importResponsesWindowActive: boolean = false;
  templateWindowActive: boolean = false;
  showOrder: boolean = false;
  chaptersById: any = {};
  jobs: any = [];
  extensions: any = [".xlsx"];

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    private jobsService: JobsService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["survey"];

    this.InitializeSurveyInfo();
    this.InitializeSurveyChapters();
    this.InitializeJobs();
  }

  InitializeSurveyInfo() {
    this.surveysService
      .getSurveyInfo(this.surveyObj.id)
      .valueChanges()
      .subscribe((survey) => {
        if (survey.info) this.surveyObj.info = survey.info;
      });
  }

  InitializeSurveyChapters() {
    this.surveysService
      .getSurveyChapters(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((chapters) => {
        this.surveyObj.chapters = chapters;

        this.surveyObj.chapters.sort((a, b) =>
          a.position > b.position ? 1 : -1,
        );

        this.chaptersById = {};

        for (let chapter of this.surveyObj.chapters) {
          this.chaptersById[chapter["id"]] = true;
        }

        this.initialized = true;
      });
  }

  InitializeJobs() {
    this.jobsService
      .getJobsList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((jobs) => {
        if (jobs) this.jobs = jobs;
      });
  }

  onCloseImportWindow() {
    this.importWindowActive = false;
  }

  onCloseExportWindow() {
    this.exportWindowActive = false;
  }

  onCloseImportResponsesWindow() {
    this.importResponsesWindowActive = false;
  }

  onItemSelected(item: any) {
    this.showOrder = false;
    this.selectedItem = item.item;

    if (item.item.hasOwnProperty("parent")) {
      for (let surveyItem of this.surveyObj.chapters) {
        if (surveyItem.id == item.item.parent) {
          this.selectedItemParent = surveyItem;
        }
      }
    } else {
      this.selectedItemParent = item.item;
    }
  }

  onItemChanged(item: any) {
    this.surveysService.updateSurveyChapter(
      this.surveyObj.id,
      this.selectedItemParent,
    );
  }

  onOrderChanged(event: any) {
    let position = 0;
    for (let item of this.surveyObj.chapters) {
      this.surveysService.updateSurveyChapterPosition(
        this.surveyObj.id,
        item.key,
        position,
      );
      position = position + 1;
    }
  }

  onViewOrder() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showOrder = true;
  }

  onAddChapter() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.chapters,
        "",
        "CHAPTER",
      ),
      type: "CHAPTER",
      template: "other",
      display: "TABLE",
      position: this.surveyObj.chapters.length,
    };
    this.surveysService.addSurveyChapter(this.surveyObj.id, item);
  }

  onAddSubChapter() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }
    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "SUBCHAPTER",
      ),
      type: "SUBCHAPTER",
      template: "other",
      display: "TABLE",
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.surveysService.updateSurveyChapter(
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  onAddTemplateItem(item: any) {
    let newIds = [];
    let valid = true;

    for (let key in item.item.items) {
      newIds.push(item.item.items[key]["id"]);
    }

    for (let id of newIds) {
      if (this.chaptersById.hasOwnProperty(id)) valid = false;
    }

    if (!valid) {
      alert(
        "You already added one or more items from this template to the this survey. Delete them first (to avoid duplicates) then try again.",
      );
    } else {
      if (
        confirm("Are you sure you want to add this template to the survey?") ==
        true
      ) {
        let position = this.surveyObj.chapters.length + 1;

        for (let key in item.item.items) {
          let surveyItem = item.item.items[key];

          surveyItem.position = position;

          console.log(surveyItem);

          this.surveysService.addSurveyChapter(this.surveyObj.id, surveyItem);

          position = position + 1;
        }
      }
    }

    this.templateWindowActive = false;
  }

  onRemoveChapter() {
    if (confirm("Are you sure you want to delete this chapter?") == true) {
      let removeIds = [];
      let removedId = this.selectedItem.id;

      removeIds.push(removedId);

      if (this.selectedItem.parent) {
        for (let surveyItem of this.surveyObj.chapters) {
          if (surveyItem.id == this.selectedItem.parent) {
            let removedPos = surveyItem.items.indexOf(this.selectedItem);
            surveyItem.items.splice(removedPos, 1);
            this.surveysService.updateSurveyChapter(
              this.surveyObj.id,
              surveyItem,
            );

            this.selectedItem = null;
            for (let pos in [removedPos, removedPos - 1, 0]) {
              if (surveyItem.items[pos]) {
                if (surveyItem.items[pos].id != removedId) {
                  this.selectedItem = surveyItem.items[pos];
                  break;
                }
              }
            }
          }
        }
      } else {
        let removedPos = this.surveyObj.chapters.indexOf(this.selectedItem);

        if (this.selectedItem.items) {
          for (let subitem of this.selectedItem.items) {
            removeIds.push(subitem.id);
          }
        }

        this.surveysService
          .deleteSurveyChapter(this.surveyObj.id, this.selectedItem.key)
          .then((res) => {
            if (this.surveyObj.items.length > 0) {
              if (this.surveyObj.items[removedPos])
                this.selectedItem = this.surveyObj.chapters[removedPos];
              else
                this.selectedItem =
                  this.surveyObj.chapters[this.surveyObj.items.length - 1];
            } else {
              this.selectedItem = null;
            }
          });
      }
    }
  }

  onRecalculateDashboard() {
    if (
      confirm(
        "Are you sure you are ready to recalculate the dashboard of this survey?",
      ) == true
    ) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "recalculate_dashboard",
          this.surveyObj.id,
        )
      ) {
        // NOTE: this is a *manage* job; manage-backend will pick it up
        // and in turn create a recalculate_dashboard job for the dashboard jobrunner
        this.jobsService.addJob({
          type: "recalculate_dashboard",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert(
          "In couple of minutes the system will recalculate the dashboard for this survey.",
        );
      } else {
        alert(
          "There is already a recalculation task in the queue for this survey.",
        );
      }
    }
  }

  onGoToSurveyLabels() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/labels"]);
  }

  onGoToSurveyCompose() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/compose"]);
  }

  onGoToSurveySegmentation() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/segments"]);
  }

  onGoToSurveyMailing() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/mailings"]);
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
