<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Recipients</h3>

    <kendo-window
      [(top)]="windowTop"
      [(left)]="windowLeft"
      title="Send Mail for Respondent {{ selected.loginname }}"
      *ngIf="respondentWindowActive"
      (close)="respondentWindowActive = false"
    >
      <div>
        <h3>
          Respondent
          <span *ngIf="selected['loginname']"
            ><b>{{ selected["loginname"] }}</b></span
          >
        </h3>

        <div class="form-group row">
          <label for="receiver" class="col-sm-2 col-form-label">Send To</label>

          <div class="col-sm-10">
            <kendo-dropdownlist
              name="receiver"
              [data]="[{ id: 'respondent' }, { id: 'email' }]"
              [textField]="'id'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="receiver"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="type" class="col-sm-2 col-form-label">Text</label>

          <div class="col-sm-10">
            <kendo-dropdownlist
              name="type"
              [data]="[{ id: 'mailing' }, { id: 'reminder' }]"
              [textField]="'id'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="type"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row" *ngIf="receiver == 'email'">
          <label for="email" class="col-sm-2 col-form-label">Email</label>

          <div class="col-sm-10">
            <input type="email" [(ngModel)]="email" />
          </div>
        </div>

        <div class="form-group row" *ngIf="receiver == 'email'">
          <label for="language" class="col-sm-2 col-form-label">Language</label>

          <div class="col-sm-10">
            <kendo-dropdownlist
              name="language"
              [data]="languages"
              [textField]="'name'"
              [valueField]="'code'"
              [valuePrimitive]="true"
              [(ngModel)]="language"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <img
                  src="assets/images/flags/{{ dataItem?.code }}.png"
                  border="0"
                />&nbsp;{{ dataItem?.name }}
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <img
                  src="assets/images/flags/{{ dataItem?.code }}.png"
                  border="0"
                />&nbsp;{{ dataItem?.name }}
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="update" class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <button (click)="onSendMailingForRecipient()">Send</button>
          </div>
        </div>
      </div>
    </kendo-window>

    <kendo-grid
      [data]="gridView"
      [pageSize]="pageSize"
      [pageable]="true"
      [skip]="skip"
      [sortable]="{ mode: 'single' }"
      [sort]="sort"
      (pageChange)="pageChange($event)"
      (sortChange)="sortChange($event)"
    >
      <kendo-grid-column field="firstlogin" title="firstlogin">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ GetDateFormat(dataItem["firstlogin"]) }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="invitation" title="invitation">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ GetDateFormat(dataItem["invitation"]) }}</span>
        </ng-template>
      </kendo-grid-column>

      <ng-container *ngFor="let column of columns">
        <kendo-grid-column
          field="column.column"
          title="{{ column.column }}"
          *ngIf="column.column != 'id' && column.column != 'password'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem[column.column] }}</span>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
      <kendo-grid-column field="autologin" title="autologin">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            href="{{ surveyUrl }}/#/autologin/{{ dataItem.autologin }}"
            target="_blank"
            >Autologin</a
          >
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="test" title="Test mail">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a (click)="selected = dataItem; respondentWindowActive = true"
            ><fa-icon [icon]="['fas', 'envelope']"></fa-icon
          ></a>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
