<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <span class="main-buttons">
        <button
          (click)="onGoToSurveyCompose()"
          class="btn btn-green"
          title="Compose"
        >
          <fa-icon [icon]="['fas', 'magic']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveySegmentation()"
          class="btn btn-yellow"
          title="Segmentation"
        >
          <fa-icon [icon]="['fas', 'chart-pie']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyMailing()"
          class="btn btn-red"
          title="Mailings"
        >
          <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyDashbaord()"
          class="btn btn-purple"
          title="Dashbaord"
        >
          <fa-icon [icon]="['fas', 'table']"></fa-icon>
        </button>
      </span>

      <button (click)="importWindowActive = true" class="btn-actions">
        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
      </button>

      <button (click)="exportWindowActive = true" class="btn-actions">
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
      </button>

      <button (click)="onGoToSurveyList()" class="btn-actions">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left"></div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }} <span class="selection"> Library Files</span>
      </h1>

      <div class="contentblock">
        <!--
                <kendo-scrollview [data]="items" [width]="width" [height]="height" [arrows]="true" [pageable]="true">
                    <ng-template let-item="item">
                        <h2 class="demo-title">{{item.name}}</h2>
                        <img src='https://manage.integronresearch.com/static/uploads/library/{{item.id}}' alt='{{item.title}}' [ngStyle]="{minWidth: width}" draggable="false" />
                    </ng-template>
                </kendo-scrollview>
                -->
        <div *ngFor="let file of files">
          <img
            src="{{ uploadedFileUrl }}/{{ file.id }}"
            alt="{{ file.name }}"
          /><br />
          {{ uploadedFileUrl }}/{{ file.id }} <br /><br /><br />
        </div>

        <br /><br />

        Upload new image
        <app-ctrl-import-file
          [survey]="surveyObj"
          [type]="'image'"
          [link]="'importlibraryitem'"
          [extensions]="extensionsimg"
          [buttons]="importbuttons"
        ></app-ctrl-import-file>
      </div>
    </div>

    <kendo-window
      [top]="200"
      title="Import Labels"
      *ngIf="importWindowActive"
      (close)="importWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'labels'"
        [extensions]="extensions"
        (onWindowClose)="onCloseImportWindow()"
      ></app-ctrl-import-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Export Labels"
      *ngIf="exportWindowActive"
      (close)="exportWindowActive = false"
    >
      <app-ctrl-export-file
        [survey]="surveyObj"
        [type]="'labels'"
        (onWindowClose)="onCloseExportWindow()"
      ></app-ctrl-export-file>
    </kendo-window>
  </div>
</div>
