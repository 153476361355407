import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import {
  FirebaseSurveyInfo,
  SurveysService,
} from "../../../../services/surveys.service";
import { JobsService } from "../../../../services/jobs.service";
import { AuthService } from "../../../../services/auth.service";
import { SurveyObject } from "../../../../shared/survey";
import { SYS_LANGAUGES } from "../../../../shared/language";
import { GradientSettings, OutputFormat } from "@progress/kendo-angular-inputs";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

export const NEW_ID = "new";

@Component({
  selector: "app-survey-info",
  templateUrl: "./survey-info.component.html",
  styleUrls: ["./survey-info.component.css"],
})
export class SurveyInfoComponent implements OnInit {
  surveyObj = new SurveyObject();

  initialized: boolean = false;
  isNewSurvey: boolean = false;
  startdate: any = null;
  enddate: any = null;
  jobs: any = [];
  languages: any = SYS_LANGAUGES;
  branches: any = [
    { id: "Arbeidsbemiddeling", text: "Arbeidsbemiddeling" },
    { id: "Bouw en installatie", text: "Bouw en installatie" },
    { id: "Facilitaire dienstverlening", text: "Facilitaire dienstverlening" },
    { id: "Gemeente en overheid", text: "Gemeente en overheid" },
    { id: "Groothandel", text: "Groothandel" },
    {
      id: "Industrie (Grondstoffen en Halffabrikaten)",
      text: "Industrie (Grondstoffen en Halffabrikaten)",
    },
    { id: "Non- en semiprofit", text: "Non- en semiprofit" },
    { id: "Onderwijs", text: "Onderwijs" },
    { id: "Postorder/ E-Business", text: "Postorder/ E-Business" },
    { id: "Retail", text: "Retail" },
    { id: "Transport &amp; Logistiek", text: "Transport &amp; Logistiek" },
    { id: "Uitgeverijen", text: "Uitgeverijen" },
    {
      id: "Zakelijke en financiële dienstverlening",
      text: "Zakelijke en financiële dienstverlening",
    },
    { id: "Zorg", text: "Zorg" },
  ];

  monitors: any = [
    { id: "KTO", text: "KTO" },
    { id: "MTO", text: "MTO" },
    { id: "ITO", text: "ITO" },
  ];

  scales: any = [
    { id: "5", text: "5" },
    { id: "6", text: "6" },
    { id: "10", text: "10" },
  ];

  access: any = [
    { id: "OPEN", text: "Open" },
    { id: "CLOSED", text: "Closed" },
  ];

  types: any = [
    { id: "LOGIN", text: "Login" },
    { id: "ANONYMOUS", text: "Anonymous" },
  ];

  baselanguages: any = [
    { code: "en", name: "English" },
    { code: "nl", name: "Dutch" },
  ];

  trendingperiods: any = [
    { id: "YEAR", text: "Year" },
    { id: "QUARTER", text: "Quarter" },
    { id: "MONTH", text: "Month" },
  ];

  stylecolors: any = [
    { id: "header", text: "Header color", color: "#2b2a5b" },
    { id: "button", text: "Buttons color", color: "#ed791b" },
    { id: "font", text: "Font color", color: "#3E3D88" },
    { id: "fontlight", text: "Font light color", color: "#9998cb" },
    { id: "selected", text: "Selected item color", color: "#ed791b" },
    { id: "selectedlight", text: "Selected item ligthcolor", color: "#fdf1e8" },
  ];

  format: OutputFormat = "hex";
  settings: GradientSettings = { opacity: false };

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    private jobsService: JobsService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const id = this.activateRoute.snapshot.params["survey"];

    if (id == NEW_ID) {
      const now = new Date();
      const survey = {
        name: "New survey",
        prefix: "",
        // No longer showed to the user but kept for compatibility reasons
        description: "",
        startdate:
          now.getMonth() + 1 + "/" + now.getDate() + "/" + now.getFullYear(),
        enddate:
          now.getMonth() + 1 + "/" + now.getDate() + "/" + now.getFullYear(),
        subindicators: {
          experience: true,
          importance: true,
          performance: true,
          comment: true,
        },
        branche: "Arbeidsbemiddeling",
        monitor: "KTO",
        scale: "6",
        access: "CLOSED",
        type: "LOGIN",
        limit: 50,
        looping: false,
        trending: false,
        public: false,
        // No longer showed to the user or changeable, but kept for compatibility reasons
        style: "BROWSE_WITH_BUTTONS",
        output: false,
        pdflanguage: "en",
        segmentlimit: 0,
        translations: false,
        baselanguage: "en",
        mincomments: 500,
        trendingperiod: "",
        segmentprefix: false,
        //deepsegmentation    : false,
        powerpoint: false,
        mobile: true,
        npstarget: 0,
        satisfactiontarget: 0,
      };
      this.surveyObj.id = guid();
      this.isNewSurvey = true;
      this.onSurveyLoaded(survey);
    } else {
      this.surveyObj.id = id;
      this.InitializeSurveyInfo();
    }
    this.InitializeJobs();
  }

  InitializeSurveyInfo() {
    this.surveysService
      .getSurveyInfo(this.surveyObj.id)
      .valueChanges()
      .subscribe((survey) => this.onSurveyLoaded(survey.info));
  }

  private onSurveyLoaded(survey: FirebaseSurveyInfo) {
    this.surveyObj.info = survey;

    if (this.surveyObj.info.hasOwnProperty("startdate")) {
      this.startdate = new Date(this.surveyObj.info.startdate);
    } else {
      this.startdate = new Date();
    }

    if (this.surveyObj.info.hasOwnProperty("enddate")) {
      this.enddate = new Date(this.surveyObj.info.enddate);
    } else {
      this.startdate = new Date();
    }

    if (!this.surveyObj.info.hasOwnProperty("colors")) {
      this.surveyObj.info.colors = {};
    }

    for (let color of this.stylecolors) {
      if (!this.surveyObj.info.colors.hasOwnProperty(color.id))
        this.surveyObj.info.colors[color.id] = color.color;
    }

    this.initialized = true;
  }

  InitializeJobs() {
    this.jobsService
      .getJobsList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((jobs) => {
        if (jobs) this.jobs = jobs;
      });
  }

  GetColorValue(id: string) {
    return this.surveyObj.info.colors[id];
  }

  onChangeColor(type: string, color: string): void {
    this.surveyObj.info.colors[type] = color;
  }

  SaveSurvey() {
    let surveyInfo = this.surveyObj.info;
    surveyInfo["startdate"] =
      this.startdate.getMonth() +
      1 +
      "/" +
      this.startdate.getDate() +
      "/" +
      this.startdate.getFullYear();
    surveyInfo["enddate"] =
      this.enddate.getMonth() +
      1 +
      "/" +
      this.enddate.getDate() +
      "/" +
      this.enddate.getFullYear();
    if (this.isNewSurvey) {
      this.surveysService.addSurvey(this.surveyObj.id, surveyInfo);
    } else {
      this.surveysService.updateSurvey(this.surveyObj.id, { info: surveyInfo });
    }
    this.router.navigate(["/surveys"]);
  }

  onCopySurvey() {
    if (confirm("Are you sure you are ready to copy this survey?") == true) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "copy_survey",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "copy_survey",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert(
          "In couple of minutes the system will create a copy for this survey.",
        );
      } else {
        alert(
          "There is already an active copy survey job in the queue for this survey",
        );
      }
    }
  }

  onCloseSurvey() {
    if (
      confirm(
        "Are you sure you want to close this survey? All respondents will loose their access.",
      ) == true
    ) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "close_survey",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "close_survey",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert("The survey will be shortly closed.");
      } else {
        alert(
          "There is already an active close survey job in the queue for this survey",
        );
      }
    }
  }

  onCleanSurvey() {
    if (
      confirm(
        "Are you sure you want to clean this survey? All non-respondents will loose their data.",
      ) == true
    ) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "delete_contacts_data",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "delete_contacts_data",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert("The survey will be shortly cleaned.");
      } else {
        alert(
          "There is already an active clean survey job in the queue for this survey",
        );
      }
    }
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
