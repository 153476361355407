<div>
  <h3>
    Respondent
    <span *ngIf="respondent['loginname']"
      ><b>{{ respondent["loginname"] }}</b></span
    >
  </h3>

  <div class="form-group row">
    <label for="id" class="col-sm-2 col-form-label">Id</label>
    <div class="col-sm-10">
      <span>{{ respondent["id"] }}</span>
    </div>
  </div>

  <div class="form-group row">
    <label for="loginname" class="col-sm-2 col-form-label">Loginname</label>
    <div class="col-sm-10">
      <span>{{ respondent["loginname"] }}</span>
    </div>
  </div>

  <div class="form-group row">
    <label for="password" class="col-sm-2 col-form-label">Password</label>
    <div class="col-sm-10">
      <span>{{ respondent["password"] }}</span>
    </div>
  </div>

  <div *ngFor="let column of columns">
    <div class="form-group row" *ngIf="!IsSysColumn(column)">
      <label for="{{ column.column }}" class="col-sm-2 col-form-label">{{
        column.column
      }}</label>

      <div class="col-sm-10" [ngSwitch]="column.column">
        <span *ngSwitchCase="'type'">
          <kendo-dropdownlist
            name="column.column"
            [data]="[
              { id: 'REAL' },
              { id: 'TEST' },
              { id: 'EXCLUDE' },
              { id: 'ARCHIVE' }
            ]"
            [textField]="'id'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            [(ngModel)]="respondent[column.column]"
          ></kendo-dropdownlist>
        </span>

        <span *ngSwitchCase="'email'">
          <input
            type="email"
            *ngIf="column.column == 'email'"
            [(ngModel)]="respondent[column.column]"
          />
        </span>

        <span *ngSwitchCase="'language'">
          <kendo-dropdownlist
            name="language"
            [data]="languages"
            [textField]="'name'"
            [valueField]="'code'"
            [valuePrimitive]="true"
            [(ngModel)]="respondent[column.column]"
            *ngIf="column.column == 'language'"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <img
                src="assets/images/flags/{{ dataItem?.code }}.png"
                border="0"
              />&nbsp;{{ dataItem?.name }}
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <img
                src="assets/images/flags/{{ dataItem?.code }}.png"
                border="0"
              />&nbsp;{{ dataItem?.name }}
            </ng-template>
          </kendo-dropdownlist>
        </span>

        <span *ngSwitchDefault>
          <input type="string" [(ngModel)]="respondent[column.column]" />
        </span>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngFor="let segment of survey.segments">
    <label for="{{ segment.text }}" class="col-sm-2 col-form-label">{{
      segment.text
    }}</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="segment.text"
        [data]="segment.items"
        [textField]="'text'"
        [valueField]="'text'"
        [valuePrimitive]="true"
        [(ngModel)]="respondent[segment.text]"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row" *ngIf="displaymailings">
    <label for="mailing" class="col-sm-2 col-form-label">Mailing</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="mailing"
        [data]="mailings"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="mailing"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row">
    <label for="update" class="col-sm-2 col-form-label"></label>
    <div class="col-sm-10">
      <button (click)="doUpdateRespondent()">Save</button>
    </div>
  </div>
</div>
