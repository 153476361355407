<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <span class="main-buttons">
        <button
          (click)="onGoToSurveyLabels()"
          class="btn btn-blue"
          title="Labels"
        >
          <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyCompose()"
          class="btn btn-green"
          title="Compose"
        >
          <fa-icon [icon]="['fas', 'magic']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveySegmentation()"
          class="btn btn-yellow"
          title="Segmentation"
        >
          <fa-icon [icon]="['fas', 'chart-pie']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyMailing()"
          class="btn btn-red"
          title="Mailings"
        >
          <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        </button>
      </span>

      <button (click)="onAddChapter()" class="btn-actions" title="Add chapter">
        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
      </button>

      <span *ngIf="selectedItem">
        <button
          (click)="onAddSubChapter()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'CHAPTER'"
          title="Add subchapter"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>
      </span>

      <button
        (click)="importResponsesWindowActive = true"
        class="btn-actions"
        title="Import responses"
      >
        <fa-icon
          [icon]="['fas', 'sign-in-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>

      <button
        (click)="onRecalculateDashboard()"
        class="btn-actions"
        title="Recalculate dashboard"
      >
        <fa-icon [icon]="['fas', 'table']"></fa-icon>
      </button>

      <button
        (click)="importWindowActive = true"
        class="btn-actions"
        title="Import chapters"
      >
        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
      </button>

      <button
        (click)="exportWindowActive = true"
        class="btn-actions"
        title="Export chapters"
      >
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
      </button>

      <button
        (click)="onRemoveChapter()"
        class="btn-actions"
        title="Remove chapter"
      >
        <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
      </button>

      <button
        (click)="templateWindowActive = true"
        class="btn-actions"
        title="Add template"
      >
        <fa-icon [icon]="['fas', 'plus']" style="color: #ffb752"></fa-icon>
      </button>

      <button (click)="onViewOrder()" class="btn-actions" title="View order">
        <fa-icon [icon]="['fas', 'network-wired']"></fa-icon>
      </button>

      <button (click)="onGoToSurveyList()" class="btn-actions" title="Surveys">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="surveyObj.chapters"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }}
        <span class="selection" *ngIf="selectedItem"
          >>> {{ selectedItem.text }}</span
        >
      </h1>

      <div *ngIf="selectedItem">
        <div *ngIf="selectedItem.type == 'CHAPTER'">
          <app-ctrl-chapter-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-chapter-info>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
        </div>

        <div *ngIf="selectedItem.type == 'SUBCHAPTER'">
          <app-ctrl-chapter-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-chapter-info>
        </div>
      </div>

      <div *ngIf="showOrder">
        <div class="contentblock">
          <div *ngIf="surveyObj.chapters">
            <kendo-sortable
              [kendoSortableBinding]="surveyObj.chapters"
              (dragEnd)="onOrderChanged($event)"
            >
              <ng-template let-subitem="item">
                <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                <b>{{ subitem.text }}</b> <br /><br />
              </ng-template>
            </kendo-sortable>
          </div>
        </div>
      </div>
    </div>

    <kendo-window
      [top]="200"
      title="Import Chapters"
      *ngIf="importWindowActive"
      (close)="importWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'chapters'"
        [extensions]="extensions"
        (onWindowClose)="onCloseImportWindow()"
      ></app-ctrl-import-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Export Chapters"
      *ngIf="exportWindowActive"
      (close)="exportWindowActive = false"
    >
      <app-ctrl-export-file
        [survey]="surveyObj"
        [type]="'chapters'"
        (onWindowClose)="onCloseExportWindow()"
      ></app-ctrl-export-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Templates"
      *ngIf="templateWindowActive"
      (close)="templateWindowActive = false"
    >
      <app-template-item-choice
        [type]="'chapters'"
        (itemChoosed)="onAddTemplateItem($event)"
      ></app-template-item-choice>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Import Responses"
      *ngIf="importResponsesWindowActive"
      (close)="importResponsesWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'responses'"
        [extensions]="extensions"
        (onWindowClose)="onCloseImportResponsesWindow()"
      ></app-ctrl-import-file>
    </kendo-window>
  </div>
</div>
