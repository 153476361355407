import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TemplatesService } from "../services/templates.service";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";
import { guid } from "../shared/utils";

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.css"],
})
export class TemplatesComponent implements OnInit {
  selectedItem: any = null;
  name: string = "";
  templates: any = [];
  types: any = [
    { id: "items", text: "Survey Items", type: "TEMPLATETYPE" },
    { id: "mailings", text: "Mailings", type: "TEMPLATETYPE" },
    { id: "chapters", text: "Chapters", type: "TEMPLATETYPE" },
  ];

  constructor(
    private templatesService: TemplatesService,
    private authService: AuthService,
    public router: Router,
  ) {}

  ngOnInit(): void {}

  onItemSelected(item: any) {
    this.selectedItem = item.item;
    this.templatesService
      .getTemplatesList(this.selectedItem.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((templates) => {
        this.templates = templates;
      });
  }

  AddTemplate() {
    this.templatesService.addTemplate(this.selectedItem.id, {
      info: { id: guid(), name: this.name },
    });
  }

  onSelectTemplate(dataItem: any) {}

  onRemoveTemplate(dataItem: any) {
    if (confirm("Are you sure you want to delete this template?") == true) {
      this.templatesService.deleteTemplate(this.selectedItem.id, dataItem.key);
    }
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
