import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-move",
  templateUrl: "./ctrl-item-move.component.html",
  styleUrls: ["./ctrl-item-move.component.css"],
})
export class CtrlItemMoveComponent implements OnInit {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemMoved = new EventEmitter();

  selected: any = {};

  constructor() {}

  ngOnInit(): void {}

  public onMoveItem() {
    this.itemMoved.emit({ item: this.selected });
  }
}
