<div class="contentblock">
  <h3>Shuffle</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Shuffle</label>
    <div class="col-sm-10">
      <kendo-switch
        name="shuffle"
        [(ngModel)]="item.shuffle"
        (valueChange)="onChangeShuffle()"
      ></kendo-switch>
    </div>
  </div>
</div>
