import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SurveysService } from "../services/surveys.service";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";
import { process, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import { guid } from "../shared/utils";
import { NEW_ID } from "./survey/setup/survey-info/survey-info.component";

@Component({
  selector: "app-surveys",
  templateUrl: "./surveys.component.html",
  styleUrls: ["./surveys.component.css"],
})
export class SurveysComponent implements OnInit {
  surveys: any = [];
  selected: any;
  initialized: boolean = false;
  state: State = { filter: null };

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.getSurveysList();
  }

  getSurveysList() {
    this.surveysService
      .getSurveysList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((surveys) => {
        this.surveys = [];

        for (let survey of surveys) {
          this.surveys.push({
            id: survey.key,
            name: survey.info.name,
            startdate: survey.info.enddate,
            enddate: survey.info.enddate,
            branche: survey.info.branche,
            monitor: survey.info.monitor,
          });
        }

        this.surveys.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.gridData = process(this.surveys, this.state);

        this.initialized = true;
      });
  }

  public gridData: GridDataResult = process(this.surveys, this.state);

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.surveys, this.state);
  }

  public onAddNewSurvey() {
    this.router.navigate([`/survey/${NEW_ID}/info`]);
  }

  public onSelectClients() {
    this.authService.NavigateToURL("clients");
  }

  public onSelectJobs() {
    this.authService.NavigateToURL("jobs");
  }

  public onSignOut() {
    this.authService.SignOut();
  }

  public onSelectSurvey(dataItem: any) {
    let url = "survey/" + dataItem.id + "/compose";
    this.authService.NavigateToURL(url);
  }

  public onSelectSurveyInfo(dataItem: any) {
    let url = "survey/" + dataItem.id + "/info";
    this.authService.NavigateToURL(url);
  }
}
