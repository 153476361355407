<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <span class="main-buttons">
        <button
          (click)="onGoToSurveyLabels()"
          class="btn btn-blue"
          title="Labels"
        >
          <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveySegmentation()"
          class="btn btn-yellow"
          title="Segmentation"
        >
          <fa-icon [icon]="['fas', 'chart-pie']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyMailing()"
          class="btn btn-red"
          title="Mailings"
        >
          <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyDashbaord()"
          class="btn btn-purple"
          title="Dashbaord"
        >
          <fa-icon [icon]="['fas', 'table']"></fa-icon>
        </button>
      </span>

      <span *ngIf="selectedItem">
        <button
          (click)="onAddIndicator()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'SUCCESSFACTOR'"
          title="Add indicator"
        >
          <fa-icon [icon]="['far', 'object-ungroup']"></fa-icon>
        </button>

        <button
          (click)="addQuestionToMatrix()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'MATRIX'"
          title="Add question to matrix"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>

        <button
          (click)="onAddSubindicator()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'MATRIX'"
          title="Add matrix"
        >
          <fa-icon [icon]="['far', 'object-ungroup']"></fa-icon>
        </button>

        <button
          (click)="addAnswer()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'QUESTION' && selectedItem.parent == null"
          title="Add answer"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>
      </span>

      <button
        (click)="onAddQuestion()"
        class="btn-actions"
        title="Add question"
      >
        <fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
      </button>

      <button
        (click)="onOpenLanguageWindow()"
        class="btn-actions"
        title="Add language question"
      >
        <fa-icon [icon]="['fas', 'flag']"></fa-icon>
      </button>

      <button
        (click)="onAddSuccessFactor()"
        class="btn-actions"
        title="Add success factor"
      >
        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
      </button>

      <button (click)="onAddMatrix()" class="btn-actions" title="Add matrix">
        <fa-icon [icon]="['fas', 'th-list']"></fa-icon>
      </button>

      <button (click)="onAddText()" class="btn-actions" title="Add text">
        <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
      </button>

      <button
        (click)="onAddPageBreak()"
        class="btn-actions"
        title="Add page break"
      >
        <fa-icon [icon]="['fas', 'level-down-alt']" rotate="90"></fa-icon>
      </button>

      <button (click)="onAddMarker()" class="btn-actions" title="Add marker">
        <fa-icon [icon]="['fas', 'map-pin']" rotate="180"></fa-icon>
      </button>

      <button
        (click)="onAddMailMarker()"
        class="btn-actions"
        title="Add mail reminder marker"
      >
        <fa-icon [icon]="['fas', 'envelope-open']"></fa-icon>
      </button>

      <button (click)="onAddSkip()" class="btn-actions" title="Add skip">
        <fa-icon [icon]="['fas', 'code-branch']" rotate="90"></fa-icon>
      </button>

      <button (click)="onCloneItem()" class="btn-actions" title="Clone item">
        <fa-icon [icon]="['fas', 'clone']"></fa-icon>
      </button>

      <button
        (click)="templateWindowActive = true"
        class="btn-actions"
        title="Add template"
      >
        <fa-icon [icon]="['fas', 'plus']" style="color: #ffb752"></fa-icon>
      </button>

      <button (click)="onRemoveItem()" class="btn-actions" title="Remove item">
        <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
      </button>

      <button
        (click)="onViewDependencies()"
        class="btn-actions"
        title="View dependencies"
      >
        <fa-icon [icon]="['fas', 'dna']"></fa-icon>
      </button>

      <button (click)="onViewOrder()" class="btn-actions" title="View order">
        <fa-icon [icon]="['fas', 'network-wired']"></fa-icon>
      </button>

      <button
        (click)="onPublishSurvey()"
        class="btn-actions"
        title="Publish survey"
      >
        <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
      </button>

      <button
        (click)="importWindowActive = true"
        class="btn-actions"
        title="Import filters"
      >
        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
      </button>

      <button
        (click)="exportWindowActive = true"
        class="btn-actions"
        title="Export filters"
      >
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
      </button>

      <button (click)="onViewSurvey()" class="btn-actions" title="View survey">
        <fa-icon [icon]="['fas', 'eye']"></fa-icon>
      </button>

      <button
        (click)="onGoToSurveyList()"
        class="btn-actions"
        title="Survey list"
      >
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="surveyObj.items"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }}
        <span class="selection" *ngIf="selectedItem"
          >>> {{ selectedItem.text }}</span
        >
      </h1>

      <div *ngIf="selectedItem">
        <div *ngIf="selectedItem.type == 'SUCCESSFACTOR'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-requested
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-requested>
          <app-ctrl-item-settings
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-settings>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
          <app-ctrl-item-move
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemMoved)="onItemMoved($event)"
          ></app-ctrl-item-move>
        </div>

        <div *ngIf="selectedItem.type == 'INDICATOR'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-requested
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-requested>
          <app-ctrl-item-settings
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-settings>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'MATRIX'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-style
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-style>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
          <app-ctrl-item-move
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemMoved)="onItemMoved($event)"
          ></app-ctrl-item-move>
        </div>

        <div *ngIf="selectedItem.type == 'QUESTION'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-item-form
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-form>
          <app-ctrl-item-style
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-style>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-requested
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-requested>
          <app-ctrl-item-settings
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-settings>
          <app-ctrl-item-shuffle
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-shuffle>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
          <app-ctrl-item-move
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemMoved)="onItemMoved($event)"
            *ngIf="!selectedItem.parent"
          ></app-ctrl-item-move>
        </div>

        <div *ngIf="selectedItem.type == 'TEXT'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
          <app-ctrl-item-move
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemMoved)="onItemMoved($event)"
          ></app-ctrl-item-move>
        </div>

        <div *ngIf="selectedItem.type == 'ANSWER'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-additional
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-additional>
          <app-ctrl-item-anonymity
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-anonymity>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'SKIP'">
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-move
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemMoved)="onItemMoved($event)"
          ></app-ctrl-item-move>
        </div>
      </div>

      <div *ngIf="showOrder">
        Show Items Order List
        <div class="contentblock">
          <div *ngIf="surveyObj.items">
            <kendo-sortable
              [kendoSortableBinding]="surveyObj.items"
              (dragEnd)="onOrderChanged($event)"
            >
              <ng-template let-subitem="item">
                <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                <b>{{ subitem.text }}</b> &nbsp;<span
                  >{{ labelsObj.getItemLabel(subitem.id) }}
                </span>
                <br /><br />
              </ng-template>
            </kendo-sortable>
          </div>
        </div>
      </div>

      <div *ngIf="showDependencies">
        Show Dependencies List
        <div class="contentblock">
          <kendo-grid
            [data]="dependenciesView"
            [groupable]="true"
            [group]="groups"
          >
            <kendo-grid-column field="operator" title="Operator">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.operator }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="item" title="Item">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ ParseItemId(dataItem.relationship.item) }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="subitem" title="Subitem">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{
                  ParseSubItemId(
                    dataItem.relationship.item,
                    dataItem.relationship.subitem
                  )
                }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="action" title="Action">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.relationship.action }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="remove" title="Remove">
              <ng-template kendoGridCellTemplate let-dataItem>
                <fa-icon
                  [icon]="['fas', 'times']"
                  style="color: orange"
                  (click)="onDeleteRelationship(dataItem)"
                ></fa-icon>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>

    <kendo-window
      [top]="200"
      title="Languages"
      *ngIf="languageWindowActive"
      (close)="onCloseLanguageWindow()"
    >
      <kendo-multiselect
        name="languages"
        [data]="languageQuestion.items"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="selectedLanguages"
      ></kendo-multiselect>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Import Filters"
      *ngIf="importWindowActive"
      (close)="importWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'filters'"
        [extensions]="extensions"
        (onWindowClose)="onCloseImportWindow()"
      ></app-ctrl-import-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Export Filters"
      *ngIf="exportWindowActive"
      (close)="exportWindowActive = false"
    >
      <app-ctrl-export-file
        [survey]="surveyObj"
        [type]="'filters'"
        (onWindowClose)="onCloseExportWindow()"
      ></app-ctrl-export-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Templates"
      *ngIf="templateWindowActive"
      (close)="templateWindowActive = false"
    >
      <app-template-item-choice
        [type]="'items'"
        (itemChoosed)="onAddTemplateItem($event)"
      ></app-template-item-choice>
    </kendo-window>
  </div>
</div>
