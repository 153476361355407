import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-rules",
  templateUrl: "./survey-rules.component.html",
  styleUrls: ["./survey-rules.component.css"],
})
export class SurveyRulesComponent implements OnInit {
  @Input() survey: any;

  initialized: boolean = false;
  rules: any = {};
  types: any = [
    { id: "EXCLUSION", name: "Exclude respondent if selected segment is" },
    {
      id: "CONDITION",
      name: "Alwaise invite a respondent if selected segment is",
    },
    { id: "QUOTA", name: "Only invite a certain number of respondent" },
    { id: "TIME", name: "Invite same respondent only after certain days" },
    { id: "PRIORITY", name: "Set priority for segment" },
    { id: "QUOTAPRIORITY", name: "Set priority for quota segment" },
  ];
  surveySegments: any = [];
  selected: any = { type: null };

  constructor(private surveysService: SurveysService) {}

  ngOnInit(): void {
    this.InitializeSurveyRules();
  }

  InitializeSurveyRules() {
    this.surveysService
      .getSurveyRules(this.survey.id)
      .valueChanges()
      .subscribe((rules) => {
        if (rules) this.rules = rules;
        this.initialized = true;
      });
  }

  onSelectSegmentGroup(event: any) {
    this.surveySegments = [];
    this.selected.subitems = [];
    this.surveySegments = this.survey.GetSurveySegmentSubsegments(
      this.selected.item,
    );
  }

  doAddRule() {
    if (!this.rules.hasOwnProperty(this.selected.type)) {
      this.rules[this.selected.type] = [];
    }

    if (
      this.selected.type == "EXCLUSION" ||
      this.selected.type == "CONDITION" ||
      this.selected.type == "QUOTA" ||
      this.selected.type == "PRIORITY"
    ) {
      for (let subitem of this.selected.subitems) {
        let rule = { id: guid(), item: this.selected.item, subitem: subitem };

        if (this.selected.type == "QUOTA") {
          rule["max"] = this.selected.max;
        }

        this.rules[this.selected.type].push(rule);
      }
    }

    if (this.selected.type == "TIME") {
      let rule = { id: guid(), time: this.selected.time };
      this.rules[this.selected.type].push(rule);
    }

    if (this.selected.type == "QUOTAPRIORITY") {
      let rule = { id: guid(), item: this.selected.item };
      this.rules[this.selected.type].push(rule);
    }

    this.surveysService.updateSurveyRules(
      this.survey.id,
      this.selected.type,
      this.rules[this.selected.type],
    );

    this.selected = { type: null, item: null, subitems: [] };
  }

  doDeleteRule(ruleType: any, rule: any) {
    let index = this.rules[ruleType].indexOf(rule);
    this.rules[ruleType].splice(index, 1);
    this.surveysService.updateSurveyRules(
      this.survey.id,
      ruleType,
      this.rules[ruleType],
    );
  }
}
