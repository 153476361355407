import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-settings",
  templateUrl: "./ctrl-item-settings.component.html",
  styleUrls: ["./ctrl-item-settings.component.css"],
})
export class CtrlItemSettingsComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  constructor() {}

  ngOnChanges(): void {
    if (this.item.type == "SUCCESSFACTOR" || this.item.type == "INDICATOR") {
      if (!this.item.hasOwnProperty("buildWorcloud")) {
        this.item["buildWorcloud"] = false;
      }
    }

    if (this.item.type == "QUESTION") {
      if (!this.item.hasOwnProperty("isCompliment")) {
        this.item["isCompliment"] = false;
      }
      if (!this.item.hasOwnProperty("isTip")) {
        this.item["isTip"] = false;
      }
      if (!this.item.hasOwnProperty("isAnonymity")) {
        this.item["isAnonymity"] = false;
      }
      if (!this.item.hasOwnProperty("isSegment")) {
        this.item["isSegment"] = false;
      }

      if (this.item.form == "TEXT") {
        if (!this.item.hasOwnProperty("isPromoterComment")) {
          this.item["isPromoterComment"] = false;
        }
        if (!this.item.hasOwnProperty("isPassiveComment")) {
          this.item["isPassiveComment"] = false;
        }
        if (!this.item.hasOwnProperty("isDetractorComment")) {
          this.item["isDetractorComment"] = false;
        }
        if (!this.item.hasOwnProperty("buildWorcloud")) {
          this.item["buildWorcloud"] = false;
        }
      }
    }
  }

  public onChangeSuccessfactor() {
    if (this.item.type == "SUCCESSFACTOR") {
      for (let subitem of this.item["items"]) {
        if (!subitem.hasOwnProperty("buildWorcloud")) {
          subitem["buildWorcloud"] = this.item["buildWorcloud"];
        }
      }
    }
    this.itemChanged.emit({ item: this.item });
  }

  public onChangeSettings() {
    this.itemChanged.emit({ item: this.item });
  }
}
