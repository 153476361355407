import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { SurveysService } from "../../../../services/surveys.service";
import { JobsService } from "../../../../services/jobs.service";
import { AuthService } from "../../../../services/auth.service";
import { SurveyObject } from "../../../../shared/survey";
import { SurveyLabelsObject } from "../../../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-segments",
  templateUrl: "./survey-segments.component.html",
  styleUrls: ["./survey-segments.component.css"],
})
export class SurveySegmentsComponent implements OnInit {
  surveyObj = new SurveyObject();
  labelsObj = new SurveyLabelsObject();

  selectedItem: any = null;
  selectedItemParent: any = null;
  initialized: boolean = false;
  importWindowActive: boolean = false;
  exportWindowActive: boolean = false;
  showSections: boolean = false;
  showSectionType: any = "";
  columns: any = [];
  jobs: any = [];
  extensions: any = [".xlsx"];

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    private jobsService: JobsService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["survey"];

    this.InitializeSurveyInfo();
    this.InitializeSurveyItems();
    this.InitializeSurveySegments();
    this.InitializeSurveyRespondentsStructures();
    this.InitializeJobs();
  }

  InitializeSurveyInfo() {
    this.surveysService
      .getSurveyInfo(this.surveyObj.id)
      .valueChanges()
      .subscribe((survey) => {
        if (survey.info) this.surveyObj.info = survey.info;
      });
  }

  InitializeSurveyItems() {
    this.surveysService
      .getSurveyItems(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((items) => {
        this.surveyObj.items = items;

        this.surveyObj.items.sort((a, b) => (a.position > b.position ? 1 : -1));
      });
  }

  InitializeSurveySegments() {
    this.surveysService
      .getSurveySegments(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((segments) => {
        this.surveyObj.segments = segments;

        this.surveyObj.segments.sort((a, b) =>
          a.position > b.position ? 1 : -1,
        );

        this.RefreshSelectedItem();

        this.initialized = true;
      });
  }

  InitializeSurveyRespondentsStructures() {
    this.surveysService
      .getSurveyRespondentsStructures(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((columns) => {
        if (columns.length > 0) {
          this.columns = columns;
        } else {
          let basecolumns = {};

          basecolumns["id"] = { column: "id", position: 1 };
          basecolumns["email"] = { column: "email", position: 2 };
          basecolumns["type"] = { column: "type", position: 3 };
          basecolumns["language"] = { column: "language", position: 4 };
          basecolumns["systime"] = { column: "systime", position: 5 };
          basecolumns["loginname"] = { column: "loginname", position: 6 };
          basecolumns["password"] = { column: "password", position: 7 };

          this.surveysService.updateSurveyRespondentsStructures(
            this.surveyObj.id,
            basecolumns,
          );
        }

        this.columns.sort((a, b) => (a.position > b.position ? 1 : -1));
      });
  }

  InitializeJobs() {
    this.jobsService
      .getJobsList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((jobs) => {
        if (jobs) this.jobs = jobs;
      });
  }

  RefreshSelectedItem() {
    if (this.selectedItemParent) {
      for (let item of this.surveyObj.segments) {
        if (item.id == this.selectedItemParent.id) {
          let selectedItemId = null;
          if (this.selectedItem) {
            selectedItemId = this.selectedItem.id;
          }
          this.selectedItemParent = item;
          this.selectedItem = item;
          if (selectedItemId && item.hasOwnProperty("items")) {
            for (let subitem of item["items"]) {
              if (subitem.id == selectedItemId) {
                this.selectedItem = subitem;
              }
            }
          }
        }
      }
    }
  }

  getSurveyItemText(
    list: any,
    prefixparent: string,
    prefixitem: string,
  ): string {
    let prefix = "";
    let text = "";
    let existing = false;
    let position = list.length;

    if (prefixparent.length > 0) prefix = prefixparent;
    if (prefix.length > 0) prefix += "_";
    prefix += prefixitem;
    if (prefix.length > 0) prefix += "_";

    while (!existing) {
      let found = false;
      text = prefix + position;
      for (let item of list) {
        if (item.text == text) found = true;
      }
      if (found == false) existing = true;
      position++;
    }

    return text;
  }

  onAddSegmentGroup() {
    let item = {
      id: guid(),
      text: this.getSurveyItemText(this.surveyObj.segments, "", "SEGMENTGROUP"),
      type: "SEGMENTGROUP",
      position: this.surveyObj.segments.length,
    };
    this.surveysService.addSurveySegmentGroup(this.surveyObj.id, item);
  }

  onAddSegment() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }
    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "SEGMENT",
      ),
      type: "SEGMENT",
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.surveysService.updateSurveySegmentGroup(
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  onRemoveSegment() {
    if (confirm("Are you sure you want to delete this segment?") == true) {
      let removeIds = [];
      let removedId = this.selectedItem.id;

      removeIds.push(removedId);

      if (this.selectedItem.parent) {
        for (let surveyItem of this.surveyObj.segments) {
          if (surveyItem.id == this.selectedItem.parent) {
            let removedPos = surveyItem.items.indexOf(this.selectedItem);
            surveyItem.items.splice(removedPos, 1);
            this.surveysService.updateSurveySegmentGroup(
              this.surveyObj.id,
              surveyItem,
            );

            this.selectedItem = null;
            for (let pos in [removedPos, removedPos - 1, 0]) {
              if (surveyItem.items[pos]) {
                if (surveyItem.items[pos].id != removedId) {
                  this.selectedItem = surveyItem.items[pos];
                  break;
                }
              }
            }
          }
        }
      } else {
        let removedPos = this.surveyObj.segments.indexOf(this.selectedItem);

        if (this.selectedItem.items) {
          for (let subitem of this.selectedItem.items) {
            removeIds.push(subitem.id);
          }
        }

        this.surveysService
          .deleteSurveySegmentGroup(this.surveyObj.id, this.selectedItem.key)
          .then((res) => {
            if (this.surveyObj.items.length > 0) {
              if (this.surveyObj.items[removedPos])
                this.selectedItem = this.surveyObj.segments[removedPos];
              else
                this.selectedItem =
                  this.surveyObj.segments[this.surveyObj.items.length - 1];
            } else {
              this.selectedItem = null;
            }
          });
      }

      //this.CheckAndRemoveRelation(removeIds)
    }
  }

  onItemSelected(item: any) {
    this.showSections = false;

    this.selectedItem = item.item;

    if (item.item.hasOwnProperty("parent")) {
      for (let surveyItem of this.surveyObj.items) {
        if (surveyItem.id == item.item.parent) {
          this.selectedItemParent = surveyItem;
        }
      }
    } else {
      this.selectedItemParent = item.item;
    }
  }

  onItemChanged(item: any) {
    this.surveysService.updateSurveySegmentGroup(
      this.surveyObj.id,
      this.selectedItemParent,
    );
  }

  onOrderChanged(event: any) {
    let position = 0;
    for (let item of this.surveyObj.segments) {
      this.surveysService.updateSurveySegmentGroupPosition(
        this.surveyObj.id,
        item.key,
        position,
      );
      position = position + 1;
    }
  }

  onCloseImportWindow() {
    this.importWindowActive = false;
  }

  onCloseExportWindow() {
    this.exportWindowActive = false;
  }

  onPublishContacts() {
    if (confirm("Are you sure you want to publish the contacts?") == true) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "publish_contacts",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "publish_contacts",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert(
          "In couple of minutes the system will publish the contacts for this survey.",
        );
      } else {
        alert(
          "here is already a publish contacts job in the queue for this survey",
        );
      }
    }
  }

  onExportPrototype() {
    if (
      confirm(
        "Are you sure you want to export the prototype for this survey?",
      ) == true
    ) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "export_prototype",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "export_prototype",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert(
          "In couple of minutes the system will email with the prototype for this survey.",
        );
      } else {
        alert(
          "here is already a export prototype job in the queue for this survey",
        );
      }
    }
  }

  onResegmentateInvitations() {
    if (
      confirm(
        "Are you sure you are ready to calculate the resegmentation of invitations?",
      ) == true
    ) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "recalculate_invitation_segmentation",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "recalculate_invitation_segmentation",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert(
          "The resegmentation of invitations forthis survey will be shortly start",
        );
      } else {
        alert(
          "here is already a recalculate invitation segmentation job in the queue for this survey",
        );
      }
    }
  }

  onGoToSurveyLabels() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/labels"]);
  }

  onGoToSurveyCompose() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/compose"]);
  }

  onGoToSurveyMailing() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/mailings"]);
  }

  onGoToSurveyDashbaord() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/dashboard"]);
  }

  onViewResegmentation() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "RESEGMENTATIONS";
  }

  onViewTransformation() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "TRANSFORMATIONS";
  }

  onViewRules() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "RULES";
  }

  onViewAPI() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "API";
  }

  onViewRespondents() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "RESPONDENTS";
  }

  onViewPublicMetaData() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "METADATA";
  }

  onViewOrder() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showSections = true;
    this.showSectionType = "ORDER";
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
