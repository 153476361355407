<div class="contentblock">
  <h3>Settings</h3>

  <div class="form-group row">
    <label for="text" class="col-sm-2 col-form-label">Title</label>
    <div class="col-sm-10">
      <input type="text" [(ngModel)]="mailing.text" />
    </div>
  </div>

  <div class="form-group row">
    <label for="creator" class="col-sm-2 col-form-label">Creator</label>
    <div class="col-sm-10">
      <input type="text" [(ngModel)]="mailing.creator" disabled="true" />
    </div>
  </div>

  <div class="form-group row">
    <label for="creator" class="col-sm-2 col-form-label">Scheduler</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="scheduler"
        [data]="[
          { id: 'MANUALLY', name: 'Manually' },
          { id: 'AUTOMATIC', name: 'Automatic' }
        ]"
        [textField]="'name'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="mailing.scheduler"
        (change)="onChangeItem()"
      ></kendo-dropdownlist>
      <!-- Display a green checkmark with the label scheduled if the mailing status is Scheduled else display a red cross -->
      <span class="spaced" *ngIf="mailing.status === 'SCHEDULED'">
        <i class="fa fa-check-circle" style="color: green"></i> Scheduled
      </span>
      <span class="spaced" *ngIf="mailing.status !== 'SCHEDULED'">
        <i class="fa fa-times-circle" style="color: red"></i> Not Scheduled
      </span>
    </div>
  </div>

  <div class="form-group row" *ngIf="mailing.status === 'SCHEDULED'">
    <label for="creator" class="col-sm-2 col-form-label"></label>
    <div class="col-sm-10">
      <button type="button" (click)="doCancelScheduledMailing()">
        Cancel scheduled mailing
      </button>
    </div>
  </div>

  <div class="form-group row">
    <label for="creator" class="col-sm-2 col-form-label">Date / Time</label>
    <div class="col-sm-10">
      <kendo-datetimepicker
        name="schedulertime"
        [steps]="steps"
        [(value)]="mailing.schedulertime"
      ></kendo-datetimepicker>
    </div>
  </div>

  <div class="form-group row">
    <label for="reminder" class="col-sm-2 col-form-label">Reminder</label>
    <div class="col-sm-10">
      <kendo-switch
        name="reminder"
        [(ngModel)]="mailing.reminder"
      ></kendo-switch>
    </div>
  </div>

  <div class="form-group row" *ngIf="mailing.reminder">
    <label for="interval" class="col-sm-2 col-form-label"
      >Send reminder after x days</label
    >
    <div class="col-sm-10">
      <input type="number" name="interval" [(ngModel)]="mailing.interval" />
    </div>
  </div>

  <div class="form-group row" *ngIf="mailing.reminder">
    <label for="invitations" class="col-sm-2 col-form-label"
      >Max allowed invitations</label
    >
    <div class="col-sm-10">
      <input
        type="number"
        name="invitations"
        [(ngModel)]="mailing.invitations"
      />
    </div>
  </div>
</div>

<div class="contentblock">
  <h3>Templates</h3>

  <div class="form-group row">
    <label for="usecustom" class="col-sm-2 col-form-label"
      >Use custom template</label
    >
    <div class="col-sm-10">
      <kendo-switch
        name="usecustom"
        [(ngModel)]="mailing.usecustom"
      ></kendo-switch>
    </div>
  </div>

  <div class="form-group row" *ngIf="mailing.usecustom">
    <label for="usecustom" class="col-sm-2 col-form-label">Template</label>
    <div class="col-sm-10">
      <app-ctrl-import-file
        [survey]="survey"
        [type]="'templates'"
        [extensions]="extensions"
        [link]="'importtemplate'"
        [buttons]="importbuttons"
        [attached]="attached"
      ></app-ctrl-import-file>
    </div>
  </div>

  <div class="form-group row" *ngIf="mailing.usecustom">
    <label for="usecustom" class="col-sm-2 col-form-label">Color</label>
    <div class="col-sm-10">
      <kendo-colorpicker
        [(value)]="color"
        [format]="format"
        [gradientSettings]="settings"
        (valueChange)="onChangeColor($event)"
        (close)="onPickColor($event)"
      ></kendo-colorpicker>
    </div>
  </div>
</div>

<div class="contentblock">
  <h3>Composition</h3>

  <div class="form-group row">
    <label for="language" class="col-sm-2 col-form-label">Language</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="languages"
        [data]="languages"
        [textField]="'name'"
        [valueField]="'code'"
        [valuePrimitive]="true"
        [(ngModel)]="language"
        (valueChange)="onSelectLanguage($event)"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <img
            src="assets/images/flags/{{ dataItem?.code }}.png"
            border="0"
          />&nbsp;{{ dataItem?.name }}
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <img
            src="assets/images/flags/{{ dataItem?.code }}.png"
            border="0"
          />&nbsp;{{ dataItem?.name }}
        </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row">
    <label for="replytoname" class="col-sm-2 col-form-label"
      >Reply to name</label
    >
    <div class="col-sm-10">
      <input
        name="text"
        [(ngModel)]="mailing.composition[language].replytoname"
      />
    </div>
  </div>

  <div class="form-group row">
    <label for="replytoemail" class="col-sm-2 col-form-label"
      >Reply to email</label
    >
    <div class="col-sm-10">
      <input
        name="text"
        [(ngModel)]="mailing.composition[language].replytoemail"
      />
    </div>
  </div>

  <kendo-tabstrip>
    <kendo-tabstrip-tab [title]="'Initial mailing texts'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="form-group row">
          <label for="subject" class="col-sm-2 col-form-label"
            >Mail subject</label
          >
          <div class="col-sm-10">
            <input
              name="subject"
              [(ngModel)]="mailing.composition[language].subject"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="invitation" class="col-sm-2 col-form-label"
            >Invitation text</label
          >
          <div class="col-sm-10">
            <input
              name="invitation"
              [(ngModel)]="mailing.composition[language].invitation"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="mailhtml" class="col-sm-2 col-form-label"
            >Mail HTML</label
          >
          <div class="col-sm-10">
            <app-html-editor
              [model]="mailing.composition[language].mailhtml"
              (modelChanged)="mailing.composition[language].mailhtml = $event"
              style="height: 200px"
            ></app-html-editor>
          </div>
        </div>

        <div class="form-group row">
          <label for="mailtext" class="col-sm-2 col-form-label"
            >Mail TEXT</label
          >
          <div class="col-sm-10">
            <textarea
              [(ngModel)]="mailing.composition[language].mailtext"
              style="height: 200px"
            ></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label for="footerhtml" class="col-sm-2 col-form-label"
            >Footer HTML</label
          >
          <div class="col-sm-10">
            <app-html-editor
              [model]="mailing.composition[language].footerhtml"
              (modelChanged)="mailing.composition[language].footerhtml = $event"
              style="height: 200px"
            ></app-html-editor>
          </div>
        </div>

        <div class="form-group row">
          <label for="footertext" class="col-sm-2 col-form-label"
            >Footer TEXT</label
          >
          <div class="col-sm-10">
            <textarea
              [(ngModel)]="mailing.composition[language].footertext"
              style="height: 200px"
            ></textarea>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Reminder mailing texts'">
      <ng-template kendoTabContent>
        <div class="form-group row">
          <label for="remindersubject" class="col-sm-2 col-form-label"
            >Reminder Mail subject</label
          >
          <div class="col-sm-10">
            <input
              name="subject"
              [(ngModel)]="mailing.composition[language].remindersubject"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="reminderinvitation" class="col-sm-2 col-form-label"
            >Reminder Invitation text</label
          >
          <div class="col-sm-10">
            <input
              name="invitation"
              [(ngModel)]="mailing.composition[language].reminderinvitation"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="remindermailhtml" class="col-sm-2 col-form-label"
            >Reminder Mail HTML</label
          >
          <div class="col-sm-10">
            <app-html-editor
              [model]="mailing.composition[language].remindermailhtml"
              (modelChanged)="
                mailing.composition[language].remindermailhtml = $event
              "
              style="height: 200px"
            >
            </app-html-editor>
          </div>
        </div>

        <div class="form-group row">
          <label for="remindermailtext" class="col-sm-2 col-form-label"
            >Reminder Mail TEXT</label
          >
          <div class="col-sm-10">
            <textarea
              [(ngModel)]="mailing.composition[language].remindermailtext"
              style="height: 200px"
            ></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label for="reminderfooterhtml" class="col-sm-2 col-form-label"
            >Reminder Footer HTML</label
          >
          <div class="col-sm-10">
            <app-html-editor
              [model]="mailing.composition[language].reminderfooterhtml"
              (modelChanged)="
                mailing.composition[language].reminderfooterhtml = $event
              "
              style="height: 200px"
            >
            </app-html-editor>
          </div>
        </div>

        <div class="form-group row">
          <label for="reminderfootertext" class="col-sm-2 col-form-label"
            >Reminder Footer TEXT</label
          >
          <div class="col-sm-10">
            <textarea
              [(ngModel)]="mailing.composition[language].reminderfootertext"
              style="height: 200px"
            ></textarea>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <button type="button" (click)="doUpdateMailing()">
    Save mailing for language {{ language }}
  </button>
</div>
