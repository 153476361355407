<div>
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu pull-right">
      <button
        (click)="onGoToSurveyList()"
        class="btn-actions"
        title="Survey list"
      >
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="types"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>Templates</h1>

      <div *ngIf="selectedItem">
        <div class="contentblock">
          <h3>{{ selectedItem.text }} templates</h3>

          <div class="form-group row">
            <label for="name" class="col-sm-2 col-form-label"
              >Template name</label
            >
            <div class="col-sm-10">
              <input type="text" name="name" [(ngModel)]="name" />
            </div>
          </div>

          <div class="form-group row">
            <label for="save" class="col-sm-2 col-form-label"></label>
            <div class="col-sm-10">
              <button type="button" (click)="AddTemplate()">
                Add Template
              </button>
            </div>
          </div>
        </div>

        <div class="contentblock">
          <kendo-grid [data]="templates">
            <kendo-grid-column field="name" title="Template name">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span
                  ><a
                    href="/template/{{ selectedItem.id }}/{{ dataItem.key }}"
                    >{{ dataItem.info.name }}</a
                  ></span
                >
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="delete" title="Delete">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span (click)="onRemoveTemplate(dataItem)"
                  ><fa-icon
                    [icon]="['fas', 'trash']"
                    style="color: #dd5a43"
                  ></fa-icon
                ></span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>
