<div class="contentblock">
  <h3>Info</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">{{ item.text }}</label>
    <div class="col-sm-10">
      <input name="text" [(ngModel)]="item.text" (mouseout)="onChangeInfo()" />
    </div>
  </div>

  <div class="form-group row">
    <label for="template" class="col-sm-2 col-form-label">Template</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="template"
        [data]="templates"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="item.template"
        (valueChange)="onChangeInfo()"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row">
    <label for="display" class="col-sm-2 col-form-label">Display</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="display"
        [data]="displays"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="item.display"
        (valueChange)="onChangeInfo()"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row" *ngIf="item.type == 'CHAPTER'">
    <label for="icon" class="col-sm-2 col-form-label">Icon</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="icon"
        [data]="icons"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="item.icon"
        (valueChange)="onChangeInfo()"
      ></kendo-dropdownlist>
    </div>
  </div>
</div>
