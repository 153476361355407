<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Client API test</h3>

    <app-survey-respondent
      [survey]="survey"
      [columns]="columns"
      [displaymailings]="displaymailings"
      [mailings]="mailings"
      (itemChanged)="onAddInvitation($event)"
    ></app-survey-respondent>
  </div>
</div>
