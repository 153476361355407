<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Client Keys</h3>

    <button (click)="onAddClientKey()" class="btn-actions">
      <fa-icon [icon]="['fas', 'plus']" style="color: orange"></fa-icon>
    </button>

    <kendo-grid [data]="clientkeys">
      <kendo-grid-column field="key" title="Client Key"></kendo-grid-column>
      <kendo-grid-column field="survey" title="Survey"></kendo-grid-column>
      <kendo-grid-column field="mailing" title="Mailing"></kendo-grid-column>
      <kendo-grid-column
        field="delete"
        title="Delete"
        [filterable]="false"
        [width]="50"
      >
        <ng-template kendoGridCellTemplate let-dataItem
          ><span (click)="onRemoveClientKey(dataItem)"
            ><fa-icon
              [icon]="['fas', 'trash']"
              style="color: #dd5a43"
            ></fa-icon></span
        ></ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
