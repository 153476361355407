export const QUESTION_LANGUAGE_ID = "4fe42c11c55d7caf61066a154bbb7906";
export const QUESTION_LANGUAGE = {
  id: QUESTION_LANGUAGE_ID,
  text: "Language",
  type: "QUESTION",
  form: "SINGLECHOICE",
  style: "SELECT",
  required: true,
  visibility: true,
  items: [
    {
      id: "bf592a0450f32ecb09500797b80e5892",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Arabic",
    },
    {
      id: "3676a735f7dba931512b32454075f534",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Brazilian",
    },
    {
      id: "9511aefa749611e9b5aa784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Bulgarian",
    },
    {
      id: "5b491bdc139d659515944d6cfc6a59f9",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Catalan",
    },
    {
      id: "842a70f73bdfde7c234a25ee1d20b775",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Chinese",
    },
    {
      id: "09b77fa6d07c11e88dbb784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Croatian",
    },
    {
      id: "12362308d07c11e89069784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Czech",
    },
    {
      id: "5ae538f4749711e9ba05784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Danish",
    },
    {
      id: "8dd2ce521ecfdc5fc879b9e6d256136c",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Dutch",
    },
    {
      id: "837143c9573ed511955d89b303f576e1",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "English",
    },
    {
      id: "7e4948bb124711822a78077dc23dc0b3",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Finnish",
    },
    {
      id: "c1cb0f34d07b11e89f6a784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Hungarian",
    },
    {
      id: "f67b027b965d88b64403a5afe36794c3",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "French",
    },
    {
      id: "81e7e49ed09711e8b290784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "French1",
    },
    {
      id: "81ecf28dca3e8d10f1287e0927c6805c",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "German",
    },
    {
      id: "f514bddcd09611e88f03784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "German1",
    },
    {
      id: "fbddcf64d09611e882e2784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "German2",
    },
    {
      id: "0fa22e7f57e95c0d725dae6318460264",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Italian",
    },
    {
      id: "e4eba66b77fbfd8e287cc8883ee132a8",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Japanese",
    },
    {
      id: "07ac3d5d7aca01c6b764af2222837bef",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Korean",
    },
    {
      id: "fedae658749611e9a534784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Latvian",
    },
    {
      id: "d2b67f411d58258c64c636b415034170",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Malay",
    },
    {
      id: "da42d9647ca7a9efb39ee77816ac7ef7",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Norwegian",
    },
    {
      id: "af338066c1be5abc22e3c021d47c1951",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Polish",
    },
    {
      id: "2374da1222f0ea4ae9bf176f8813cb6f",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Portuguese",
    },
    {
      id: "325abe8452f3f19d4f88a21dd6b0e364",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Romanian",
    },
    {
      id: "ddf06c573598c7ae1484e53a027218b4",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Russian",
    },
    {
      id: "d0b56ffef312b82f755ca922a2e5f2e1",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Slovak",
    },
    {
      id: "8886cf18749511e9bf47784f438a5ad0",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Slovenian",
    },
    {
      id: "dc38a18c779ed9390e0f2b7038058d33",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Spanish",
    },
    {
      id: "363bf143d005ba4fac46aba82c65ca2d",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Swedish",
    },
    {
      id: "e103ad15fc341317a37500fe72d97cac",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Taiwanese",
    },
    {
      id: "98aa6f879d6d3eb654c0c73137d07212",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Thai",
    },
    {
      id: "0f3aeb108f67fab4ccb32cb9c90943b9",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Turkish",
    },
    {
      id: "1dd77f26ee79dbd2016328d7451bf58b",
      parent: QUESTION_LANGUAGE_ID,
      style: "",
      required: false,
      visibility: true,
      type: "ANSWER",
      text: "Ukrainian",
    },
  ],
};

export const SYS_LANGAUGES = [
  { code: "ar", name: "Arabic" },
  { code: "bg", name: "Bulgarian" },
  { code: "br", name: "Brazilian" },
  { code: "ca", name: "Catalan" },
  { code: "cn", name: "Chinese" },
  { code: "hr", name: "Croatian" },
  { code: "cs", name: "Czech" },
  { code: "da", name: "Danish" },
  { code: "nl", name: "Dutch" },
  { code: "en", name: "English" },
  { code: "fi", name: "Finnish" },
  { code: "fr", name: "French" },
  { code: "fr1", name: "French 1" },
  { code: "de", name: "German" },
  { code: "de1", name: "German 1" },
  { code: "de2", name: "German 2" },
  { code: "hu", name: "Hungarian" },
  { code: "it", name: "Italian" },
  { code: "jp", name: "Japanese" },
  { code: "kr", name: "Korean" },
  { code: "lv", name: "Latvian" },
  { code: "ms", name: "Malay" },
  { code: "no", name: "Norwegian" },
  { code: "pl", name: "Polish" },
  { code: "pt", name: "Portuguese" },
  { code: "ro", name: "Romanian" },
  { code: "ru", name: "Russian" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "es", name: "Spanish" },
  { code: "sv", name: "Swedish" },
  { code: "tw", name: "Taiwanese" },
  { code: "th", name: "Thai" },
  { code: "tr", name: "Turkish" },
];
