import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsersService } from "../services/users.service";
import { JobsService } from "../services/jobs.service";
import { AuthService } from "../services/auth.service";
import { process, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import { map } from "rxjs/operators";

@Component({
  selector: "app-sysusers",
  templateUrl: "./sysusers.component.html",
  styleUrls: ["./sysusers.component.css"],
})
export class SysusersComponent implements OnInit {
  sysusers: any = [];
  sysuser: any = { name: "", email: "", role: "integron" };
  emails: any = {};
  state: State = { filter: null };
  initialized: boolean = false;

  constructor(
    private usersService: UsersService,
    private jobsService: JobsService,
    private authService: AuthService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.InitializeSysUsers();
  }

  InitializeSysUsers() {
    this.usersService
      .getUsersList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((users) => {
        this.sysusers = [];

        for (let user of users) {
          if (user.hasOwnProperty("info")) {
            this.emails[user["info"]["email"]] = true;
            if (user["info"]["role"] == "integron") {
              this.sysusers.push({
                id: user.key,
                name: user.info.name,
                email: user.info.email,
              });
            }
          }
        }

        this.sysusers.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.gridData = process(this.sysusers, this.state);

        this.initialized = true;
      });
  }

  public gridData: GridDataResult = process(this.sysusers, this.state);

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.sysusers, this.state);
  }

  public IsEmailTaken() {
    return this.emails.hasOwnProperty(this.sysuser.email);
  }

  public onAddNewSysUser() {
    this.authService.SignUp(this.sysuser);
  }

  public onResetPassword(dataItem: any) {
    if (
      confirm(
        "Are you sure you want to send a reset password for this sys user?",
      ) == true
    ) {
      this.authService.SendPasswordResetEmail(dataItem.email);
    }
  }

  public onRemoveSysUser(dataItem: any) {
    let id = dataItem.id;
    if (
      confirm(
        "Are you sure you want to remove this sys user? He will lose all his privileges.",
      ) == true
    ) {
      this.usersService.deleteUser(id);
      this.jobsService.addJob({
        type: "delete_client_authorization",
        state: "WAITING_FOR_PROCESS",
        data: { user: this.authService.GetCurrentUser(), client: id },
      });
    }
  }
}
