<div class="contentblock">
  <h3>Form</h3>

  <div class="form-group row">
    <label for="form" class="col-sm-2 col-form-label">Form</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="form"
        [data]="forms"
        [textField]="'name'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="item.form"
        (valueChange)="onChangeForm()"
      ></kendo-dropdownlist>
    </div>
  </div>

  <div class="form-group row" *ngIf="item.form == 'MULTIPLECHOICE'">
    <label for="minimum" class="col-sm-2 col-form-label">Minimum</label>
    <div class="col-sm-10">
      <input
        type="number"
        name="minimum"
        [(ngModel)]="item.minimum"
        (change)="onChangeForm()"
      />
    </div>
  </div>

  <div class="form-group row" *ngIf="item.form == 'MULTIPLECHOICE'">
    <label for="maximum" class="col-sm-2 col-form-label">Maximum</label>
    <div class="col-sm-10">
      <input
        type="number"
        name="maximum"
        [(ngModel)]="item.maximum"
        (change)="onChangeForm()"
      />
    </div>
  </div>

  <div
    class="form-group row"
    *ngIf="item.form == 'MULTIPLECHOICE' && item.style == 'DIVISION'"
  >
    <label for="divisionvalue" class="col-sm-2 col-form-label"
      >Division value</label
    >
    <div class="col-sm-10">
      <input
        type="number"
        name="divisionvalue"
        [(ngModel)]="item.divisionvalue"
        (change)="onChangeForm()"
      />
    </div>
  </div>

  <div
    class="form-group row"
    *ngIf="item.form == 'SINGLECHOICE' && item.style == 'HORIZONTAL'"
  >
    <label for="label" class="col-sm-2 col-form-label">Buttoned style</label>
    <div class="col-sm-10">
      <kendo-switch
        name="isButtoned"
        [(ngModel)]="item.isButtoned"
        (valueChange)="onChangeForm()"
      ></kendo-switch>
    </div>
  </div>
</div>
