<div class="contentblock">
  <h3>Filter</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Is Filter</label>
    <div class="col-sm-10">
      <kendo-switch
        name="isFilter"
        [(ngModel)]="item.isFilter"
        (valueChange)="onChangeFilter()"
      ></kendo-switch>
    </div>
  </div>
</div>
