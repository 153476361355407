import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { GradientSettings, OutputFormat } from "@progress/kendo-angular-inputs";
import { SurveysService } from "../../../../services/surveys.service";
import { SYS_LANGAUGES } from "../../../../shared/language";

@Component({
  selector: "app-survey-mailing",
  templateUrl: "./survey-mailing.component.html",
  styleUrls: ["./survey-mailing.component.css"],
})
export class SurveyMailingComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Input() respondents: any;
  @Input() times: any;
  @Output() itemChanged = new EventEmitter();

  languages: any = SYS_LANGAUGES;
  language: any = "nl";
  mailing: any = null;
  steps: any = { year: 10, month: 2, day: 5, hour: 1, minute: 15 };
  color: any = "#fe413b";
  preview: boolean = true;
  importbuttons: boolean = false;
  format: OutputFormat = "hex";
  settings: GradientSettings = { opacity: false };
  extensions: any = [".html"];
  attached: any = {};

  constructor(private surveysService: SurveysService) {}

  ngOnChanges(): void {
    this.mailing = {};
    if (this.item) {
      for (let key in this.item) {
        if (key == "schedulertime") {
          if (this.item[key].length > 0)
            this.mailing[key] = new Date(this.item[key]);
          else this.mailing[key] = new Date();
        } else {
          this.mailing[key] = this.item[key];
        }
      }
    }

    if (this.mailing.hasOwnProperty("customcolor")) {
      this.color = this.mailing["customcolor"];
    } else {
      this.color = "#fe413b";
    }

    this.InitializeMailingComposition();

    this.attached["mailing"] = this.mailing.id;
  }

  InitializeMailingComposition() {
    if (!this.mailing.hasOwnProperty("composition")) {
      this.mailing["composition"] = {};
    }
    if (!this.mailing["composition"].hasOwnProperty(this.language)) {
      this.mailing["composition"][this.language] = {
        replytoname: "",
        replytoemail: "",
        subject: "",
        invitation: "",
        mailhtml: "",
        mailtext: "",
        footerhtml: "",
        footertext: "",
        remindersubject: "",
        reminderinvitation: "",
        remindermailhtml: "",
        remindermailtext: "",
        reminderfooterhtml: "",
        reminderfootertext: "",
      };
    }
  }

  onSelectLanguage(event: any) {
    this.InitializeMailingComposition();
  }

  onChangeItem() {
    this.doUpdateMailing();
  }

  onChangeColor(event: any) {
    this.mailing["customcolor"] = this.color;
  }

  onPickColor(event: any) {
    this.doUpdateMailing();
  }

  doCancelScheduledMailing() {
    if (confirm("Are you sure you want to cancel the mailing?") == true) {
      this.mailing["status"] = "READY";
      this.doUpdateMailing();
    }
  }

  doUpdateMailing() {
    let event = { item: this.mailing };

    this.itemChanged.emit(event);
  }
}
