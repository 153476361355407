import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";

@Injectable({
  providedIn: "root",
})
export class TemplatesService {
  constructor(private firebase: AngularFireDatabase) {}

  getTemplatesList(type: string): AngularFireList<any> {
    return this.firebase.list("/Templates/" + type + "/");
  }

  getTemplateInfo(type: string, item: string): AngularFireObject<any> {
    return this.firebase.object("/Templates/" + type + "/" + item + "/info");
  }

  getTemplateItems(type: string, item: string): AngularFireList<any> {
    return this.firebase.list("/Templates/" + type + "/" + item + "/items");
  }

  addTemplateSurveyItem(type: string, template: string, item: any) {
    return this.firebase
      .list("/Templates/" + type + "/" + template + "/items")
      .push(item);
  }

  updateTemplateSurveyItem(
    type: string,
    template: string,
    item: any,
  ): Promise<void> {
    let surveyItem = JSON.parse(JSON.stringify(item));
    let surveyItemRef = this.firebase.list(
      "/Templates/" + type + "/" + template + "/items",
    );
    let key = surveyItem.key;
    delete surveyItem.key;
    return surveyItemRef.update(key, surveyItem);
  }

  deleteTemplateSurveyItem(
    type: string,
    template: string,
    item: string,
  ): Promise<void> {
    let surveyItemRef = this.firebase.object(
      "/Templates/" + type + "/" + template + "/items/" + item,
    );
    if (surveyItemRef) return surveyItemRef.remove();
  }

  addTemplate(type: string, item: any) {
    let templateItemRef = this.firebase.list("/Templates/" + type);
    return templateItemRef.update(item.info.id, item);
  }

  updateTemplate(type: string, template: string, item: any): Promise<void> {
    let templateItemRef = this.firebase.list(
      "/Templates/" + type + "/" + template,
    );
    let key = item.key;
    delete item.key;
    return templateItemRef.update(key, item);
  }

  deleteTemplate(type: string, template: string): Promise<void> {
    let templateItemRef = this.firebase.object(
      "/Templates/" + type + "/" + template,
    );
    if (templateItemRef) return templateItemRef.remove();
  }

  getTemplateLabels(template: string): AngularFireObject<any> {
    return this.firebase.object("/TemplateLabels/" + template);
  }

  updateTemplateSurveyItemLabel(
    template: string,
    item: string,
    language: any,
    label: any,
  ): Promise<void> {
    let surveyItemLabelRef = this.firebase.list(
      "/TemplateLabels/" + template + "/" + item,
    );
    return surveyItemLabelRef.set(language, label);
  }

  getTemplateMailingComposition(
    type: string,
    item: string,
  ): AngularFireObject<any> {
    return this.firebase.object(
      "/Templates/" + type + "/" + item + "/composition",
    );
  }

  updateTemplateMailingComposition(
    type: string,
    template: string,
    language: any,
    composition: any,
  ): Promise<void> {
    let mailingItemRef = this.firebase.list(
      "/Templates/" + type + "/" + template + "/composition",
    );
    return mailingItemRef.update(language, composition);
  }

  updateTemplateMailingCustom(
    type: string,
    template: string,
    usecustom: boolean,
  ): Promise<void> {
    let mailingItemRef = this.firebase.object(
      "/Templates/" + type + "/" + template + "/info/usecustom",
    );
    return mailingItemRef.set(usecustom);
  }

  updateTemplateMailingColor(
    type: string,
    template: string,
    color: string,
  ): Promise<void> {
    let mailingItemRef = this.firebase.object(
      "/Templates/" + type + "/" + template + "/info/customcolor",
    );
    return mailingItemRef.set(color);
  }
}
