import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-style",
  templateUrl: "./ctrl-item-style.component.html",
  styleUrls: ["./ctrl-item-style.component.css"],
})
export class CtrlItemStyleComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  styles: any = [];

  constructor() {}

  ngOnChanges(): void {
    this.styles = [];

    if (this.item.type == "QUESTION") {
      if (this.item.form != "TEXT") {
        this.styles.push({ id: "VERTICAL", name: "Vertical" });
        this.styles.push({ id: "HORIZONTAL", name: "Horizontal" });
      }

      if (this.item.form == "SINGLECHOICE") {
        this.styles.push({ id: "SELECT", name: "Select" });
        this.styles.push({ id: "RATING", name: "Rating" });
        this.styles.push({ id: "SLIDER", name: "Slider" });
      }

      if (this.item.form == "MULTIPLECHOICE") {
        this.styles.push({ id: "DRAGANDDROP", name: "Drag and drop" });
        this.styles.push({ id: "DIVISION", name: "Division" });
      }

      if (this.item.form == "TEXT") {
        this.styles.push({ id: "TEXTBIG", name: "Text big" });
        this.styles.push({ id: "TEXTSMALL", name: "Text small" });
        this.styles.push({ id: "TEXTAREA", name: "Textarea" });
      }
    }

    if (this.item.type == "MATRIX") {
      this.styles.push({ id: "MATRIX", name: "Matrix" });
    }
  }

  public onChangeStyle() {
    this.itemChanged.emit({ item: this.item });
  }
}
