<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Rules</h3>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Type</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="types"
          [data]="types"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.type"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Group</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="segmentGroups"
          [data]="survey.segments"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.item"
          (valueChange)="onSelectSegmentGroup($event)"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Segment</label>
      <div class="col-sm-10">
        <kendo-multiselect
          name="segments"
          [data]="surveySegments"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.subitems"
        ></kendo-multiselect>
      </div>
    </div>

    <div class="form-group row" *ngIf="selected.type == 'QUOTA'">
      <label for="label" class="col-sm-2 col-form-label">Max</label>
      <div class="col-sm-10">
        <input type="number" name="max" [(ngModel)]="selected.max" />
      </div>
    </div>

    <div class="form-group row" *ngIf="selected.type == 'TIME'">
      <label for="label" class="col-sm-2 col-form-label">Time</label>
      <div class="col-sm-10">
        <input type="number" name="time" [(ngModel)]="selected.time" />
      </div>
    </div>

    <div
      class="form-group row"
      *ngIf="selected.type && selected.item && selected.subitems.length > 0"
    >
      <label for="label" class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10">
        <button (click)="doAddRule()">Add Rule</button>
      </div>
    </div>
  </div>

  <div class="contentblock" *ngFor="let ruleType of rules | keyvalue">
    <h3>{{ ruleType.key }} Rules</h3>
    <kendo-grid [data]="ruleType.value" [sortable]="{ mode: 'single' }">
      <kendo-grid-column field="item" title="Item">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ survey.GetSurveySegmentById(dataItem.item) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="subitem" title="Subitem">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ survey.GetSurveySegmentById(dataItem.subitem) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="max"
        title="Max"
        *ngIf="ruleType.key == 'QUOTA'"
      ></kendo-grid-column>
      <kendo-grid-column
        field="time"
        title="Time"
        *ngIf="ruleType.key == 'TIME'"
      ></kendo-grid-column>
      <kendo-grid-column field="remove" title="Remove">
        <ng-template kendoGridCellTemplate let-dataItem>
          <fa-icon
            [icon]="['fas', 'times']"
            style="color: orange"
            (click)="doDeleteRule(ruleType.key, dataItem)"
          ></fa-icon>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
