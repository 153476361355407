import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class JobsService {
  constructor(private firebase: AngularFireDatabase) {}

  getJobsList(): AngularFireList<any> {
    return this.firebase.list("/Jobs");
  }

  addJob(job: any) {
    job["platform"] = environment.platform;
    let jobsRef = this.firebase.list("/Jobs");
    return jobsRef.push(job);
  }

  IsJobTypeInQueueForSurvey(jobs: any, type: any, survey: any): boolean {
    for (let job of jobs) {
      if (job["type"] == type) {
        if (job.hasOwnProperty("data")) {
          if (job["data"].hasOwnProperty("survey")) {
            if (job["data"]["survey"] == survey) return true;
          }
        }
      }
    }
    return false;
  }

  deleteJob(job: any) {
    let jobRef = this.firebase.object("/Jobs/" + job);
    if (jobRef) return jobRef.remove();
  }
}
