import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-visibility",
  templateUrl: "./ctrl-item-visibility.component.html",
  styleUrls: ["./ctrl-item-visibility.component.css"],
})
export class CtrlItemVisibilityComponent implements OnInit, OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  operators: any = [
    { id: "AND", name: "And" },
    { id: "OR", name: "Or" },
  ];
  actions: any = [
    { id: "SELECTED", name: "Is selected" },
    { id: "NOTSELECTED", name: "Is not selected" },
  ];
  surveyItems: any = [];
  surveySubItems: any = [];
  surveySegments: any = [];
  targetItems: any = [];
  selected: any = {
    operator: "OR",
    item: null,
    subitems: [],
    action: "SELECTED",
  };

  constructor() {}

  ngOnInit(): void {
    for (let item of this.survey.items) {
      if (item.type == "QUESTION")
        this.surveyItems.push({ id: item["id"], name: item["text"] });
      if (item.type == "SUCCESSFACTOR" || item.type == "MATRIX") {
        if (item.hasOwnProperty("items")) {
          for (let subitem of item.items) {
            this.surveyItems.push({ id: subitem["id"], name: subitem["text"] });
          }
        }
      }
      this.targetItems.push({ id: item["id"], name: item["text"] });
    }
    this.surveyItems.push({ id: "RESPONDENT", name: "Respondent" });

    for (let item of this.survey.segments) {
      if (item.hasOwnProperty("items")) {
        for (let subitem of item.items) {
          this.surveySegments.push({
            id: subitem["id"],
            name: item["text"] + " - " + subitem["text"],
          });
        }
      }
    }
  }

  ngOnChanges(): void {
    this.selected = {
      operator: "OR",
      item: null,
      subitems: [],
      action: "SELECTED",
    };
  }

  onSelectSurveyItem(event: any) {
    this.surveySubItems = [];

    if (this.selected.item == "RESPONDENT") {
      for (let item of this.surveySegments) {
        this.surveySubItems.push({ id: item["id"], name: item["name"] });
      }
    } else {
      for (let item of this.survey.items) {
        if (item.id == this.selected.item) {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              this.surveySubItems.push({
                id: subitem["id"],
                name: subitem["text"],
              });
            }
          }
        } else {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == this.selected.item) {
                if (subitem.type == "INDICATOR") {
                  if (
                    this.survey.info.subindicators.hasOwnProperty("experience")
                  ) {
                    if (this.survey.info.subindicators.experience) {
                      for (
                        let pos = 1;
                        pos <= parseInt(this.survey.info.scale);
                        pos++
                      ) {
                        this.surveySubItems.push({
                          id: subitem.id + "_E_" + pos,
                          name: "E_" + pos,
                        });
                      }
                    }
                  }
                  if (
                    this.survey.info.subindicators.hasOwnProperty("importance")
                  ) {
                    if (this.survey.info.subindicators.importance) {
                      for (let pos = 1; pos <= 4; pos++) {
                        this.surveySubItems.push({
                          id: subitem.id + "_I_" + pos,
                          name: "I_" + pos,
                        });
                      }
                    }
                  }
                  if (
                    this.survey.info.subindicators.hasOwnProperty("performance")
                  ) {
                    if (this.survey.info.subindicators.performance) {
                      for (let pos = 1; pos <= 3; pos++) {
                        this.surveySubItems.push({
                          id: subitem.id + "_P_" + pos,
                          name: "P_" + pos,
                        });
                      }
                    }
                  }
                }

                if (subitem.type == "QUESTION") {
                  if (item.hasOwnProperty("subindicators")) {
                    for (let subindicator of item.subindicators) {
                      for (
                        let pos = 1;
                        pos <= parseInt(subindicator.scale);
                        pos++
                      ) {
                        this.surveySubItems.push({
                          id: subitem.id + "_" + subindicator.id + "_" + pos,
                          name: subindicator.text + "_" + pos,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  onChangeVisibility(event: any) {
    this.itemChanged.emit({ item: this.item });
  }

  ParseItemId(itemid: string): string {
    if (itemid == "RESPONDENT") {
      return "Respondent";
    } else {
      for (let item of this.survey.items) {
        if (item.id == itemid) {
          return item.text;
        } else {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == itemid) {
                return subitem.text;
              }
            }
          }
        }
      }
    }
  }

  ParseSubItemId(itemid: string, subitemid: string): string {
    if (itemid == "RESPONDENT") {
      for (let segment of this.surveySegments) {
        if (segment.id == subitemid) return segment.name;
      }
    } else {
      for (let item of this.survey.items) {
        if (item.id == itemid) {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == subitemid) {
                return subitem.text;
              }
            }
          }
        }
        if (item.type == "SUCCESSFACTOR" || item.type == "MATRIX") {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == itemid) {
                if (subitem.type == "INDICATOR")
                  return subitemid.substr(33, subitem.text.length);
                if (subitem.type == "QUESTION") {
                  for (let subindicator of item.subindicators) {
                    if (subitemid.indexOf(subindicator.id) > -1)
                      return (
                        subindicator.text +
                        subitemid.substr(66, subitem.text.length)
                      );
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  onAddRelationship() {
    if (this.selected.item && this.selected.subitems.length > 0) {
      if (!this.item.hasOwnProperty("relationships"))
        this.item["relationships"] = [];

      for (let subitem of this.selected.subitems) {
        this.item["relationships"].push({
          operator: this.selected.operator,
          item: this.selected.item,
          subitem: subitem,
          action: this.selected.action,
        });
      }

      this.itemChanged.emit({ item: this.item });

      this.selected = {
        operator: "OR",
        item: null,
        subitems: [],
        action: "SELECTED",
      };
      this.surveySubItems = [];
    }
  }

  onDeleteRelationship(relationship: any) {
    let index = this.item.relationships.indexOf(relationship);
    this.item.relationships.splice(index, 1);
    this.itemChanged.emit({ item: this.item });
  }
}
