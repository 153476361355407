import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TemplatesService } from "../../services/templates.service";
import { AuthService } from "../../services/auth.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-template-item-choice",
  templateUrl: "./template-item-choice.component.html",
  styleUrls: ["./template-item-choice.component.css"],
})
export class TemplateItemChoiceComponent implements OnInit {
  @Input() type: any;
  @Output() itemChoosed = new EventEmitter();

  selectedItem: any = null;
  templates: any = [];
  initialized: boolean = false;
  templatesById: any = {};

  constructor(
    private templatesService: TemplatesService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.InitializeTemplate();
  }

  InitializeTemplate() {
    this.templatesService
      .getTemplatesList(this.type)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((templates) => {
        this.templates = [];
        for (let template of templates) {
          this.templates.push({
            id: template.info.id,
            name: template.info.name,
          });
          this.templatesById[template.info.id] = template;
        }
        this.initialized = true;
      });
  }

  public onChooseTemplate() {
    this.itemChoosed.emit({ item: this.templatesById[this.selectedItem] });
  }
}
