import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-transformations",
  templateUrl: "./survey-transformations.component.html",
  styleUrls: ["./survey-transformations.component.css"],
})
export class SurveyTransformationsComponent implements OnInit {
  @Input() survey: any;

  initialized: boolean = false;
  transformations: any = {};
  surveySegments: any = [];
  selected: any = { segmentgroup: null, segment: null, replacesegment: null };

  constructor(private surveysService: SurveysService) {}

  ngOnInit(): void {
    this.InitializeSurveyTransformations();
  }

  InitializeSurveyTransformations() {
    this.surveysService
      .getSurveyTransformations(this.survey.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((transformations) => {
        if (transformations) this.transformations = transformations;
        this.initialized = true;
      });
  }

  onSelectSegmentGroup(event: any) {
    this.surveySegments = [];
    this.selected.segment = null;
    this.selected.replacesegment = null;
    this.surveySegments = this.survey.GetSurveySegmentSubsegments(
      this.selected.segmentgroup,
    );
  }

  doAddTransformation() {
    this.surveysService.addSurveyTransformation(this.survey.id, this.selected);
    this.selected = { segmentgroup: null, segment: null, replacesegment: null };
  }

  doDeleteTransformation(transformation: any) {
    let index = this.transformations.indexOf(transformation);
    this.transformations.splice(index, 1);
    this.surveysService.deleteSurveyTransformation(
      this.survey.id,
      transformation.key,
    );
  }
}
