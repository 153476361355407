<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <span class="main-buttons">
        <button
          (click)="onGoToSurveyCompose()"
          class="btn btn-green"
          title="Compose"
        >
          <fa-icon [icon]="['fas', 'magic']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyLabels()"
          class="btn btn-blue"
          title="Labels"
        >
          <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyMailing()"
          class="btn btn-red"
          title="Mailings"
        >
          <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyDashbaord()"
          class="btn btn-purple"
          title="Dashbaord"
        >
          <fa-icon [icon]="['fas', 'table']"></fa-icon>
        </button>
      </span>

      <button (click)="onViewRules()" class="btn-actions" title="Rules">
        <fa-icon [icon]="['fas', 'code-branch']"></fa-icon>
      </button>

      <button
        (click)="onViewResegmentation()"
        class="btn-actions"
        title="Resegmentations"
      >
        <fa-icon [icon]="['fas', 'exchange-alt']"></fa-icon>
      </button>

      <button
        (click)="onViewTransformation()"
        class="btn-actions"
        title="Transformations"
      >
        <fa-icon [icon]="['fas', 'text-width']"></fa-icon>
      </button>

      <button (click)="onViewAPI()" class="btn-actions" title="API">
        <fa-icon [icon]="['fas', 'cog']"></fa-icon>
      </button>

      <button
        (click)="onAddSegmentGroup()"
        class="btn-actions"
        title="Add segment group"
      >
        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
      </button>

      <span *ngIf="selectedItem">
        <button
          (click)="onAddSegment()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'SEGMENTGROUP'"
          title="Add segment"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>
      </span>

      <button
        (click)="importWindowActive = true"
        class="btn-actions"
        title="Import contacts"
      >
        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
      </button>

      <button
        (click)="exportWindowActive = true"
        class="btn-actions"
        title="Export contacts"
      >
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
      </button>

      <button
        (click)="onViewRespondents()"
        class="btn-actions"
        title="Respondents"
      >
        <fa-icon [icon]="['fas', 'users']"></fa-icon>
      </button>

      <button
        (click)="onViewPublicMetaData()"
        class="btn-actions"
        title="Public Meta Data"
      >
        <fa-icon [icon]="['fas', 'unlock']"></fa-icon>
      </button>

      <button
        (click)="onExportPrototype()"
        class="btn-actions"
        title="Export prototype"
      >
        <fa-icon [icon]="['fas', 'file-archive']"></fa-icon>
      </button>

      <button (click)="onViewOrder()" class="btn-actions" title="View order">
        <fa-icon [icon]="['fas', 'network-wired']"></fa-icon>
      </button>

      <button
        (click)="onPublishContacts()"
        class="btn-actions"
        title="Publish contacts"
      >
        <fa-icon
          [icon]="['fas', 'paper-plane']"
          style="color: #478fca"
        ></fa-icon>
      </button>

      <button
        (click)="onResegmentateInvitations()"
        class="btn-actions"
        title="Resegmentate invitations"
      >
        <fa-icon
          [icon]="['fas', 'exchange-alt']"
          style="color: #478fca"
        ></fa-icon>
      </button>

      <button
        (click)="onRemoveSegment()"
        class="btn-actions"
        title="Remove segment"
      >
        <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
      </button>

      <button (click)="onGoToSurveyList()" class="btn-actions" title="Surveys">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="surveyObj.segments"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }}
        <span class="selection" *ngIf="selectedItem"
          >>> {{ selectedItem.text }}</span
        >
      </h1>

      <div *ngIf="selectedItem">
        <div *ngIf="selectedItem.type == 'SEGMENTGROUP'">
          <app-ctrl-segment-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-segment-info>
          <app-ctrl-segment-filter
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-segment-filter>
          <app-ctrl-segment-dynamic
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-segment-dynamic>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-segment-exclude
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-segment-exclude>
        </div>

        <div *ngIf="selectedItem.type == 'SEGMENT'">
          <app-ctrl-segment-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-segment-info>
          <app-ctrl-segment-exclude
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-segment-exclude>
        </div>
      </div>

      <div *ngIf="showSections">
        <div *ngIf="showSectionType == 'RESEGMENTATIONS'">
          <app-survey-resegmentations
            [survey]="surveyObj"
          ></app-survey-resegmentations>
        </div>

        <div *ngIf="showSectionType == 'TRANSFORMATIONS'">
          <app-survey-transformations
            [survey]="surveyObj"
          ></app-survey-transformations>
        </div>

        <div *ngIf="showSectionType == 'RULES'">
          <app-survey-rules [survey]="surveyObj"></app-survey-rules>
        </div>

        <div *ngIf="showSectionType == 'API'">
          <app-survey-api
            [survey]="surveyObj"
            [columns]="columns"
          ></app-survey-api>
        </div>

        <div *ngIf="showSectionType == 'RESPONDENTS'">
          <app-survey-respondents
            [survey]="surveyObj"
            [columns]="columns"
          ></app-survey-respondents>
        </div>

        <div *ngIf="showSectionType == 'METADATA'">
          <app-survey-metadata
            [survey]="surveyObj"
            [columns]="columns"
          ></app-survey-metadata>
        </div>

        <div *ngIf="showSectionType == 'ORDER'">
          <div class="contentblock">
            <div *ngIf="surveyObj.segments">
              <kendo-sortable
                [kendoSortableBinding]="surveyObj.segments"
                (dragEnd)="onOrderChanged($event)"
              >
                <ng-template let-subitem="item">
                  <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                  <b>{{ subitem.text }}</b> &nbsp;<span
                    >{{ labelsObj.getItemLabel(subitem.id) }}
                  </span>
                  <br /><br />
                </ng-template>
              </kendo-sortable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <kendo-window
      [top]="200"
      title="Import Contacts"
      *ngIf="importWindowActive"
      (close)="importWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'contacts'"
        [extensions]="extensions"
        (onWindowClose)="onCloseImportWindow()"
      ></app-ctrl-import-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Export Contacts"
      *ngIf="exportWindowActive"
      (close)="exportWindowActive = false"
    >
      <app-ctrl-export-file
        [survey]="surveyObj"
        [type]="'contacts'"
        (onWindowClose)="onCloseExportWindow()"
      ></app-ctrl-export-file>
    </kendo-window>
  </div>
</div>
