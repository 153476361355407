import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { SYS_LANGAUGES } from "../../../../shared/language";
import { map } from "rxjs/operators";

@Component({
  selector: "app-survey-respondent",
  templateUrl: "./survey-respondent.component.html",
  styleUrls: ["./survey-respondent.component.css"],
})
export class SurveyRespondentComponent implements OnChanges {
  @Input() survey: any;
  @Input() columns: any;
  @Input() mailings: any;
  @Input() item: any;
  @Input() displaymailings: any;
  @Output() itemChanged = new EventEmitter();

  languages: any = SYS_LANGAUGES;
  respondent: any = {};
  mailing: any = null;

  constructor(private surveysService: SurveysService) {}

  ngOnChanges(): void {
    this.InitializeRespondent();
  }

  InitializeRespondent() {
    let basecolumns = [
      "id",
      "loginname",
      "password",
      "autologin",
      "archive",
      "systime",
      "type",
      "language",
    ];
    this.respondent = {};
    this.mailing = null;

    if (this.item) {
      for (let column of this.columns) {
        if (this.item.hasOwnProperty(column.column)) {
          this.respondent[column.column] = this.item[column.column];
        } else {
          if (basecolumns.indexOf(column.column) == -1) {
            this.respondent[column.column] = "";
          }
        }
      }
      for (let segment of this.survey.segments) {
        if (this.item.hasOwnProperty(segment.text))
          this.respondent[segment.text] = this.item[segment.text];
      }
    }

    if (!this.respondent.hasOwnProperty("type")) {
      this.respondent["type"] = "TEST";
    }
    if (!this.respondent.hasOwnProperty("language")) {
      this.respondent["language"] = "en";
    }
  }

  IsSysColumn(column: any) {
    if (
      column.column == "id" ||
      column.column == "loginname" ||
      column.column == "password" ||
      column.column == "systime"
    )
      return true;
    else return false;
  }

  doUpdateRespondent() {
    let event = { item: this.respondent };

    if (this.displaymailings) event["mailing"] = this.mailing;

    this.itemChanged.emit(event);
  }
}
