<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <span *ngIf="selectedItem">
        <button
          (click)="onAddIndicator()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'SUCCESSFACTOR'"
          title="Add indicator"
        >
          <fa-icon [icon]="['far', 'object-ungroup']"></fa-icon>
        </button>

        <button
          (click)="addQuestionToMatrix()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'MATRIX'"
          title="Add question to matrix"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>

        <button
          (click)="onAddSubindicator()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'MATRIX'"
          title="Add matrix"
        >
          <fa-icon [icon]="['far', 'object-ungroup']"></fa-icon>
        </button>

        <button
          (click)="addAnswer()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'QUESTION' && selectedItem.parent == null"
          title="Add answer"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>
      </span>

      <button
        (click)="onAddQuestion()"
        class="btn-actions"
        title="Add question"
      >
        <fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
      </button>

      <button
        (click)="onOpenLanguageWindow()"
        class="btn-actions"
        title="Add language question"
      >
        <fa-icon [icon]="['fas', 'flag']"></fa-icon>
      </button>

      <button
        (click)="onAddSuccessFactor()"
        class="btn-actions"
        title="Add success factor"
      >
        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
      </button>

      <button (click)="onAddMatrix()" class="btn-actions" title="Add matrix">
        <fa-icon [icon]="['fas', 'th-list']"></fa-icon>
      </button>

      <button (click)="onAddText()" class="btn-actions" title="Add text">
        <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
      </button>

      <button
        (click)="onAddPageBreak()"
        class="btn-actions"
        title="Add page break"
      >
        <fa-icon [icon]="['fas', 'level-down-alt']" rotate="90"></fa-icon>
      </button>

      <button (click)="onAddMarker()" class="btn-actions" title="Add marker">
        <fa-icon [icon]="['fas', 'map-pin']" rotate="180"></fa-icon>
      </button>

      <button (click)="onAddSkip()" class="btn-actions" title="Add skip">
        <fa-icon [icon]="['fas', 'code-branch']" rotate="90"></fa-icon>
      </button>

      <button (click)="importWindowActive = true" class="btn-actions">
        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
      </button>

      <button (click)="exportWindowActive = true" class="btn-actions">
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
      </button>

      <button (click)="onCloneItem()" class="btn-actions" title="Clone item">
        <fa-icon [icon]="['fas', 'clone']"></fa-icon>
      </button>

      <button (click)="onRemoveItem()" class="btn-actions" title="Remove item">
        <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
      </button>

      <button
        (click)="onGoToTemplatesList()"
        class="btn-actions"
        title="Survey list"
      >
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="surveyObj.items"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }}
        <span class="selection" *ngIf="selectedItem"
          >>> {{ selectedItem.text }}</span
        >
      </h1>

      <div *ngIf="selectedItem">
        <div *ngIf="selectedItem.type == 'SUCCESSFACTOR'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
            [template]="templateOn"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-settings
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-settings>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'INDICATOR'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
            [template]="templateOn"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-settings
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-settings>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'MATRIX'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
            [template]="templateOn"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-style
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-style>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'QUESTION'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
            [template]="templateOn"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
          <app-ctrl-item-form
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-form>
          <app-ctrl-item-style
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-style>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-settings
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-settings>
          <app-ctrl-item-shuffle
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-shuffle>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'TEXT'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
            [template]="templateOn"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'ANSWER'">
          <app-ctrl-item-label
            [survey]="surveyObj"
            [item]="selectedItem"
            [labels]="labelsObj"
            [template]="templateOn"
          ></app-ctrl-item-label>
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
          <app-ctrl-item-required
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-required>
          <app-ctrl-item-additional
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-additional>
          <app-ctrl-item-anonymity
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-anonymity>
          <app-ctrl-item-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-info>
        </div>

        <div *ngIf="selectedItem.type == 'SKIP'">
          <app-ctrl-item-visibility
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-visibility>
        </div>
      </div>
    </div>

    <kendo-window
      [top]="200"
      title="Languages"
      *ngIf="languageWindowActive"
      (close)="onCloseLanguageWindow()"
    >
      <kendo-multiselect
        name="languages"
        [data]="languageQuestion.items"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [(ngModel)]="selectedLanguages"
      ></kendo-multiselect>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Import Labels"
      *ngIf="importWindowActive"
      (close)="importWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'templatelabels'"
        [extensions]="extensions"
        (onWindowClose)="onCloseImportWindow()"
      ></app-ctrl-import-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Export Labels"
      *ngIf="exportWindowActive"
      (close)="exportWindowActive = false"
    >
      <app-ctrl-export-file
        [survey]="surveyObj"
        [type]="'templatelabels'"
        (onWindowClose)="onCloseExportWindow()"
      ></app-ctrl-export-file>
    </kendo-window>
  </div>
</div>
