// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDgryMJlEGJWxn5IkIftAkoQEWDEYYHFs4",
    authDomain: "chb-manage-eu.firebaseapp.com",
    databaseURL: "https://chb-manage-eu.firebaseio.com",
    projectId: "chb-manage-eu",
    storageBucket: "chb-manage-eu.appspot.com",
    messagingSenderId: "646434434017",
  },
  chart: {
    labels: { font: "Verdana", color: "#3E3D88" },
  },
  //host : 'http://127.0.0.1:8000',
  host: "https://manage.integronresearch.com",
  surveyUrl: "https://survey.integronresearch.com",
  platform: "DEVELOPMENT",
};
