<div class="contentblock">
  <h3>Dynamic</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label"
      >Clients can create new segments via the API</label
    >
    <div class="col-sm-10">
      <kendo-switch
        name="isDynamic"
        [(ngModel)]="item.isDynamic"
        (valueChange)="onChangeDynamic()"
      ></kendo-switch>
    </div>
  </div>
</div>
