<kendo-editor [value]="model" (valueChange)="modelChanged.emit($event)">
  <kendo-toolbar>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
    <kendo-toolbar-colorpicker kendoEditorForeColor>
    </kendo-toolbar-colorpicker>
    <kendo-toolbar-dropdownlist
      kendoEditorFontSize
    ></kendo-toolbar-dropdownlist>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
      <kendo-toolbar-button
        kendoEditorAlignJustifyButton
      ></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button
        kendoEditorInsertUnorderedListButton
      ></kendo-toolbar-button>
      <kendo-toolbar-button
        kendoEditorInsertOrderedListButton
      ></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
  </kendo-toolbar>
</kendo-editor>
