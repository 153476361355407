import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-survey-metadata",
  templateUrl: "./survey-metadata.component.html",
  styleUrls: ["./survey-metadata.component.css"],
})
export class SurveyMetadataComponent implements OnInit {
  @Input() survey: any;
  @Input() columns: any;

  selected: any = {};
  baseColumns: any = [
    "id",
    "email",
    "type",
    "language",
    "systime",
    "loginname",
    "password",
  ];

  constructor(private surveysService: SurveysService) {}

  ngOnInit(): void {
    for (let column of this.columns) {
      if (this.baseColumns.indexOf(column.column) == -1)
        this.selected[column.column] = false;
    }
    this.InitializeSurveyMetaData();
  }

  InitializeSurveyMetaData() {
    this.surveysService
      .getSurveyMetaData(this.survey.id)
      .valueChanges()
      .subscribe((items) => {
        this.selected = {};

        for (let column of this.columns) {
          if (items.indexOf(column.column) > -1)
            this.selected[column.column] = true;
          else this.selected[column.column] = false;
        }
      });
  }

  isBaseColumn(column: any) {
    return this.baseColumns.indexOf(column) == -1;
  }

  doSaveMetaData() {
    let metaData = [];

    for (let key in this.selected) {
      if (this.selected[key]) {
        metaData.push(key);
      }
    }

    this.surveysService.updateSurveyMetaData(this.survey.id, metaData);
  }
}
