import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { AngularFirestore } from "@angular/fire/firestore";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

export type FirebaseSurveyInfo = any;

export interface FirebaseSurvey {
  info: FirebaseSurveyInfo;
}

@Injectable({
  providedIn: "root",
})
export class SurveysService {
  constructor(
    private firebase: AngularFireDatabase,
    private firestore: AngularFirestore,
    private httpClient: HttpClient,
  ) {}

  getSurveysList(): AngularFireList<any> {
    return this.firebase.list("/Surveys");
  }

  getSurveyInfo(survey: string): AngularFireObject<FirebaseSurvey> {
    return this.firebase.object("/Surveys/" + survey);
  }

  addSurvey(survey: string, info: FirebaseSurveyInfo) {
    let surveysInfoRef = this.firebase.list("/Surveys/" + survey);
    return surveysInfoRef.set("info", info);
  }

  updateSurvey(survey: string, info: FirebaseSurveyInfo) {
    let surveyInfoRef = this.firebase.object("/Surveys/" + survey);
    return surveyInfoRef.set(info);
  }

  getSurveyItems(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyItems/" + survey + "/");
  }

  addSurveyItem(survey: string, item: any) {
    return this.firebase.list("/SurveyItems/" + survey + "/").push(item);
  }

  updateSurveyItem(survey: string, item: any): Promise<void> {
    let surveyItem = JSON.parse(JSON.stringify(item));
    let surveyItemRef = this.firebase.list("/SurveyItems/" + survey);
    let key = surveyItem.key;
    delete surveyItem.key;
    return surveyItemRef.update(key, surveyItem);
  }

  updateSurveyItemPosition(survey: string, item: any, position): Promise<void> {
    let surveyItemRef = this.firebase.list(
      "/SurveyItems/" + survey + "/" + item,
    );
    return surveyItemRef.set("position", position);
  }

  deleteSurveyItem(survey: string, item: string): Promise<void> {
    let surveyItemRef = this.firebase.object(
      "/SurveyItems/" + survey + "/" + item,
    );
    if (surveyItemRef) return surveyItemRef.remove();
  }

  getSurveyLabels(survey: string): AngularFireObject<any> {
    return this.firebase.object("/SurveyLabels/" + survey);
  }

  updateSurveyItemLabel(
    survey: string,
    item: string,
    labels: any,
  ): Promise<void> {
    let surveyItemLabelRef = this.firebase.list("/SurveyLabels/" + survey);
    return surveyItemLabelRef.set(item, labels);
  }

  updateSurveyItemLabelForLanguage(
    survey: string,
    item: string,
    language: any,
    label: any,
  ): Promise<void> {
    let surveyItemLabelRef = this.firebase.list(
      "/SurveyLabels/" + survey + "/" + item,
    );
    return surveyItemLabelRef.set(language, label);
  }

  getSurveySegments(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveySegments/" + survey + "/");
  }

  addSurveySegmentGroup(survey: string, item: any) {
    return this.firebase.list("/SurveySegments/" + survey + "/").push(item);
  }

  updateSurveySegmentGroup(survey: string, item: any): Promise<void> {
    let surveyItem = JSON.parse(JSON.stringify(item));
    let surveyItemRef = this.firebase.list("/SurveySegments/" + survey);
    let key = surveyItem.key;
    delete surveyItem.key;
    return surveyItemRef.update(key, surveyItem);
  }

  updateSurveySegmentGroupPosition(
    survey: string,
    item: any,
    position,
  ): Promise<void> {
    let surveyItemRef = this.firebase.list(
      "/SurveySegments/" + survey + "/" + item,
    );
    return surveyItemRef.set("position", position);
  }

  deleteSurveySegmentGroup(survey: string, item: string): Promise<void> {
    let surveyItemRef = this.firebase.object(
      "/SurveySegments/" + survey + "/" + item,
    );
    if (surveyItemRef) return surveyItemRef.remove();
  }

  getSurveyRules(survey: string): AngularFireObject<any> {
    return this.firebase.object("/SurveyRespondentsRules/" + survey + "/");
  }

  updateSurveyRules(survey: string, type: string, rules: any): Promise<void> {
    let surveyRulesRef = this.firebase.object(
      "/SurveyRespondentsRules/" + survey + "/" + type + "/",
    );
    return surveyRulesRef.set(rules);
  }

  getSurveyTransformations(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyTransormations/" + survey + "/");
  }

  addSurveyTransformation(survey: string, transformation: any) {
    return this.firebase
      .list("/SurveyTransormations/" + survey + "/")
      .push(transformation);
  }

  deleteSurveyTransformation(
    survey: string,
    transformation: string,
  ): Promise<void> {
    let surveyTransformationRef = this.firebase.object(
      "/SurveyTransormations/" + survey + "/" + transformation,
    );
    if (surveyTransformationRef) return surveyTransformationRef.remove();
  }

  getSurveyResegmentations(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyResegmentation/" + survey + "/");
  }

  addSurveyResegmentation(survey: string, resegmentation: any) {
    return this.firebase
      .list("/SurveyResegmentation/" + survey + "/")
      .push(resegmentation);
  }

  deleteSurveyResegmentation(
    survey: string,
    resegmentation: string,
  ): Promise<void> {
    let surveyResegmentationRef = this.firebase.object(
      "/SurveyResegmentation/" + survey + "/" + resegmentation,
    );
    if (surveyResegmentationRef) return surveyResegmentationRef.remove();
  }

  getSurveyRespondentsStructures(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyRespondentsStructures/" + survey + "/");
  }

  updateSurveyRespondentsStructures(
    survey: string,
    columns: any,
  ): Promise<void> {
    let surveyRespondentsStructuresRef = this.firebase.list(
      "/SurveyRespondentsStructures/",
    );
    return surveyRespondentsStructuresRef.update(survey, columns);
  }

  getSurveyMailings(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyMailings/" + survey + "/");
  }

  getSurveyMailing(survey: string, mailing: string): AngularFireObject<any> {
    return this.firebase.object(
      "/SurveyMailings/" + survey + "/" + mailing + "/",
    );
  }

  addSurveyMailing(survey: string, mailing: any) {
    return this.firebase.list("/SurveyMailings/" + survey + "/").push(mailing);
  }

  updateSurveyMailing(survey: string, mailing: any): Promise<void> {
    let surveyMailingsRef = this.firebase.list("/SurveyMailings/" + survey);
    let key = mailing.key;
    delete mailing.key;
    return surveyMailingsRef.update(key, mailing);
  }

  deleteSurveyMailing(survey: string, mailing: string): Promise<void> {
    let surveyMailingRef = this.firebase.object(
      "/SurveyMailings/" + survey + "/" + mailing,
    );
    if (surveyMailingRef) return surveyMailingRef.remove();
  }

  getSurveyMailingRecipients(
    survey: any,
    mailing: string,
  ): AngularFireObject<any> {
    return this.firebase.object(
      "/SurveyRespondents/" + survey + "/dynamic/" + mailing,
    );
  }

  getSurveyResponsesTimes(survey: any): AngularFireObject<any> {
    return this.firebase.object("/SurveyResponses/" + survey);
  }

  getSurveyRespondents(survey: string): Observable<any[]> {
    return this.firestore
      .collection("SurveyRespondents_" + survey)
      .valueChanges();
  }

  updateSurveyRespondent(survey: string, respondent: any) {
    this.firestore
      .collection("SurveyRespondents_" + survey)
      .doc(respondent.id)
      .set(respondent, { merge: true });
  }

  getSurveyLoginNamesIndex(survey: string): AngularFireObject<any> {
    return this.firebase.object("/SurveyLoginNamesIndex/" + survey);
  }

  updateSurveyLoginNamesIndex(survey: string, index: number): Promise<void> {
    let surveyLoginNamesIndexRef = this.firebase.object(
      "/SurveyLoginNamesIndex/" + survey,
    );
    return surveyLoginNamesIndexRef.set({ current: index });
  }

  getSurveyLibrary(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyLibrary/" + survey + "/");
  }

  getSurveyChapters(survey: string): AngularFireList<any> {
    return this.firebase.list("/SurveyChapterMap/" + survey + "/");
  }

  addSurveyChapter(survey: string, chapter: any) {
    return this.firebase
      .list("/SurveyChapterMap/" + survey + "/")
      .push(chapter);
  }

  updateSurveyChapter(survey: string, chapter: any): Promise<void> {
    let surveyChapter = JSON.parse(JSON.stringify(chapter));
    let surveyChapterRef = this.firebase.list("/SurveyChapterMap/" + survey);
    let key = surveyChapter.key;
    delete surveyChapter.key;
    return surveyChapterRef.update(key, surveyChapter);
  }

  updateSurveyChapterPosition(
    survey: string,
    item: any,
    position,
  ): Promise<void> {
    let surveyItemRef = this.firebase.list(
      "/SurveyChapterMap/" + survey + "/" + item,
    );
    return surveyItemRef.set("position", position);
  }

  deleteSurveyChapter(survey: string, chapter: string): Promise<void> {
    let surveyChapterRef = this.firebase.object(
      "/SurveyChapterMap/" + survey + "/" + chapter,
    );
    if (surveyChapterRef) return surveyChapterRef.remove();
  }

  getSurveyMetaData(survey: string): AngularFireObject<any> {
    return this.firebase.object("/SurveyPublicMetadata/" + survey + "/");
  }

  updateSurveyMetaData(survey: string, metadata: any): Promise<void> {
    let surveyMetadataRef = this.firebase.object(
      "/SurveyPublicMetadata/" + survey,
    );
    return surveyMetadataRef.set(metadata);
  }

  getClientKeys(): AngularFireList<any> {
    return this.firebase.list("/CompanyKeys");
  }

  updateClientKey(id: string, info: any): Promise<void> {
    let clientKeyRef = this.firebase.object("/CompanyKeys/" + id);
    return clientKeyRef.set(info);
  }

  deleteClientKey(id: string): Promise<void> {
    let clientKeyRef = this.firebase.object("/CompanyKeys/" + id);
    if (clientKeyRef) return clientKeyRef.remove();
  }
}
