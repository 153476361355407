import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-additional",
  templateUrl: "./ctrl-item-additional.component.html",
  styleUrls: ["./ctrl-item-additional.component.css"],
})
export class CtrlItemAdditionalComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  constructor() {}

  ngOnChanges(): void {}

  public onChangeAdditional() {
    this.itemChanged.emit({ item: this.item });
  }
}
