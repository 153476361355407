import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { AuthService } from "../../../../services/auth.service";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-clientkeys",
  templateUrl: "./survey-clientkeys.component.html",
  styleUrls: ["./survey-clientkeys.component.css"],
})
export class SurveyClientkeysComponent implements OnInit {
  @Input() survey: any;
  @Input() mailing: any;

  initialized: boolean = false;
  clientkeys: any = [];

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.InitializeClientKeys();
  }

  InitializeClientKeys() {
    this.surveysService
      .getClientKeys()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((clientkeys) => {
        this.clientkeys = [];

        for (let clientkey of clientkeys) {
          if (
            clientkey.survey == this.survey.id &&
            clientkey.mailing == this.mailing.id
          ) {
            this.clientkeys.push(clientkey);
          }
        }

        this.initialized = true;
      });
  }

  onAddClientKey() {
    this.surveysService.updateClientKey(guid(), {
      survey: this.survey.id,
      mailing: this.mailing.id,
    });
  }

  onRemoveClientKey(dataItem) {
    if (confirm("Are you sure you want to remove this client key?") == true) {
      if (
        confirm(
          "Are you absolutly sure you want to remove this client key? You client will not be able to call the API anymore!!!",
        ) == true
      ) {
        this.surveysService.deleteClientKey(dataItem.key);
      }
    }
  }
}
