<div *ngIf="initialized">
  <kendo-grid [data]="jobs">
    <kendo-grid-column
      field="type"
      title="Job type"
      [width]="350"
    ></kendo-grid-column>
    <kendo-grid-column
      field="state"
      title="Job state"
      [width]="350"
    ></kendo-grid-column>
    <kendo-grid-column field="name" title="Survey Name" [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ GetSurveyName(dataItem) }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="name" title="User Name" [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ GetUserName(dataItem) }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="delete"
      title="Delete"
      [filterable]="false"
      [width]="50"
    >
      <ng-template kendoGridCellTemplate let-dataItem
        ><span (click)="onDeleteJob(dataItem)"
          ><fa-icon
            [icon]="['fas', 'trash']"
            style="color: #dd5a43"
          ></fa-icon></span
      ></ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
