<div class="contentblock">
  <h3>Confirms publicity (non-anonymity)</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label"
      >Confirms publicity (non-anonymity)</label
    >
    <div class="col-sm-10">
      <kendo-switch
        name="isPublic"
        [(ngModel)]="item.isPublic"
        (valueChange)="onChangeAnonymity()"
      ></kendo-switch>
    </div>
  </div>
</div>
