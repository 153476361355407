import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-ctrl-chapter-info",
  templateUrl: "./ctrl-chapter-info.component.html",
  styleUrls: ["./ctrl-chapter-info.component.css"],
})
export class CtrlChapterInfoComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  templates: any = [
    { id: "yes_no", text: "Yes / No Template" },
    { id: "image", text: "Image Template" },
    { id: "loyalty", text: "Loyalty Template" },
    { id: "loyalty4", text: "Loyalty 4 Template" },
    { id: "loyalty5", text: "Loyalty 5 Template" },
    { id: "loyalty11", text: "Loyalty 11 Template" },
    { id: "loyaltyeuro11", text: "Loyalty Euro 11 Template" },
    { id: "netpromoter", text: "NetPromoter Template" },
    { id: "agreeableness4", text: "Agreeableness 4 Template" },
    { id: "frequency", text: "Frequency Template" },
    { id: "reportnumber", text: "Reportnumber Template" },
    { id: "other", text: "Other Template" },
  ];

  displays: any = [
    { id: "TABLE", text: "Table" },
    { id: "GRAPH", text: "Graph" },
  ];

  icons: any = [
    { id: "actionpoints", text: "Action Points" },
    { id: "involvement", text: "Involvement" },
    { id: "nps", text: "Net promoter score" },
    { id: "open", text: "Open" },
    { id: "overview", text: "Overview" },
    { id: "satisfaction", text: "Satisfaction" },
    { id: "subjects", text: "Subjects" },
  ];

  constructor(private authService: AuthService) {}

  ngOnChanges(): void {}

  public onChangeInfo() {
    this.itemChanged.emit({ item: this.item });
  }
}
