import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FileRestrictions,
  SelectEvent,
  UploadEvent,
} from "@progress/kendo-angular-upload";
import { AuthService } from "../../../../services/auth.service";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-ctrl-import-file",
  templateUrl: "./ctrl-import-file.component.html",
  styleUrls: ["./ctrl-import-file.component.css"],
})
export class CtrlImportFileComponent implements OnInit {
  uploadSaveUrl: string = environment.host + "/importfile/";
  uploadSaveField: string = "importfile";

  constructor(private authService: AuthService) {}

  @Input() survey: any;
  @Input() type: any;
  @Input() extensions: any;
  @Input() link: any;
  @Input() buttons: boolean = true;
  @Input() attached: any;

  @Output() onWindowClose = new EventEmitter();

  initialized: boolean = false;
  uploadRestrictions: FileRestrictions = { allowedExtensions: [".xlsx"] };

  ngOnInit(): void {
    if (this.link)
      this.uploadSaveUrl = environment.host + "/" + this.link + "/";
    this.uploadRestrictions.allowedExtensions = this.extensions;
    this.initialized = true;
  }

  public uploadEventHandler(e: UploadEvent): void {
    e.data = {
      survey: this.survey.id,
      user: this.authService.GetCurrentUser(),
      session: this.authService.GetCurrentSession(),
      job: this.type,
      name: e.files[0].name,
      type: this.type,
    };
    if (this.attached) {
      for (let key in this.attached) {
        e.data[key] = this.attached[key];
      }
    }
  }

  public onClose() {
    this.onWindowClose.emit({});
  }
}
