<div *ngIf="initialized">
  <button (click)="onAddNewSurvey()" class="btn-actions">
    <fa-icon [icon]="['fas', 'plus']" style="color: orange"></fa-icon>
  </button>
  <button (click)="onSignOut()" class="btn-actions" style="float: right">
    <fa-icon [icon]="['fas', 'sign-out-alt']" style="color: red"></fa-icon>
  </button>
  <button (click)="onSelectClients()" class="btn-actions" style="float: right">
    <fa-icon [icon]="['fas', 'users']"></fa-icon>
  </button>
  <button (click)="onSelectJobs()" class="btn-actions" style="float: right">
    <fa-icon [icon]="['fas', 'inbox']"></fa-icon>
  </button>

  <kendo-grid
    [data]="gridData"
    [filterable]="true"
    [filter]="state.filter"
    (dataStateChange)="dataStateChange($event)"
  >
    <kendo-grid-column field="name" title="Survey Name" [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="user-name"
          ><a (click)="onSelectSurvey(dataItem)">{{ dataItem.name }}</a></span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="startdate"
      title="Start Date"
      [filterable]="false"
    ></kendo-grid-column>
    <kendo-grid-column
      field="enddate"
      title="End Date"
      [filterable]="false"
    ></kendo-grid-column>
    <kendo-grid-column
      field="monitor"
      title="Monitor"
      [filterable]="false"
    ></kendo-grid-column>
    <kendo-grid-column
      field="branche"
      title="Branche"
      [filterable]="false"
    ></kendo-grid-column>
    <kendo-grid-column
      field="update"
      title="Update"
      [filterable]="false"
      [width]="50"
    >
      <ng-template kendoGridCellTemplate let-dataItem
        ><span (click)="onSelectSurveyInfo(dataItem)"
          ><fa-icon [icon]="['fas', 'forward']"></fa-icon></span
      ></ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
