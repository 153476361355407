import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { DragEndEvent } from "@progress/kendo-angular-sortable";

@Component({
  selector: "app-ctrl-item-order",
  templateUrl: "./ctrl-item-order.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./ctrl-item-order.component.css"],
})
export class CtrlItemOrderComponent implements OnInit {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onDragEnd(e: DragEndEvent): void {
    this.itemChanged.emit({ item: this.item });
  }

  public AutoSort() {
    this.item.items.sort((a, b) => (a.text > b.text ? 1 : -1));
    this.itemChanged.emit({ item: this.item });
  }
}
