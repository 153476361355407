<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <a href="#" class="circle-purple"
          ><i class="fa fa-bell"></i><br /><span class="notification-amount"
            >42</span
          ></a
        >
      </div>
    </div>
    <div class="menu">
      <button (click)="onAddChapter()" class="btn-actions" title="Add chapter">
        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
      </button>

      <span *ngIf="selectedItem">
        <button
          (click)="onAddSubChapter()"
          class="btn-actions"
          *ngIf="selectedItem.type == 'CHAPTER'"
          title="Add subchapter"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>
      </span>

      <button
        (click)="onRemoveChapter()"
        class="btn-actions"
        title="Remove chapter"
      >
        <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
      </button>

      <button
        (click)="onGoToTemplatesList()"
        class="btn-actions"
        title="Surveys"
      >
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="surveyObj.chapters"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }}
        <span class="selection" *ngIf="selectedItem"
          >>> {{ selectedItem.text }}</span
        >
      </h1>

      <div *ngIf="selectedItem">
        <div *ngIf="selectedItem.type == 'CHAPTER'">
          <app-ctrl-chapter-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-chapter-info>
          <app-ctrl-item-order
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-item-order>
        </div>

        <div *ngIf="selectedItem.type == 'SUBCHAPTER'">
          <app-ctrl-chapter-info
            [survey]="surveyObj"
            [item]="selectedItem"
            (itemChanged)="onItemChanged($event)"
          ></app-ctrl-chapter-info>
        </div>
      </div>
    </div>
  </div>
</div>
