export class SurveyObject {
  public survey = new Map();
  public surveyItemsById: any = {};
  public surveyItemsByName: any = {};
  public surveySegmentsByName: any = {};
  public surveySegmentsById: any = {};
  public surveySegmentsParent: any = {};

  constructor() {}

  get id(): any {
    return this.survey.get("id");
  }

  set id(id: any) {
    this.survey.set("id", id);
  }

  get info(): any {
    return this.survey.get("info");
  }

  set info(info: any) {
    this.survey.set("info", info);
  }

  get items(): any {
    return this.survey.get("items");
  }

  set items(items: any) {
    this.survey.set("items", items);
    for (let item of items) {
      if (item.hasOwnProperty("isSegment")) {
        if (item["isSegment"]) {
          this.surveySegmentsById[item["id"]] = item["text"];
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              this.surveySegmentsById[subitem["id"]] = subitem["text"];
              this.surveySegmentsParent[subitem["id"]] = item["id"];
            }
          }
        }
      }
    }
  }

  get segments(): any {
    return this.survey.get("segments");
  }

  set segments(segments: any) {
    this.survey.set("segments", segments);
    for (let segment of segments) {
      this.surveySegmentsById[segment["id"]] = segment["text"];
      if (segment.hasOwnProperty("items")) {
        for (let subitem of segment.items) {
          this.surveySegmentsById[subitem["id"]] = subitem["text"];
          this.surveySegmentsParent[subitem["id"]] = segment["id"];
        }
      }
    }
  }

  get chapters(): any {
    return this.survey.get("chapters");
  }

  set chapters(chapters: any) {
    this.survey.set("chapters", chapters);
  }

  public MapSurveyItems(): void {
    this.surveyItemsByName = {};
    this.surveyItemsById = {};
    for (let item of this.items) {
      this.surveyItemsByName[item["name"]] = item["id"];
      this.surveyItemsById[item["id"]] = item["name"];
    }
  }

  public GetSurveyItemByName(name: string): any {
    if (this.surveyItemsByName.hasOwnProperty(name))
      return this.surveyItemsByName[name];
    return null;
  }

  public GetSurveySegmentById(id: string): any {
    if (this.surveySegmentsById.hasOwnProperty(id))
      return this.surveySegmentsById[id];
    return null;
  }

  public GetSurveySegmentByName(name: string): any {
    if (this.surveySegmentsByName.hasOwnProperty(name))
      return this.surveySegmentsByName[name];
    return null;
  }

  public GetSurveySegmentParent(id: string): any {
    if (this.surveySegmentsParent.hasOwnProperty(id))
      return this.surveySegmentsParent[id];
    return null;
  }

  public GetSurveySegmentSubsegments(id: string): any {
    let subsegments = [];
    for (let segment of this.segments) {
      if (segment.id == id) {
        if (segment.hasOwnProperty("items")) {
          return segment["items"];
        }
      }
    }
    return [];
  }

  getSurveyItemText(
    list: any,
    prefixparent: string,
    prefixitem: string,
  ): string {
    let prefix = "";
    let text = "";
    let existing = false;
    let position = list.length;

    if (prefixparent.length > 0) prefix = prefixparent;
    if (prefix.length > 0) prefix += "_";
    prefix += prefixitem;
    if (prefix.length > 0) prefix += "_";

    while (!existing) {
      let found = false;
      text = prefix + position;
      for (let item of list) {
        if (item.text == text) found = true;
      }
      if (found == false) existing = true;
      position++;
    }

    return text;
  }
}
