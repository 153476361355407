import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { AuthService } from "../../../../services/auth.service";
import { JobsService } from "../../../../services/jobs.service";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { SYS_LANGAUGES } from "../../../../shared/language";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-survey-recipients",
  templateUrl: "./survey-recipients.component.html",
  styleUrls: ["./survey-recipients.component.css"],
})
export class SurveyRecipientsComponent implements OnInit {
  @Input() survey: any;
  @Input() mailing: any;
  @Input() respondents: any;
  @Input() times: any;
  @Input() columns: any;

  initialized: boolean = false;
  languages: any = SYS_LANGAUGES;
  recipients: any = [];
  selected: any = null;
  type: string = "mailing";
  receiver: string = "email";
  language: string = "nl";
  email: string = "";
  respondentWindowActive: boolean = false;
  sort: SortDescriptor[] = [{ field: "loginname", dir: "asc" }];
  pageSize: number = 500;
  skip: number = 0;
  gridView: GridDataResult;
  windowTop: number = 100;
  windowLeft: number = 50;
  surveyUrl: string = environment.surveyUrl;

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    private jobsService: JobsService,
  ) {}

  ngOnInit(): void {
    this.email = this.authService.GetCurrentUserMetaData()["email"];
    this.InitializeSurveyRecipients();
  }

  InitializeSurveyRecipients() {
    this.surveysService
      .getSurveyMailingRecipients(this.survey.id, this.mailing.id)
      .valueChanges()
      .subscribe((recipients) => {
        this.recipients = [];

        for (let typeRecipients in recipients) {
          for (let respondent in recipients[typeRecipients]) {
            let recipient = null;

            if (this.respondents.hasOwnProperty(respondent)) {
              recipient = this.respondents[respondent];
              if (this.times.hasOwnProperty(respondent)) {
                recipient["firstlogin"] = this.times[respondent]["firstlogin"];
                recipient["invitation"] = this.times[respondent]["invitation"];
              }

              this.recipients.push(recipient);
            }
          }
        }

        this.loadRecipients();

        this.initialized = true;
      });
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadRecipients();
  }

  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.loadRecipients();
  }

  loadRecipients(): void {
    this.gridView = {
      data: orderBy(this.recipients, this.sort).slice(
        this.skip,
        this.skip + this.pageSize,
      ),
      total: this.recipients.length,
    };
  }

  GetDateFormat(datetime: any): string {
    if (datetime) {
      let inputdate = new Date(Number(datetime));
      return inputdate.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    return "";
  }

  onSendMailingForRecipient() {
    if (
      confirm("Are you sure you want to send a test mail to this recipient?") ==
      true
    ) {
      let jobData = {
        survey: this.survey.id,
        user: this.authService.GetCurrentUser(),
        mailing: this.mailing.id,
        preview: true,
        type: this.type,
        respondent: this.selected.id,
      };
      if (this.receiver == "email") {
        jobData["email"] = this.email;
        jobData["language"] = this.language;
      }
      this.jobsService.addJob({
        type: "send_mailing",
        state: "WAITING_FOR_PROCESS",
        data: jobData,
      });
      this.respondentWindowActive = false;
      alert(
        "In couple of minutes the system will send a test mail for this recipient.",
      );
    }
  }
}
