import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { SurveysService } from "../../../../services/surveys.service";
import { JobsService } from "../../../../services/jobs.service";
import { AuthService } from "../../../../services/auth.service";
import { SurveyObject } from "../../../../shared/survey";
import { SurveyLabelsObject } from "../../../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-mailings",
  templateUrl: "./survey-mailings.component.html",
  styleUrls: ["./survey-mailings.component.css"],
})
export class SurveyMailingsComponent implements OnInit {
  surveyObj = new SurveyObject();

  selectedItem: any = null;
  initialized: boolean = false;
  importWindowActive: boolean = false;
  templateWindowActive: boolean = false;
  showSectionType: any = "";
  extensions: any = [".xlsx"];
  respondents: any = {};
  columns: any = [];
  jobs: any = [];
  times: any = {};
  mailings: any = [];
  attached: any = {};

  constructor(
    private surveysService: SurveysService,
    private jobsService: JobsService,
    private authService: AuthService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["survey"];

    this.InitializeSurveyInfo();
    this.InitializeSurveyMailings();
    this.InitializeSurveyResponsesTimes();
    this.InitializeSurveyRespondentsStructures();
    this.InitializeSurveyRespondents();
    this.InitializeJobs();
  }

  InitializeSurveyInfo() {
    this.surveysService
      .getSurveyInfo(this.surveyObj.id)
      .valueChanges()
      .subscribe((survey) => {
        if (survey.info) this.surveyObj.info = survey.info;
      });
  }

  InitializeSurveyMailings() {
    this.surveysService
      .getSurveyMailings(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((mailings) => {
        this.mailings = mailings;

        //this.mailings.sort((a, b) => (a.position > b.position) ? 1 : -1)

        this.RefreshSelectedItem();

        this.initialized = true;
      });
  }

  InitializeSurveyRespondentsStructures() {
    this.surveysService
      .getSurveyRespondentsStructures(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((columns) => {
        if (columns) this.columns = columns;

        this.columns.sort((a, b) => (a.position > b.position ? 1 : -1));
      });
  }

  InitializeSurveyRespondents() {
    this.surveysService
      .getSurveyRespondents(this.surveyObj.id)
      .subscribe((respondents) => {
        for (let respondent of respondents) {
          this.respondents[respondent.id] = respondent;
        }
      });
  }

  InitializeSurveyResponsesTimes() {
    this.surveysService
      .getSurveyResponsesTimes(this.surveyObj.id)
      .valueChanges()
      .subscribe((times) => {
        if (times) {
          this.times = times;
        }
      });
  }

  InitializeJobs() {
    this.jobsService
      .getJobsList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((jobs) => {
        if (jobs) this.jobs = jobs;
      });
  }

  RefreshSelectedItem() {
    if (this.selectedItem) {
      for (let item of this.mailings) {
        if (item.id == this.selectedItem.id) {
          this.selectedItem = item;
        }
      }
    }
  }

  toJSONLocal(): string {
    let date = new Date();
    let local = new Date();
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    let slocal = local.toJSON();
    return slocal.slice(0, 10) + " " + slocal.slice(11, 16);
  }

  onItemSelected(item: any) {
    this.selectedItem = item.item;
    this.showSectionType = "MAILING";
    this.attached["mailing"] = this.selectedItem.id;
  }

  onAddMailing() {
    let mailing = {
      id: guid(),
      text: "M " + this.toJSONLocal(),
      type: "MAILING",
      scheduler: "MANUALLY",
      schedulertime: "",
      creator: "SYSTEM",
      usecustom: false,
      progress: 0,
      reminder: false,
      interval: 7,
      invitations: 1,
    };
    this.surveysService.addSurveyMailing(this.surveyObj.id, mailing);
  }

  onUpdateMailing(item: any) {
    this.surveysService.updateSurveyMailing(this.surveyObj.id, item.item);
  }

  onRemoveMailing() {
    if (confirm("Are you sure you want to delete this mailing?") == true) {
      this.surveysService.deleteSurveyMailing(
        this.surveyObj.id,
        this.selectedItem.key,
      );
      this.selectedItem = null;
    }
  }

  onUpdateTimes() {
    if (
      confirm(
        "Are you sure you want to update the responses times for this survey?",
      ) == true
    ) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "publish_contacts",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "update_times",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert("In couple of minutes the system will update respondents time.");
      } else {
        alert(
          "There is already a update time job in the queue for this survey",
        );
      }
    }
  }

  onViewMailing() {
    this.showSectionType = "MAILING";
  }

  onViewRecipients() {
    this.showSectionType = "RECIPIENTS";
  }

  onViewClientKeys() {
    this.showSectionType = "CLIENTKEYS";
  }

  onSendMailing() {
    if (confirm("Are you sure you are ready to send the mailing?") != true)
      return;

    if (this.selectedItem["scheduler"] == "MANUALLY") {
      if (
        this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "send_mailing",
          this.surveyObj.id,
        )
      ) {
        alert(
          "There is already an active sending mailing job in the queue for this survey",
        );
        return;
      }

      this.jobsService.addJob({
        type: "send_mailing",
        state: "WAITING_FOR_PROCESS",
        data: {
          survey: this.surveyObj.id,
          user: this.authService.GetCurrentUser(),
          mailing: this.selectedItem.id,
          type: "mailing",
        },
      });
      alert("In couple of minutes the system will update respondents time.");
    } else {
      this.selectedItem["progress"] = 0;
      this.selectedItem["status"] = "SCHEDULED";
      this.selectedItem["sent"] = false;

      this.surveysService.updateSurveyMailing(
        this.surveyObj.id,
        this.selectedItem,
      );
      alert("The mailing is scheduled and will be send on the specified time.");
    }
  }

  onCopyMailing() {
    if (this.selectedItem) {
      if (
        confirm("Are you sure you want to create a copy of this mailing?") ==
        true
      ) {
        let copymailing = {
          id: guid(),
          text: "M " + this.toJSONLocal(),
          type: "MAILING",
          scheduler: "MANUALLY",
          schedulertime: "",
          creator: "SYSTEM",
          usecustom: false,
          progress: 0,
          reminder: false,
          interval: 7,
          invitations: 1,
        };
        copymailing["composition"] = this.selectedItem["composition"];
        copymailing["usecustom"] = this.selectedItem["usecustom"];
        copymailing["reminder"] = this.selectedItem["reminder"];
        copymailing["interval"] = this.selectedItem["interval"];
        copymailing["invitations"] = this.selectedItem["invitations"];
        this.surveysService.addSurveyMailing(this.surveyObj.id, copymailing);

        if (this.selectedItem["usecustom"]) {
          this.jobsService.addJob({
            type: "copy_mailing_template",
            state: "WAITING_FOR_PROCESS",
            data: {
              survey: this.surveyObj.id,
              user: this.authService.GetCurrentUser(),
              source: this.selectedItem.id,
              target: copymailing.id,
            },
          });
        }
      }
    }
  }

  onAddTemplateItem(item: any) {
    if (
      confirm("Are you sure you want to add this template mailing?") == true
    ) {
      let templatemailing = {
        id: guid(),
        text: "M " + this.toJSONLocal(),
        type: "MAILING",
        scheduler: "MANUALLY",
        schedulertime: "",
        creator: "SYSTEM",
        usecustom: false,
        progress: 0,
        reminder: false,
        interval: 7,
        invitations: 1,
      };

      if (item.item.hasOwnProperty("info")) {
        if (item.item.info.hasOwnProperty("usecustom")) {
          templatemailing["usecustom"] = item.item.info["usecustom"];
        }
        if (item.item.info.hasOwnProperty("customcolor")) {
          templatemailing["customcolor"] = item.item.info["customcolor"];
        }
      }

      templatemailing["composition"] = item.item.composition;
      this.surveysService.addSurveyMailing(this.surveyObj.id, templatemailing);

      if (templatemailing["usecustom"]) {
        this.jobsService.addJob({
          type: "copy_mailing_coretemplate",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
            source: item.item.info.id,
            target: templatemailing.id,
          },
        });
      }
    }

    this.templateWindowActive = false;
  }

  onCloseImportWindow() {
    this.importWindowActive = false;
  }

  onGoToSurveyLabels() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/labels"]);
  }

  onGoToSurveyCompose() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/compose"]);
  }

  onGoToSurveySegmentation() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/segments"]);
  }

  onGoToSurveyDashbaord() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/dashboard"]);
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
