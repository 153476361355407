import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { SurveysService } from "../../../../services/surveys.service";
import { JobsService } from "../../../../services/jobs.service";
import { AuthService } from "../../../../services/auth.service";
import { TemplatesService } from "../../../../services/templates.service";
import { SurveyObject } from "../../../../shared/survey";
import { SurveyLabelsObject } from "../../../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";
import { QUESTION_LANGUAGE } from "../../../../shared/language";
import {
  GroupDescriptor,
  GroupResult,
  groupBy,
} from "@progress/kendo-data-query";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-survey-composition",
  templateUrl: "./survey-composition.component.html",
  styleUrls: ["./survey-composition.component.css"],
})
export class SurveyCompositionComponent implements OnInit {
  surveyObj = new SurveyObject();
  labelsObj = new SurveyLabelsObject();

  selectedItem: any = null;
  selectedItemParent: any = null;
  initialized: boolean = false;
  languageWindowActive: boolean = false;
  importWindowActive: boolean = false;
  exportWindowActive: boolean = false;
  templateWindowActive: boolean = false;
  showDependencies: boolean = false;
  showOrder: boolean = false;
  languageQuestion: any = QUESTION_LANGUAGE;
  selectedLanguages: any = [];
  jobs: any = [];
  extensions: any = [".xlsx"];

  groups: GroupDescriptor[] = [{ field: "targettext" }];
  dependenciesView: GroupResult[];

  constructor(
    private surveysService: SurveysService,
    private templatesService: TemplatesService,
    private authService: AuthService,
    private jobsService: JobsService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["survey"];

    this.InitializeSurveyInfo();
    this.InitializeSurveyItems();
    this.InitializeSurveySegments();
    this.InitializeSurveyLabels();
    this.InitializeJobs();
  }

  InitializeSurveyInfo() {
    this.surveysService
      .getSurveyInfo(this.surveyObj.id)
      .valueChanges()
      .subscribe((survey) => {
        if (survey.info) this.surveyObj.info = survey.info;
      });
  }

  InitializeSurveyItems() {
    this.surveysService
      .getSurveyItems(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((items) => {
        this.surveyObj.items = items;

        this.surveyObj.MapSurveyItems();

        this.surveyObj.items.sort((a, b) => (a.position > b.position ? 1 : -1));

        this.RefreshSelectedItem();

        this.InitializeSurveyItemsDependencies();

        this.initialized = true;
      });
  }

  InitializeSurveySegments() {
    this.surveysService
      .getSurveySegments(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((segments) => {
        this.surveyObj.segments = segments;

        this.surveyObj.segments.sort((a, b) =>
          a.position > b.position ? 1 : -1,
        );
      });
  }

  InitializeSurveyLabels() {
    this.surveysService
      .getSurveyLabels(this.surveyObj.id)
      .valueChanges()
      .subscribe((labels) => {
        if (labels) {
          for (let key in labels) {
            this.labelsObj.setItemLabel(key, labels[key]);
          }
        }
      });
  }

  InitializeJobs() {
    this.jobsService
      .getJobsList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((jobs) => {
        if (jobs) this.jobs = jobs;
      });
  }

  InitializeSurveyItemsDependencies() {
    let dependencies = [];
    for (let item of this.surveyObj.items) {
      if (item.hasOwnProperty("relationships")) {
        for (let relationship of item["relationships"]) {
          let operator = "If";
          if (relationship != item["relationships"][0])
            operator = relationship["operator"];
          dependencies.push({
            operator: operator,
            relationship: relationship,
            target: item,
            targettext: item.text + " " + item.visibility,
          });
        }
      }
      if (item.hasOwnProperty("items")) {
        for (let subitem of item.items) {
          if (subitem.hasOwnProperty("relationships")) {
            for (let relationship of subitem["relationships"]) {
              let operator = "If";
              if (relationship != subitem["relationships"][0])
                operator = relationship["operator"];

              dependencies.push({
                operator: operator,
                relationship: relationship,
                target: item,
                targettext: subitem.text + " " + subitem.visibility,
              });
            }
          }
        }
      }
    }
    this.dependenciesView = groupBy(dependencies, this.groups);
  }

  RefreshSelectedItem() {
    if (this.selectedItemParent) {
      for (let item of this.surveyObj.items) {
        if (item.id == this.selectedItemParent.id) {
          let selectedItemId = null;
          if (this.selectedItem) {
            selectedItemId = this.selectedItem.id;
          }
          this.selectedItemParent = item;
          this.selectedItem = item;
          if (selectedItemId && item.hasOwnProperty("items")) {
            for (let subitem of item["items"]) {
              if (subitem.id == selectedItemId) {
                this.selectedItem = subitem;
              }
            }
          }
        }
      }
    }
  }

  onAddSuccessFactor() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "SF"),
      type: "SUCCESSFACTOR",
      style: "",
      required: { E: false, I: false, P: false, C: false },
      requested: { E: false, I: false, P: false, C: false },
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddQuestion() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "QUES"),
      type: "QUESTION",
      style: "VERTICAL",
      form: "SINGLECHOICE",
      isTip: false,
      isAnonymity: false,
      isSegment: false,
      required: false,
      requested: false,
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddLanguageQuestion() {
    let item = {};

    for (let key in this.languageQuestion) {
      if (key != "items") item[key] = this.languageQuestion[key];
    }

    if (this.selectedLanguages.length > 0) {
      item["items"] = [];
      for (let language of this.languageQuestion["items"]) {
        if (this.selectedLanguages.indexOf(language["id"]) > -1) {
          item["items"].push(language);
        }
      }

      item["position"] = this.surveyObj.items.length;
      this.surveysService.addSurveyItem(this.surveyObj.id, item);
    }
  }

  onAddMarker() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "MARKER",
      ),
      type: "MARKER",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddMailMarker() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "MAILMARKER",
      ),
      type: "MAILMARKER",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddText() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "TEXT"),
      type: "TEXT",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddPageBreak() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "PAGEBREAK",
      ),
      type: "PAGEBREAK",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddMatrix() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "MATRIX",
      ),
      type: "MATRIX",
      style: "MATRIX",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddSkip() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "SKIP"),
      type: "SKIP",
      style: "",
      required: false,
      requested: false,
      target: null,
      position: this.surveyObj.items.length,
    };
    this.surveysService.addSurveyItem(this.surveyObj.id, item);
  }

  onAddIndicator() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "IND",
      ),
      type: "INDICATOR",
      style: "",
      required: { E: false, I: false, P: false, C: false },
      requested: { E: false, I: false, P: false, C: false },
      visibility: true,
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.surveysService.updateSurveyItem(this.surveyObj.id, this.selectedItem);
  }

  addQuestionToMatrix() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "Q",
      ),
      type: "QUESTION",
      form: "SINGLECHOICE",
      style: "",
      required: false,
      requested: false,
      visibility: true,
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.surveysService.updateSurveyItem(this.surveyObj.id, this.selectedItem);
  }

  onAddSubindicator() {
    if (!this.selectedItem.subindicators) {
      this.selectedItem["subindicators"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.subindicators,
        this.selectedItem.text,
        "SUBINDICATOR",
      ),
      type: "SUBINDICATOR",
      style: "",
      scale: 0,
      required: false,
      requested: false,
      position: this.selectedItem.subindicators.length,
    };
    this.selectedItem["subindicators"].push(item);
    this.surveysService.updateSurveyItem(this.surveyObj.id, this.selectedItem);
  }

  addAnswer() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "ANS",
      ),
      type: "ANSWER",
      style: "",
      required: false,
      requested: false,
      visibility: true,
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.surveysService.updateSurveyItem(this.surveyObj.id, this.selectedItem);
  }

  onCloneItem() {
    if (confirm("Are you sure you want to clone this item?") == true) {
      if (this.selectedItem) {
        let columns = [
          "key",
          "id",
          "text",
          "position",
          "items",
          "subindicators",
        ];
        let clonedItem = { id: guid(), position: this.surveyObj.items.length };

        for (let key in this.selectedItem) {
          if (columns.indexOf(key) == -1) {
            clonedItem[key] = this.selectedItem[key];
          }

          if (key == "text") {
            let prefix = "";
            if (this.selectedItem.type == "TEXT") prefix = "TEXT";
            if (this.selectedItem.type == "PAGEBREAK") prefix = "PAGEBREAK";
            if (this.selectedItem.type == "QUESTION") prefix = "QUES";
            if (this.selectedItem.type == "MATRIX") prefix = "MATRIX";
            if (this.selectedItem.type == "SUCCESSFACTOR") prefix = "SF";
            if (this.selectedItem.type == "SKIP") prefix = "SKIP";
            clonedItem[key] = this.surveyObj.getSurveyItemText(
              this.surveyObj.items,
              "",
              prefix,
            );
          }
        }

        for (let key of ["items", "subindicators"]) {
          if (this.selectedItem[key]) {
            let subcolumns = ["id", "text", "position", "parent"];

            clonedItem[key] = [];

            for (let subItem of this.selectedItem[key]) {
              let clonedSubItem = {
                id: guid(),
                position: clonedItem[key].length,
                parent: clonedItem.id,
              };

              for (let subkey in subItem) {
                if (subcolumns.indexOf(subkey) == -1)
                  clonedSubItem[subkey] = subItem[subkey];
                if (subkey == "text") {
                  let subprefix = "";

                  if (key == "items") {
                    if (subItem.type == "INDICATOR") subprefix = "IND";
                    if (subItem.type == "ANSWER") subprefix = "ANS";
                    if (subItem.type == "QUESTION") subprefix = "Q";

                    clonedSubItem[subkey] = this.surveyObj.getSurveyItemText(
                      clonedItem[key],
                      clonedItem["text"],
                      subprefix,
                    );
                  }

                  if (key == "subindicators") {
                    clonedSubItem[subkey] = this.surveyObj.getSurveyItemText(
                      clonedItem[key],
                      clonedItem["text"],
                      "SUBINDICATOR",
                    );
                  }
                }
              }

              clonedItem[key].push(clonedSubItem);
            }
          }
        }

        this.surveysService.addSurveyItem(this.surveyObj.id, clonedItem);
      }
    }
  }

  onAddTemplateItem(item: any) {
    let newIds = [];
    let valid = true;

    for (let key in item.item.items) {
      newIds.push(item.item.items[key]["id"]);
    }

    for (let id of newIds) {
      if (this.surveyObj.surveyItemsById.hasOwnProperty(id)) valid = false;
    }

    if (!valid) {
      alert(
        "You already added one or more items from this template to the this survey. Delete them first (to avoid duplicates) then try again.",
      );
    } else {
      if (
        confirm("Are you sure you want to add this template to the survey?") ==
        true
      ) {
        let position = this.surveyObj.items.length + 1;

        for (let key in item.item.items) {
          let surveyItem = item.item.items[key];

          surveyItem.position = position;

          this.surveysService.addSurveyItem(this.surveyObj.id, surveyItem);

          position = position + 1;
        }

        this.templatesService
          .getTemplateLabels(item.item.info.id)
          .valueChanges()
          .subscribe((labels) => {
            if (labels) {
              for (let key in labels) {
                this.surveysService.updateSurveyItemLabel(
                  this.surveyObj.id,
                  key,
                  labels[key],
                );
              }
            }
          });
      }
    }

    this.templateWindowActive = false;
  }

  onRemoveItem() {
    if (confirm("Are you sure you want to delete this item?") == true) {
      let removeIds = [];
      let removedId = this.selectedItem.id;

      removeIds.push(removedId);

      if (this.selectedItem.parent) {
        for (let surveyItem of this.surveyObj.items) {
          if (surveyItem.id == this.selectedItem.parent) {
            let removedPos = surveyItem.items.indexOf(this.selectedItem);
            surveyItem.items.splice(removedPos, 1);

            this.surveysService
              .updateSurveyItem(this.surveyObj.id, surveyItem)
              .then((res) => {
                this.CheckAndRemoveRelation(removeIds);
              });

            this.selectedItem = null;
            for (let pos in [removedPos, removedPos - 1, 0]) {
              if (surveyItem.items[pos]) {
                if (surveyItem.items[pos].id != removedId) {
                  this.selectedItem = surveyItem.items[pos];
                  break;
                }
              }
            }
          }
        }
      } else {
        let removedPos = this.surveyObj.items.indexOf(this.selectedItem);

        if (this.selectedItem.items) {
          for (let subitem of this.selectedItem.items) {
            removeIds.push(subitem.id);
          }
        }

        this.surveysService
          .deleteSurveyItem(this.surveyObj.id, this.selectedItem.key)
          .then((res) => {
            if (this.surveyObj.items.length > 0) {
              if (this.surveyObj.items[removedPos])
                this.selectedItem = this.surveyObj.items[removedPos];
              else
                this.selectedItem =
                  this.surveyObj.items[this.surveyObj.items.length - 1];
            } else {
              this.selectedItem = null;
            }
            this.CheckAndRemoveRelation(removeIds);
          });
      }
    }
  }

  onItemSelected(item: any) {
    this.showDependencies = false;
    this.showOrder = false;

    this.selectedItem = item.item;

    if (item.item.hasOwnProperty("parent")) {
      for (let surveyItem of this.surveyObj.items) {
        if (surveyItem.id == item.item.parent) {
          this.selectedItemParent = surveyItem;
        }
      }
    } else {
      this.selectedItemParent = item.item;
    }
  }

  ParseItemId(itemid: string): string {
    if (itemid == "RESPONDENT") {
      return "Respondent";
    } else {
      for (let item of this.surveyObj.items) {
        if (item.id == itemid) {
          return item.text;
        } else {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == itemid) {
                return subitem.text;
              }
            }
          }
        }
      }
    }
  }

  ParseSubItemId(itemid: string, subitemid: string): string {
    if (itemid == "RESPONDENT") {
      for (let segment of this.surveyObj.segments) {
        if (segment.hasOwnProperty("items")) {
          for (let subitem of segment.items) {
            if (subitem.id == subitemid) {
              return subitem.text;
            }
          }
        }
      }
    } else {
      for (let item of this.surveyObj.items) {
        if (item.id == itemid) {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == subitemid) {
                return subitem.text;
              }
            }
          }
        }
        if (item.type == "SUCCESSFACTOR" || item.type == "MATRIX") {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == itemid) {
                if (subitem.type == "INDICATOR")
                  return subitemid.substr(33, subitem.text.length);
                if (subitem.type == "QUESTION") {
                  for (let subindicator of item.subindicators) {
                    if (subitemid.indexOf(subindicator.id) > -1)
                      return (
                        subindicator.text +
                        subitemid.substr(66, subitem.text.length)
                      );
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  CheckAndRemoveRelation(removeIds: any) {
    for (let surveyItem of this.surveyObj.items) {
      let shoulduUdateSurveyItem = this.DeleteRelationshipForItem(
        surveyItem,
        removeIds,
      );

      if (surveyItem.hasOwnProperty("items")) {
        for (let surveySubItem of surveyItem.items) {
          if (this.DeleteRelationshipForItem(surveySubItem, removeIds)) {
            shoulduUdateSurveyItem = true;
          }
        }
      }

      if (shoulduUdateSurveyItem) {
        this.surveysService.updateSurveyItem(this.surveyObj.id, surveyItem);
      }
    }
  }

  DeleteRelationshipForItem(surveyItem: any, removeIds: any): boolean {
    let relationshipsToDelete = [];
    let shoulduUdateSurveyItem = false;

    if (surveyItem.hasOwnProperty("relationships")) {
      for (let relationship of surveyItem["relationships"]) {
        if (
          removeIds.indexOf(relationship.item) > -1 ||
          removeIds.indexOf(relationship.subitem) > -1
        ) {
          relationshipsToDelete.push(relationship);
          shoulduUdateSurveyItem = true;
        }
      }
      for (let relationship of relationshipsToDelete) {
        let index = surveyItem["relationships"].indexOf(relationship);
        if (index > -1) {
          surveyItem["relationships"].splice(index, 1);
        }
      }
    }

    return shoulduUdateSurveyItem;
  }

  onDeleteRelationship(relationship: any) {
    let index = -1;

    if (relationship.target.hasOwnProperty("relationships"))
      index = relationship.target.relationships.indexOf(
        relationship.relationship,
      );

    if (index > -1) {
      relationship.target.relationships.splice(index, 1);
    } else {
      if (relationship.target.hasOwnProperty("items")) {
        for (let subitem of relationship.target.items) {
          if (subitem.hasOwnProperty("relationships")) {
            let index = subitem.relationships.indexOf(
              relationship.relationship,
            );
            if (index > -1) subitem.relationships.splice(index, 1);
          }
        }
      }
    }
    this.surveysService.updateSurveyItem(
      this.surveyObj.id,
      relationship.target,
    );
  }

  onItemChanged(item: any) {
    this.surveysService.updateSurveyItem(
      this.surveyObj.id,
      this.selectedItemParent,
    );
  }

  onItemMoved(move: any) {
    let position = null;
    for (let item of this.surveyObj.items) {
      if (item != this.selectedItemParent) {
        if (item == move.item) {
          position = item.position;
          this.surveysService.updateSurveyItemPosition(
            this.surveyObj.id,
            this.selectedItemParent.key,
            item.position,
          );
          position = position + 1;
        }
        if (position) {
          this.surveysService.updateSurveyItemPosition(
            this.surveyObj.id,
            item.key,
            position,
          );
          position = position + 1;
        }
      }
    }
  }

  onOrderChanged(event: any) {
    let position = 0;
    for (let item of this.surveyObj.items) {
      this.surveysService.updateSurveyItemPosition(
        this.surveyObj.id,
        item.key,
        position,
      );
      position = position + 1;
    }
  }

  onOpenLanguageWindow() {
    this.languageWindowActive = true;
  }

  onCloseLanguageWindow() {
    this.languageWindowActive = false;
    this.onAddLanguageQuestion();
  }

  onCloseImportWindow() {
    this.importWindowActive = false;
  }

  onCloseExportWindow() {
    this.exportWindowActive = false;
  }

  onGoToSurveyLabels() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/labels"]);
  }

  onGoToSurveySegmentation() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/segments"]);
  }

  onGoToSurveyMailing() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/mailings"]);
  }

  onGoToSurveyDashbaord() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/dashboard"]);
  }

  onViewDependencies() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showOrder = false;
    this.showDependencies = true;
  }

  onViewOrder() {
    this.selectedItem = null;
    this.selectedItemParent = null;
    this.showOrder = true;
    this.showDependencies = false;
  }

  onPublishSurvey() {
    if (confirm("Are you sure you want to publish the survey?") == true) {
      if (
        !this.jobsService.IsJobTypeInQueueForSurvey(
          this.jobs,
          "publish_survey",
          this.surveyObj.id,
        )
      ) {
        this.jobsService.addJob({
          type: "publish_survey",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.surveyObj.id,
            user: this.authService.GetCurrentUser(),
          },
        });
        alert("In couple of minutes the system will publish the survey.");
      } else {
        alert(
          "here is already a publish survey job in the queue for this survey",
        );
      }
    }
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }

  onViewSurvey() {
    let link =
      environment.surveyUrl +
      "/#/preview/questionnaire/" +
      this.surveyObj.id +
      "/language/nl";
    window.open(link);
  }
}
