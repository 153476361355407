<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <button
        (click)="onGoToTemplatesList()"
        class="btn-actions"
        title="Survey list"
      >
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="surveyObj.items"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>

  <div class="column-main">
    <div class="maincontent">
      <div class="contentblock">
        <h3>Templates</h3>

        <div class="form-group row">
          <label for="usecustom" class="col-sm-2 col-form-label"
            >Use custom template</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="usecustom"
              [(ngModel)]="usecustom"
              (valueChange)="onUseCustomTemplateChange($event)"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row" *ngIf="usecustom">
          <label for="template" class="col-sm-2 col-form-label">Template</label>
          <div class="col-sm-10">
            <app-ctrl-import-file
              [survey]="surveyObj"
              [type]="'templates'"
              [extensions]="extensions"
              [link]="'importtemplate'"
              [buttons]="importbuttons"
              [attached]="attached"
            ></app-ctrl-import-file>
          </div>
        </div>

        <div class="form-group row" *ngIf="usecustom">
          <label for="color" class="col-sm-2 col-form-label">Color</label>
          <div class="col-sm-10">
            <kendo-colorpicker
              [(value)]="color"
              [format]="format"
              [gradientSettings]="settings"
              (valueChange)="onChangeColor($event)"
              (close)="onPickColor($event)"
            ></kendo-colorpicker>
          </div>
        </div>
      </div>

      <div class="contentblock">
        <h3>{{ surveyObj.info.name }}</h3>

        <div class="form-group row">
          <label for="language" class="col-sm-2 col-form-label">Language</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="languages"
              [data]="languages"
              [textField]="'name'"
              [valueField]="'code'"
              [valuePrimitive]="true"
              [(ngModel)]="language"
              (valueChange)="onSelectLanguage($event)"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <img
                  src="assets/images/flags/{{ dataItem.code }}.png"
                  border="0"
                />&nbsp;{{ dataItem?.name }}
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <img
                  src="assets/images/flags/{{ dataItem.code }}.png"
                  border="0"
                />&nbsp;{{ dataItem?.name }}
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="replytoname" class="col-sm-2 col-form-label"
            >Reply to name</label
          >
          <div class="col-sm-10">
            <input
              name="replytoname"
              [(ngModel)]="composition[language].replytoname"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="replytoemail" class="col-sm-2 col-form-label"
            >Reply to email</label
          >
          <div class="col-sm-10">
            <input
              name="replytoemail"
              [(ngModel)]="composition[language].replytoemail"
            />
          </div>
        </div>

        <kendo-tabstrip>
          <kendo-tabstrip-tab
            [title]="'Initial mailing texts'"
            [selected]="true"
          >
            <ng-template kendoTabContent>
              <div class="form-group row">
                <label for="subject" class="col-sm-2 col-form-label"
                  >Mail subject</label
                >
                <div class="col-sm-10">
                  <input
                    name="subject"
                    [(ngModel)]="composition[language].subject"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="invitation" class="col-sm-2 col-form-label"
                  >Invitation text</label
                >
                <div class="col-sm-10">
                  <input
                    name="invitation"
                    [(ngModel)]="composition[language].invitation"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="mailhtml" class="col-sm-2 col-form-label"
                  >Mail HTML</label
                >
                <div class="col-sm-10">
                  <kendo-editor
                    [(ngModel)]="composition[language].mailhtml"
                    style="height: 200px"
                  >
                    <kendo-toolbar>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorBoldButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorItalicButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnderlineButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-dropdownlist
                        kendoEditorFormat
                      ></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorAlignLeftButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignCenterButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignRightButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignJustifyButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorInsertUnorderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorInsertOrderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorIndentButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorOutdentButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorCreateLinkButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnlinkButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button
                        kendoEditorInsertImageButton
                      ></kendo-toolbar-button>
                      <kendo-toolbar-button
                        kendoEditorViewSourceButton
                      ></kendo-toolbar-button>
                    </kendo-toolbar>
                  </kendo-editor>
                </div>
              </div>

              <div class="form-group row">
                <label for="mailtext" class="col-sm-2 col-form-label"
                  >Mail TEXT</label
                >
                <div class="col-sm-10">
                  <textarea
                    [(ngModel)]="composition[language].mailtext"
                    style="height: 200px"
                  ></textarea>
                </div>
              </div>

              <div class="form-group row">
                <label for="footerhtml" class="col-sm-2 col-form-label"
                  >Footer HTML</label
                >
                <div class="col-sm-10">
                  <kendo-editor
                    [(ngModel)]="composition[language].footerhtml"
                    style="height: 200px"
                  >
                    <kendo-toolbar>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorBoldButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorItalicButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnderlineButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-dropdownlist
                        kendoEditorFormat
                      ></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorAlignLeftButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignCenterButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignRightButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignJustifyButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorInsertUnorderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorInsertOrderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorIndentButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorOutdentButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorCreateLinkButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnlinkButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button
                        kendoEditorInsertImageButton
                      ></kendo-toolbar-button>
                      <kendo-toolbar-button
                        kendoEditorViewSourceButton
                      ></kendo-toolbar-button>
                    </kendo-toolbar>
                  </kendo-editor>
                </div>
              </div>

              <div class="form-group row">
                <label for="footertext" class="col-sm-2 col-form-label"
                  >Footer TEXT</label
                >
                <div class="col-sm-10">
                  <textarea
                    [(ngModel)]="composition[language].footertext"
                    style="height: 200px"
                  ></textarea>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab [title]="'Reminder mailing texts'">
            <ng-template kendoTabContent>
              <div class="form-group row">
                <label for="remindersubject" class="col-sm-2 col-form-label"
                  >Reminder Mail subject</label
                >
                <div class="col-sm-10">
                  <input
                    name="subject"
                    [(ngModel)]="composition[language].remindersubject"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="reminderinvitation" class="col-sm-2 col-form-label"
                  >Reminder Invitation text</label
                >
                <div class="col-sm-10">
                  <input
                    name="invitation"
                    [(ngModel)]="composition[language].reminderinvitation"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="remindermailhtml" class="col-sm-2 col-form-label"
                  >Reminder Mail HTML</label
                >
                <div class="col-sm-10">
                  <kendo-editor
                    [(ngModel)]="composition[language].remindermailhtml"
                    style="height: 200px"
                  >
                    <kendo-toolbar>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorBoldButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorItalicButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnderlineButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-dropdownlist
                        kendoEditorFormat
                      ></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorAlignLeftButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignCenterButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignRightButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignJustifyButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorInsertUnorderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorInsertOrderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorIndentButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorOutdentButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorCreateLinkButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnlinkButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button
                        kendoEditorInsertImageButton
                      ></kendo-toolbar-button>
                      <kendo-toolbar-button
                        kendoEditorViewSourceButton
                      ></kendo-toolbar-button>
                    </kendo-toolbar>
                  </kendo-editor>
                </div>
              </div>

              <div class="form-group row">
                <label for="remindermailtext" class="col-sm-2 col-form-label"
                  >Reminder Mail TEXT</label
                >
                <div class="col-sm-10">
                  <textarea
                    [(ngModel)]="composition[language].remindermailtext"
                    style="height: 200px"
                  ></textarea>
                </div>
              </div>

              <div class="form-group row">
                <label for="reminderfooterhtml" class="col-sm-2 col-form-label"
                  >Reminder Footer HTML</label
                >
                <div class="col-sm-10">
                  <kendo-editor
                    [(ngModel)]="composition[language].reminderfooterhtml"
                    style="height: 200px"
                  >
                    <kendo-toolbar>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorBoldButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorItalicButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnderlineButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-dropdownlist
                        kendoEditorFormat
                      ></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorAlignLeftButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignCenterButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignRightButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorAlignJustifyButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorInsertUnorderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorInsertOrderedListButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorIndentButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorOutdentButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorCreateLinkButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorUnlinkButton
                        ></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button
                        kendoEditorInsertImageButton
                      ></kendo-toolbar-button>
                      <kendo-toolbar-button
                        kendoEditorViewSourceButton
                      ></kendo-toolbar-button>
                    </kendo-toolbar>
                  </kendo-editor>
                </div>
              </div>

              <div class="form-group row">
                <label for="reminderfootertext" class="col-sm-2 col-form-label"
                  >Reminder Footer TEXT</label
                >
                <div class="col-sm-10">
                  <textarea
                    [(ngModel)]="composition[language].reminderfootertext"
                    style="height: 200px"
                  ></textarea>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>

        <button type="button" (click)="doUpdateTemplate()">
          Save template for language {{ language }}</button
        ><br /><br /><br />
      </div>
    </div>
  </div>
</div>
