<div class="contentblock">
  <h3>Info</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Name</label>
    <div class="col-sm-10">
      <input name="text" [(ngModel)]="item.text" (mouseout)="onChangeInfo()" />
    </div>
  </div>

  <div *ngIf="item.type == 'MATRIX'">
    <span *ngFor="let subindicator of item.subindicators">
      <div class="form-group row">
        <label for="label" class="col-sm-2 col-form-label">Subindicator</label>
        <div class="col-sm-10">
          <input
            name="text"
            [(ngModel)]="subindicator.text"
            (mouseout)="onChangeInfo()"
          />&nbsp;&nbsp;<fa-icon
            [icon]="['fas', 'times']"
            (click)="onRemoveSubindicator(subindicator)"
          ></fa-icon>
        </div>
      </div>
      <div class="form-group row">
        <label for="label" class="col-sm-2 col-form-label">Scale</label>
        <div class="col-sm-10">
          <input
            type="number"
            [(ngModel)]="subindicator.scale"
            (mouseout)="onChangeInfo()"
          />
        </div>
      </div>
    </span>
  </div>
</div>
