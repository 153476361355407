<div *ngIf="initialized">
  <a class="circle-purple" (click)="doShowNotificationWindow()">
    <fa-icon [icon]="['fas', 'bell']"></fa-icon><br /><span
      class="notification-amount"
      *ngIf="GetUnreadNotifications() > 0"
      ><b>{{ GetUnreadNotifications() }}</b></span
    >
  </a>

  <div *ngIf="showNotificationWindow" class="notifications-overlay">
    <div *ngIf="selected">
      <div class="notifications-header">
        <fa-icon
          [icon]="['fas', 'backward']"
          (click)="onBackToNotificationWindow()"
          class="icon-left"
        ></fa-icon>

        <fa-icon
          [icon]="['fas', 'trash']"
          (click)="onDeleteNotification()"
          style="color: #dd5a43"
          class="icon-left"
          >Remove</fa-icon
        >

        <span class="title"> {{ selected.title }} </span>
      </div>

      <div class="notifications-subheader">
        <fa-icon [icon]="['fas', 'clock']" class="icon-left"></fa-icon>
        <span>&nbsp;{{ selected.systime }}</span>
      </div>

      <div class="notifications-container">
        <div [innerHTML]="selected.text"></div>
      </div>
    </div>

    <div *ngIf="!selected">
      <div class="notifications-header">
        <fa-icon
          [icon]="['fas', 'times']"
          (click)="onCloseNotificationWindow()"
          class="icon-left"
        ></fa-icon>

        <fa-icon
          [icon]="['fas', 'trash']"
          (click)="onDeleteAllNotification()"
          style="color: #dd5a43"
          class="icon-left"
        ></fa-icon>

        <span class="title"> Inbox </span>
      </div>
      <kendo-grid [data]="notifications">
        <kendo-grid-column field="title" title="Message">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              ><a (click)="onSelectNotification(dataItem)">{{
                dataItem.title
              }}</a></span
            >
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="survey" title="Survey">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.status == 'READ'"
              ><a href="/survey/{{ dataItem.survey }}/compose">{{
                GetSurveyName(dataItem.survey)
              }}</a></span
            >
            <span *ngIf="dataItem.status == 'UNREAD'"
              ><a href="/survey/{{ dataItem.survey }}/compose"
                ><b>{{ GetSurveyName(dataItem.survey) }}</b></a
              ></span
            >
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="systime" title="Systime"></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
