import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { JobsService } from "../../../../services/jobs.service";
import { SYS_LANGAUGES } from "../../../../shared/language";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-api",
  templateUrl: "./survey-api.component.html",
  styleUrls: ["./survey-api.component.css"],
})
export class SurveyApiComponent implements OnInit {
  @Input() survey: any;
  @Input() columns: any;

  initialized: boolean = false;
  mailings: any = {};
  displaymailings: boolean = true;

  constructor(
    private surveysService: SurveysService,
    private jobsService: JobsService,
  ) {}

  ngOnInit(): void {
    this.InitializeSurveyMailings();
  }

  InitializeSurveyMailings() {
    this.surveysService
      .getSurveyMailings(this.survey.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((mailings) => {
        if (mailings) this.mailings = mailings;

        this.initialized = true;
      });
  }

  onAddInvitation(item: any) {
    let invitation = item.item;
    let mailing = item.mailing;

    invitation["id"] = guid();

    if (invitation.email.length > 0 && mailing.length > 0) {
      if (
        confirm(
          "Are you sure you want to invite this person? This testing functionality will still send an invitation mail",
        ) == true
      )
        this.jobsService.addJob({
          type: "invite_mailing_contact",
          state: "WAITING_FOR_PROCESS",
          data: {
            survey: this.survey.id,
            user: "SYSTEM",
            respondent: invitation,
            mailing: mailing,
          },
        });
    } else {
      alert("Please provide a valid email address and a valid mailing!");
    }
  }
}
