<div *ngIf="initialized">
  <button (click)="onAddNewClient()" class="btn-actions">
    <fa-icon [icon]="['fas', 'plus']" style="color: orange"></fa-icon>
  </button>
  <button (click)="onSignOut()" class="btn-actions" style="float: right">
    <fa-icon [icon]="['fas', 'sign-out-alt']" style="color: red"></fa-icon>
  </button>

  <kendo-grid
    [data]="gridData"
    [filterable]="true"
    [filter]="state.filter"
    (dataStateChange)="dataStateChange($event)"
  >
    <kendo-grid-column
      field="email"
      title="User Email"
      [width]="350"
    ></kendo-grid-column>
    <kendo-grid-column field="name" title="User Name" [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="user-name"
          ><a (click)="onSelectClient(dataItem)">{{ dataItem.name }}</a></span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="update"
      title="Update"
      [filterable]="false"
      [width]="50"
    >
      <ng-template kendoGridCellTemplate let-dataItem
        ><span (click)="onSelectClient(dataItem)"
          ><fa-icon [icon]="['fas', 'forward']"></fa-icon></span
      ></ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="delete"
      title="Delete"
      [filterable]="false"
      [width]="50"
    >
      <ng-template kendoGridCellTemplate let-dataItem
        ><span (click)="onRemoveClient(dataItem)"
          ><fa-icon
            [icon]="['fas', 'trash']"
            style="color: #dd5a43"
          ></fa-icon></span
      ></ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
