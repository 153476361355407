<div class="contentblock">
  <h3>Required</h3>

  <div *ngIf="item.type == 'SUCCESSFACTOR'">
    <div
      class="form-group row"
      *ngFor="let subindicator of ['E', 'I', 'P', 'C']"
    >
      <label for="label" class="col-sm-2 col-form-label">{{
        subindicator
      }}</label>
      <div class="col-sm-10">
        <kendo-switch
          name="required"
          [(ngModel)]="item.required[subindicator]"
          (valueChange)="onChangeSuccessfactor(subindicator)"
        ></kendo-switch>
      </div>
    </div>
  </div>

  <div *ngIf="item.type == 'INDICATOR'">
    <div
      class="form-group row"
      *ngFor="let subindicator of ['E', 'I', 'P', 'C']"
    >
      <label for="label" class="col-sm-2 col-form-label">{{
        subindicator
      }}</label>
      <div class="col-sm-10">
        <kendo-switch
          name="required"
          [(ngModel)]="item.required[subindicator]"
          (valueChange)="onChangeRequired()"
        ></kendo-switch>
      </div>
    </div>
  </div>

  <div *ngIf="item.type == 'QUESTION'">
    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Required</label>
      <div class="col-sm-10">
        <kendo-switch
          name="required"
          [(ngModel)]="item.required"
          (valueChange)="onChangeRequired()"
        ></kendo-switch>
      </div>
    </div>
  </div>
</div>
