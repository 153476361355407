import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-navigation-node",
  templateUrl: "./ctrl-navigation-node.component.html",
  styleUrls: ["./ctrl-navigation-node.component.css"],
})
export class CtrlNavigationNodeComponent implements OnInit {
  @Input() list: any;
  @Output() itemSelected = new EventEmitter();

  collapsedById = {};

  constructor() {}

  ngOnInit(): void {
    for (let item of this.list) {
      this.collapsedById[item.id] = true;
    }
  }

  isCollapsed(item: any): boolean {
    if (this.collapsedById.hasOwnProperty(item.id)) {
      return this.collapsedById[item.id];
    }
    return false;
  }

  onSelectItem(item: any): void {
    this.itemSelected.emit({ item: item });
  }

  onCollapse(item: any): void {
    this.collapsedById[item.id] = !this.collapsedById[item.id];
  }
}
