import { Component, OnInit, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { map } from "rxjs/operators";
import { guid } from "../../../../shared/utils";

@Component({
  selector: "app-survey-resegmentations",
  templateUrl: "./survey-resegmentations.component.html",
  styleUrls: ["./survey-resegmentations.component.css"],
})
export class SurveyResegmentationsComponent implements OnInit {
  @Input() survey: any;

  initialized: boolean = false;
  surveySegments: any = [];
  surveySegmentGroups: any = [];
  resegmentations: any = [];
  selected: any = { source: [], target: [] };

  constructor(private surveysService: SurveysService) {}

  ngOnInit(): void {
    for (let item of this.survey.segments) {
      if (item.hasOwnProperty("items")) {
        for (let subitem of item.items) {
          this.surveySegments.push({
            id: subitem["id"],
            text: subitem["text"],
          });
        }
      }
      this.surveySegmentGroups.push({ id: item["id"], text: item["text"] });
    }

    for (let item of this.survey.items) {
      if (item.hasOwnProperty("isSegment")) {
        if (item["isSegment"]) {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              this.surveySegments.push({
                id: subitem["id"],
                text: subitem["text"],
              });
            }
          }
          this.surveySegmentGroups.push({ id: item["id"], text: item["text"] });
        }
      }
    }

    this.InitializeSurveyResegmentations();
  }

  InitializeSurveyResegmentations() {
    this.surveysService
      .getSurveyResegmentations(this.survey.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((resegmentations) => {
        this.resegmentations = resegmentations;

        this.initialized = true;
      });
  }

  doAddResegmentation() {
    for (let source of this.selected.source) {
      for (let target of this.selected.target) {
        let resegmentation = {
          source: source,
          sourceparent: this.survey.GetSurveySegmentParent(source),
          target: target,
        };
        this.surveysService.addSurveyResegmentation(
          this.survey.id,
          resegmentation,
        );
      }
    }
    this.selected = { segmentgroup: null, segment: null, replacesegment: null };
  }

  doDeleteResegmentation(resegmentation: any) {
    let index = this.resegmentations.indexOf(resegmentation);
    this.resegmentations.splice(index, 1);
    this.surveysService.deleteSurveyResegmentation(
      this.survey.id,
      resegmentation.key,
    );
  }
}
