<div>
  <ul class="nav nav-list">
    <li
      *ngFor="let item of list"
      [ngClass]="item.type != 'PAGEBREAK' ? 'node-dark' : 'node-light'"
    >
      <a
        (click)="onSelectItem(item)"
        [ngClass]="item.items ? 'dropdown-toggle' : ''"
      >
        <fa-icon
          [icon]="['fas', 'caret-right']"
          *ngIf="item.items && isCollapsed(item)"
          (click)="onCollapse(item)"
        ></fa-icon>
        <fa-icon
          [icon]="['fas', 'caret-down']"
          *ngIf="item.items && !isCollapsed(item)"
          (click)="onCollapse(item)"
        ></fa-icon>
        <span
          with="50"
          title="{{ item.text }}"
          *ngIf="item.type != 'PAGEBREAK'"
          >{{ item.text }}</span
        >
        <span with="50" title="{{ item.text }}" *ngIf="item.type == 'PAGEBREAK'"
          >&nbsp;</span
        >
      </a>
      <ul
        *ngIf="!collapsedById[item.id]"
        class="submenu"
        style="display: block"
      >
        <li *ngFor="let subitem of item.items">
          <a (click)="onSelectItem(subitem)" class="dropdown-toggle">
            <span with="50" title="{{ subitem.text }}">{{ subitem.text }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <br /><br />
</div>
