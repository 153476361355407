export class SurveyLabelsObject {
  labels = new Map();

  constructor() {}

  setItemLabel(key: string, data: any) {
    this.labels.set(key, data);
  }

  getItemLabel(key: string) {
    if (this.labels.has(key)) {
      let itemLabels = this.labels.get(key);
      for (let language of ["nl", "en", "fr", "de"]) {
        if (itemLabels.hasOwnProperty(language)) return itemLabels[language];
      }
    }
    return "";
  }

  getItemLabelForLanguage(key: string, language: string) {
    if (this.labels.has(key)) {
      let itemLabels = this.labels.get(key);
      if (language in itemLabels) {
        return itemLabels[language];
      }
    }
    return key;
  }

  getAvalaibleLanguages(): Array<any> {
    let languages = [];
    for (let item of this.labels.keys()) {
      let itemLabel = this.labels.get(item);
      for (let key in itemLabel) {
        if (key != "key") {
          if (languages.indexOf(key) == -1) languages.push(key);
        }
      }
    }
    return languages;
  }
}
