import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-ctrl-item-form",
  templateUrl: "./ctrl-item-form.component.html",
  styleUrls: ["./ctrl-item-form.component.css"],
})
export class CtrlItemFormComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  forms: any = [
    { id: "SINGLECHOICE", name: "Single choice" },
    { id: "MULTIPLECHOICE", name: "Multiple choice" },
    { id: "TEXT", name: "Content" },
  ];

  constructor() {}

  ngOnChanges(): void {}

  public onChangeForm() {
    this.itemChanged.emit({ item: this.item });
  }
}
