<div>
  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Email</label>
    <div class="col-sm-10">
      <input type="text" name="email" [(ngModel)]="email" />
    </div>
  </div>

  <button (click)="onExport()" class="btn-actions" *ngIf="IsEmailValid()">
    <fa-icon [icon]="['fas', 'check']"></fa-icon>
  </button>
</div>
