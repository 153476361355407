<div class="header">
  <div class="logo pull-left">
    <a href="https://dashboard.customerheartbeat.com/"
      ><img src="assets/images/img-logo-integron-small.png"
    /></a>
  </div>
</div>

<div class="main-container" id="main-container">
  <div class="main-content">
    <div class="limiter">
      <div
        class="container-login100"
        style="background-image: url(&quot;/assets/images/bg-login.jpg&quot;)"
      >
        <div class="wrap-login100">
          <form
            class="login100-form validate-form"
            (ngSubmit)="onLogin()"
            #loginForm="ngForm"
          >
            <span class="login100-form-logo-wrap">
              <img
                src="assets/images/img-logo-chb-KTO.png"
                style="margin-left: auto; margin-right: auto"
              />
            </span>

            <div class="wrap-input100">
              <input
                class="input100"
                type="text"
                id="email"
                name="email"
                placeholder="User name"
                required
                [(ngModel)]="model.email"
                #email="ngModel"
              />

              <div
                [hidden]="email.valid || email.pristine"
                class="alert alert-danger"
              >
                User name is required
              </div>
            </div>

            <div class="wrap-input100">
              <input
                class="input100"
                type="password"
                id="pass"
                name="pass"
                placeholder="Password"
                required
                [(ngModel)]="model.password"
                #password="ngModel"
              />

              <div
                [hidden]="password.valid || password.pristine"
                class="alert alert-danger"
              >
                Password is required
              </div>
            </div>

            <div class="container-login100-form-btn">
              <button
                class="login100-form-btn"
                type="submit"
                [disabled]="!loginForm.form.valid"
              >
                Login
              </button>
            </div>

            <div class="text-small login-footer">
              <br />
              Intelligence Dashboard is a product of Integron B.V.<br />
              All rights reserved
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!--


<div class="container">
  <div>
    <h1>Login</h1>
    <form (ngSubmit)="onLogin()" #loginForm="ngForm">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="text" class="form-control" id="email"
               required
               [(ngModel)]="model.email" name="email"
               #email="ngModel">
        <div [hidden]="email.valid || email.pristine"
             class="alert alert-danger">
          Email is required
        </div>
      </div>


      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password"
               required
               [(ngModel)]="model.password" name="password"
               #password="ngModel">
        <div [hidden]="password.valid || password.pristine"
             class="alert alert-danger">
          Password is required
        </div>
      </div>


      <button type="submit" class="btn btn-success" [disabled]="!loginForm.form.valid">Submit</button>

      <br/><br/><br/><br/>

      <button type="button" (click)="onLogout()">Log off</button>

    </form>
  </div>

</div>

-->
