<div *ngIf="initialized">
  <div class="header">
    <div class="menu pull-right">
      <button (click)="onGoToClientsList()" class="btn-actions" title="Clients">
        <fa-icon [icon]="['fas', 'users']"></fa-icon>
      </button>

      <button (click)="onGoToSurveyList()" class="btn-actions" title="Surveys">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>

  <div class="column-main">
    <div class="maincontent">
      <h1>Client</h1>

      <div class="contentblock">
        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Name</label>
          <div class="col-sm-10">
            <input name="text" [(ngModel)]="client.info.name" />
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Email</label>
          <div class="col-sm-10">
            <input name="text" [(ngModel)]="client.info.email" />
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Project</label>
          <div class="col-sm-10">
            <input name="text" [(ngModel)]="client.info.project" />
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Icon</label>
          <div class="col-sm-10">
            <img
              src="{{ HOST }}/static/profiles/{{ client.info.icon }}"
              *ngIf="client.info.icon"
            />
            <img
              src="{{ HOST }}/static/profiles/unknown"
              *ngIf="!client.info.icon"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <span
              ><button type="button" (click)="iconWindowActive = true">
                Choose profile photo
              </button></span
            >
          </div>
        </div>
      </div>

      <h1>Give access to</h1>

      <div class="contentblock">
        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Survey</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="survey"
              [data]="surveys"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="setting.survey"
              (valueChange)="onChangeSurvey($event)"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label"
            >Can access mailing</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="cache"
              [(ngModel)]="setting.blockMailing"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row" *ngIf="setting.blockMailing">
          <label for="name" class="col-sm-2 col-form-label">Mailing</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="mailing"
              [data]="mailings"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="setting.mailing"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label"
            >Can access dashboard</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="cache"
              [(ngModel)]="setting.blockDashboard"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row" *ngIf="setting.blockDashboard">
          <label for="name" class="col-sm-2 col-form-label"
            >Dashboard link</label
          >
          <div class="col-sm-10">
            <input name="text" [(ngModel)]="setting.dashboard" />
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label"
            >Can access uploads</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="cache"
              [(ngModel)]="setting.blockUploads"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label"
            >Can access reports</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="cache"
              [(ngModel)]="setting.blockReports"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Add settings</label>
          <div class="col-sm-10">
            <button type="button" (click)="onAddSetting()">AddSetting</button>
          </div>
        </div>
      </div>

      <h1>Access to</h1>

      <div class="contentblock">
        <kendo-grid [data]="getClientSettings()">
          <kendo-grid-column field="survey" title="Survey Name" [width]="350">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ GetSurveyName(dataItem.survey) }}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="blockMailing" title="Mailing">
            <ng-template kendoGridCellTemplate let-dataItem>
              <fa-icon
                [icon]="['fas', 'check']"
                *ngIf="dataItem.blockMailing"
              ></fa-icon>
              <fa-icon
                [icon]="['fas', 'times']"
                *ngIf="!dataItem.blockMailing"
              ></fa-icon>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="blockDashboard" title="Dashboard">
            <ng-template kendoGridCellTemplate let-dataItem>
              <fa-icon
                [icon]="['fas', 'check']"
                *ngIf="dataItem.blockDashboard"
              ></fa-icon>
              <fa-icon
                [icon]="['fas', 'times']"
                *ngIf="!dataItem.blockDashboard"
              ></fa-icon>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="blockUploads" title="Uploads">
            <ng-template kendoGridCellTemplate let-dataItem>
              <fa-icon
                [icon]="['fas', 'check']"
                *ngIf="dataItem.blockUploads"
              ></fa-icon>
              <fa-icon
                [icon]="['fas', 'times']"
                *ngIf="!dataItem.blockUploads"
              ></fa-icon>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="blockReports" title="Reports">
            <ng-template kendoGridCellTemplate let-dataItem>
              <fa-icon
                [icon]="['fas', 'check']"
                *ngIf="dataItem.blockReports"
              ></fa-icon>
              <fa-icon
                [icon]="['fas', 'times']"
                *ngIf="!dataItem.blockReports"
              ></fa-icon>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="delete" title="Delete" [width]="50">
            <ng-template kendoGridCellTemplate let-dataItem
              ><span (click)="onRemoveSettings(dataItem)"
                ><fa-icon [icon]="['fas', 'minus']"></fa-icon></span
            ></ng-template>
          </kendo-grid-column>
        </kendo-grid>

        <br /><br /><br /><br />

        <div class="form-group row">
          <label for="save" class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <button type="button" (click)="SaveClient()">Save Client</button>
          </div>
        </div>

        <kendo-window
          [top]="200"
          [left]="200"
          title="Profile Image"
          *ngIf="iconWindowActive"
          (close)="onCloseIconWindow()"
        >
          <fieldset class="group-border">
            <div class="form-group row">
              <label for="file" class="col-sm-2 col-form-label">Tag</label>
              <div class="col-sm-10">
                <input type="file" (change)="fileChangeEvent($event)" />
              </div>
            </div>

            <div class="form-group row">
              <label for="image" class="col-sm-2 col-form-label"
                >Crop Image</label
              >
              <button type="button" (click)="onSaveProfileIcon()">
                Save Profile Image
              </button>
              <div class="col-sm-10">
                <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [cropperStaticWidth]="200"
                  [cropperStaticHeight]="200"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="1 / 1"
                  [resizeToWidth]="256"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                ></image-cropper>
              </div>
            </div>
          </fieldset>
        </kendo-window>
      </div>
    </div>
  </div>
</div>
