<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Respondents</h3>
    &nbsp;&nbsp;<button (click)="onAddNewRespondent()" class="btn-actions">
      <fa-icon [icon]="['fas', 'plus']" style="color: orange"></fa-icon>
    </button>

    <kendo-window
      title="Respondent {{ selected.loginname }}"
      *ngIf="respondentWindowActive"
      (close)="onCloseRespondentWindow()"
    >
      <app-survey-respondent
        [survey]="survey"
        [columns]="columns"
        [item]="selected"
        (itemChanged)="onRespondentUpdate($event)"
      ></app-survey-respondent>
    </kendo-window>

    <kendo-grid
      [data]="gridView"
      [pageSize]="pageSize"
      [resizable]="true"
      [pageable]="true"
      [skip]="skip"
      [sortable]="{ mode: 'single' }"
      [sort]="sort"
      filterable="menu"
      (pageChange)="pageChange($event)"
      (sortChange)="sortChange($event)"
      (filterChange)="filterChange($event)"
    >
      <ng-container *ngFor="let column of columns">
        <kendo-grid-column
          field="column.column"
          title="{{ column.column }}"
          *ngIf="
            column.column != 'id' &&
            column.column != 'password' &&
            column.column != 'systime'
          "
          [filterable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem[column.column] }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="column.column"
          title="{{ column.column }}"
          *ngIf="column.column == 'systime'"
          [filterable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ GetDateFormat(dataItem[column.column]) }}</span>
          </ng-template>
        </kendo-grid-column>
      </ng-container>

      <ng-container *ngFor="let segment of survey.segments">
        <kendo-grid-column
          field="{{ segment.text }}"
          title="{{ segment.text }}"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-dropdownlist
              [textField]="'text'"
              [valueField]="'text'"
              [data]="segment.items"
              (valueChange)="segmentChange($event, filterService, column.field)"
            ></kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
      </ng-container>

      <kendo-grid-column
        field="autologin"
        title="autologin"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            href="{{ surveyUrl }}/#/autologin/{{ dataItem.autologin }}"
            target="_blank"
            >Autologin</a
          >
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="archive" title="archive" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            (click)="onRespondentArchive(dataItem)"
            *ngIf="dataItem['type'] != 'ARCHIVE'"
            >Archive</a
          >
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="update" title="update" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button (click)="selected = dataItem; onOpenRespondentWindow()">
            EDIT
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
