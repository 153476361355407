/*
export replaceAll(s : string, m : string, r : string, p : string): string{
    if( s == p || r.indexOf(m) > -1){
        return s;
    }else{
        this.replaceAll(s.replace(m, r), m, r, s);
    }
}
*/
export function replaceAll(str, find, replace) {
  var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  return str.replace(new RegExp(escapedFind, "g"), replace);
}

export function guid() {
  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
}

function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function generatePassword(passwordLength: number): string {
  var allChars, lowerChars, numberChars, randPasswordArray, upperChars;
  numberChars = "0123456789";
  upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  lowerChars = "abcdefghiklmnopqrstuvwxyz";
  allChars = numberChars + upperChars + lowerChars;
  randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray = randPasswordArray.fill(allChars, 3);
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }),
  ).join("");
}

function shuffleArray(array) {
  var i, j, temp;
  i = array.length - 1;
  while (i > 0) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
    i--;
  }
  return array;
}

export function b64_encode_autologin(s): string {
  s = encodeURIComponent(s);
  s = btoa(s);
  s = s.replace(/\=/g, "-");
  return s;
}

export const FUTURE_TIME = 2000000000000;

export function AM_STATUSES(): Array<any> {
  return [
    { id: "OPEN", color: "#BC163A" },
    { id: "IN_PROCESS", color: "#EF7814" },
    { id: "DONE", color: "#66BB93" },
  ];
}

export function validateEmail(email): any {
  return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
    email,
  );
}
