<div *ngIf="initialized">
  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">File {{ type }}</label>
    <div class="col-sm-10">
      <kendo-upload
        [saveUrl]="uploadSaveUrl"
        [saveField]="uploadSaveField"
        [restrictions]="uploadRestrictions"
        (upload)="uploadEventHandler($event)"
      >
      </kendo-upload>
    </div>
  </div>

  <button (click)="onClose()" class="btn-actions" *ngIf="buttons">
    <fa-icon [icon]="['fas', 'check']"></fa-icon>
  </button>
</div>
