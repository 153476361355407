import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploaderService {
  constructor(private httpClient: HttpClient) {}

  uploadProfileIcon(formData: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.host + "/importimage/",
      formData,
    );
  }
}
