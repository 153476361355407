import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { JobsService } from "../../../../services/jobs.service";

@Component({
  selector: "app-ctrl-segment-info",
  templateUrl: "./ctrl-segment-info.component.html",
  styleUrls: ["./ctrl-segment-info.component.css"],
})
export class CtrlSegmentInfoComponent implements OnChanges {
  @Input() survey: any;
  @Input() item: any;
  @Output() itemChanged = new EventEmitter();

  newname: any = "";
  updaterespondents: boolean = false;

  constructor(
    private authService: AuthService,
    private jobsService: JobsService,
  ) {}

  ngOnChanges(): void {
    this.newname = this.item.text;
  }

  public onChangeInfo() {
    this.itemChanged.emit({ item: this.item });
  }

  UpdateSegmentInfo() {
    let oldtext = this.item.text;
    let newtext = this.newname;

    newtext = newtext.replace(/\//gi, "_");
    newtext = newtext.replace(/\\/gi, "_");

    this.item.text = newtext;

    this.onChangeInfo();
    this.newname = "";
    if (this.updaterespondents) {
      this.jobsService.addJob({
        type: "update_respondents",
        state: "WAITING_FOR_PROCESS",
        data: {
          item: this.item.id,
          survey: this.survey.id,
          user: this.authService.GetCurrentUser(),
          value: oldtext,
        },
      });
    }
  }
}
