import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { TemplatesService } from "../../services/templates.service";
import { AuthService } from "../../services/auth.service";
import { SurveyObject } from "../../shared/survey";
import { SurveyLabelsObject } from "../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../shared/utils";

@Component({
  selector: "app-template-chaptermap-info",
  templateUrl: "./template-chaptermap-info.component.html",
  styleUrls: ["./template-chaptermap-info.component.css"],
})
export class TemplateChaptermapInfoComponent implements OnInit {
  surveyObj = new SurveyObject();

  templateKey: string = "chapters";
  templateOn: boolean = true;
  selectedItem: any = null;
  selectedItemParent: any = null;
  initialized: boolean = false;

  constructor(
    private templatesService: TemplatesService,
    private authService: AuthService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["template"];

    this.InitializeTemplateInfo();
    this.InitializeTemplteItems();
  }

  InitializeTemplateInfo() {
    this.templatesService
      .getTemplateInfo(this.templateKey, this.surveyObj.id)
      .valueChanges()
      .subscribe((info) => {
        console.log(info);
        if (info) {
          this.surveyObj.info = info;
        }
      });
  }

  InitializeTemplteItems() {
    this.templatesService
      .getTemplateItems(this.templateKey, this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((chapters) => {
        this.surveyObj.chapters = chapters;

        this.surveyObj.chapters.sort((a, b) =>
          a.position > b.position ? 1 : -1,
        );

        this.initialized = true;
      });
  }

  onItemSelected(item: any) {
    this.selectedItem = item.item;

    if (item.item.hasOwnProperty("parent")) {
      for (let surveyItem of this.surveyObj.chapters) {
        if (surveyItem.id == item.item.parent) {
          this.selectedItemParent = surveyItem;
        }
      }
    } else {
      this.selectedItemParent = item.item;
    }
  }

  onItemChanged(item: any) {
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItemParent,
    );
  }

  onAddChapter() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.chapters,
        "",
        "CHAPTER",
      ),
      type: "CHAPTER",
      template: "other",
      display: "TABLE",
      position: this.surveyObj.chapters.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddSubChapter() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }
    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "SUBCHAPTER",
      ),
      type: "SUBCHAPTER",
      template: "other",
      display: "TABLE",
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  onRemoveChapter() {
    if (confirm("Are you sure you want to delete this chapter?") == true) {
      let removeIds = [];
      let removedId = this.selectedItem.id;

      removeIds.push(removedId);

      if (this.selectedItem.parent) {
        for (let surveyItem of this.surveyObj.chapters) {
          if (surveyItem.id == this.selectedItem.parent) {
            let removedPos = surveyItem.items.indexOf(this.selectedItem);
            surveyItem.items.splice(removedPos, 1);
            this.templatesService.updateTemplateSurveyItem(
              this.templateKey,
              this.surveyObj.id,
              surveyItem,
            );

            this.selectedItem = null;
            for (let pos in [removedPos, removedPos - 1, 0]) {
              if (surveyItem.items[pos]) {
                if (surveyItem.items[pos].id != removedId) {
                  this.selectedItem = surveyItem.items[pos];
                  break;
                }
              }
            }
          }
        }
      } else {
        let removedPos = this.surveyObj.chapters.indexOf(this.selectedItem);

        if (this.selectedItem.items) {
          for (let subitem of this.selectedItem.items) {
            removeIds.push(subitem.id);
          }
        }

        this.templatesService
          .deleteTemplateSurveyItem(
            this.templateKey,
            this.surveyObj.id,
            this.selectedItem.key,
          )
          .then((res) => {
            if (this.surveyObj.items.length > 0) {
              if (this.surveyObj.items[removedPos])
                this.selectedItem = this.surveyObj.chapters[removedPos];
              else
                this.selectedItem =
                  this.surveyObj.chapters[this.surveyObj.items.length - 1];
            } else {
              this.selectedItem = null;
            }
          });
      }
    }
  }

  onGoToTemplatesList() {
    this.router.navigate(["/templates"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
