<div class="contentblock">
  <h3>Exclude</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label"
      >Exclude from dashboard</label
    >
    <div class="col-sm-10">
      <kendo-switch
        name="exclude"
        [(ngModel)]="item.exclude"
        (valueChange)="onChangeExclude()"
      ></kendo-switch>
    </div>
  </div>
</div>
