<div class="contentblock">
  <h3>Move item</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Move item before</label>
    <div class="col-sm-10">
      <kendo-dropdownlist
        name="items"
        [data]="survey.items"
        [textField]="'text'"
        [valueField]="'id'"
        [(ngModel)]="selected"
      ></kendo-dropdownlist>

      &nbsp;&nbsp;&nbsp;

      <button type="button" (click)="onMoveItem()">Move Item</button>
    </div>
  </div>

  <div></div>
</div>
