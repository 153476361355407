<div *ngIf="initialized">
  <h1>Add new Integron Sys User</h1>

  <div class="contentblock">
    <div class="form-group row">
      <label for="name" class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <input name="text" [(ngModel)]="sysuser.name" />
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-2 col-form-label"
        >Email
        <span *ngIf="IsEmailTaken()" style="color: red"
          >Already exists</span
        ></label
      >
      <div class="col-sm-10">
        <input name="email" [(ngModel)]="sysuser.email" />
      </div>
    </div>

    <div class="form-group row">
      <label for="role" class="col-sm-2 col-form-label">Role</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="role"
          [data]="[{ id: 'integron' }, { id: 'sales' }]"
          [textField]="'id'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="sysuser.role"
        ></kendo-dropdownlist>
      </div>
    </div>
  </div>

  <div
    class="form-group row"
    *ngIf="
      !IsEmailTaken() && sysuser.name.length > 0 && sysuser.email.length > 0
    "
  >
    <label for="save" class="col-sm-2 col-form-label"></label>
    <div class="col-sm-10">
      <button type="button" (click)="onAddNewSysUser()">Save sys user</button>
    </div>
  </div>

  <h1>Integron Sys Users</h1>

  <kendo-grid
    [data]="gridData"
    [filterable]="true"
    [filter]="state.filter"
    (dataStateChange)="dataStateChange($event)"
  >
    <kendo-grid-column
      field="email"
      title="User Email"
      [width]="350"
    ></kendo-grid-column>
    <kendo-grid-column
      field="name"
      title="User Name"
      [width]="350"
    ></kendo-grid-column>
    <kendo-grid-column
      field="reset"
      title="Reset Password"
      [filterable]="false"
      [width]="50"
    >
      <ng-template kendoGridCellTemplate let-dataItem
        ><span (click)="onResetPassword(dataItem)"
          ><fa-icon
            [icon]="['fas', 'envelope']"
            style="color: #dd5a43"
          ></fa-icon></span
      ></ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="delete"
      title="Delete"
      [filterable]="false"
      [width]="50"
    >
      <ng-template kendoGridCellTemplate let-dataItem
        ><span (click)="onRemoveSysUser(dataItem)"
          ><fa-icon
            [icon]="['fas', 'trash']"
            style="color: #dd5a43"
          ></fa-icon></span
      ></ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
