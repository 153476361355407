<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Add transformations entry</h3>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">In Group</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="segmentGroups"
          [data]="survey.segments"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.segmentgroup"
          (valueChange)="onSelectSegmentGroup($event)"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Replace segment</label>
      <div class="col-sm-10">
        <input type="text" name="max" [(ngModel)]="selected.replacesegment" />
      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">With Segment</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="segments"
          [data]="surveySegments"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.segment"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div
      class="form-group row"
      *ngIf="
        selected.segmentgroup && selected.replacesegment && selected.segment
      "
    >
      <label for="label" class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10">
        <button (click)="doAddTransformation()">Add Trasformation</button>
      </div>
    </div>
  </div>

  <div class="contentblock" *ngIf="transformations.length > 0">
    <h3>Transformations</h3>

    <kendo-grid [data]="transformations" [sortable]="{ mode: 'single' }">
      <kendo-grid-column field="segmentgroup" title="Segment Group">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ survey.GetSurveySegmentById(dataItem.segmentgroup) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="replacesegment"
        title="Replace"
      ></kendo-grid-column>
      <kendo-grid-column field="segment" title="Segment">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ survey.GetSurveySegmentById(dataItem.segment) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="remove" title="Remove">
        <ng-template kendoGridCellTemplate let-dataItem>
          <fa-icon
            [icon]="['fas', 'times']"
            style="color: orange"
            (click)="doDeleteTransformation(dataItem)"
          ></fa-icon>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
