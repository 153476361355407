import { Component, OnInit } from "@angular/core";
import { SurveysService } from "../services/surveys.service";
import { UsersService } from "../services/users.service";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  surveys: any = {};
  notifications: any = [];
  selected: any;
  initialized: boolean = false;
  showNotificationWindow: boolean = false;

  constructor(
    private surveysService: SurveysService,
    private usersService: UsersService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.InitializeSurveysList();
    this.InitializeNotifications();
  }

  InitializeSurveysList() {
    this.surveysService
      .getSurveysList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((surveys) => {
        this.surveys = {};

        for (let survey of surveys) {
          this.surveys[survey.key] = survey.info.name;
        }
      });
  }

  InitializeNotifications() {
    this.usersService
      .getUserNotifications(this.authService.GetCurrentUser())
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((notifications) => {
        if (notifications) {
          this.notifications = notifications;
        }

        this.notifications.sort((a, b) => (a.systime > b.systime ? 1 : -1));

        this.initialized = true;
      });
  }

  GetUnreadNotifications(): number {
    let count = 0;
    for (let notification of this.notifications) {
      if (notification.status == "UNREAD") {
        count = count + 1;
      }
    }
    return count;
  }

  doShowNotificationWindow() {
    this.showNotificationWindow = true;
  }

  onCloseNotificationWindow() {
    this.showNotificationWindow = false;
  }

  onBackToNotificationWindow() {
    this.selected = null;
  }

  onDeleteNotification() {
    this.usersService.deleteUserNotification(
      this.authService.GetCurrentUser(),
      this.selected.key,
    );
    this.onBackToNotificationWindow();
  }

  onDeleteAllNotification() {
    this.usersService.deleteAllUserNotification(
      this.authService.GetCurrentUser(),
    );
  }

  GetSurveyName(survey: any) {
    if (this.surveys.hasOwnProperty(survey)) {
      return this.surveys[survey];
    }
    return "";
  }

  onSelectNotification(dataItem: any) {
    this.selected = dataItem;
    if (this.selected.hasOwnProperty("status")) {
      if (this.selected["status"] == "UNREAD") {
        this.usersService.markNotificationAsRead(
          this.authService.GetCurrentUser(),
          this.selected.key,
        );
      }
    }
  }
}
