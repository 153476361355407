<div class="contentblock">
  <h3 *ngIf="item.type == 'SKIP'">Skip criteria</h3>
  <h3 *ngIf="item.type != 'SKIP'">Visibility criteria</h3>

  <kendo-switch
    name="visibility"
    [(ngModel)]="item.visibility"
    (valueChange)="onChangeVisibility($event)"
    *ngIf="item.type != 'SKIP'"
  ></kendo-switch>

  <kendo-dropdownlist
    name="skipto"
    [data]="targetItems"
    [textField]="'name'"
    [valueField]="'id'"
    [valuePrimitive]="true"
    [(ngModel)]="item.target"
    *ngIf="item.type == 'SKIP'"
  ></kendo-dropdownlist>

  <br /><br />

  <kendo-grid
    [data]="item.relationships"
    [sortable]="{ mode: 'single' }"
    *ngIf="item.relationships"
  >
    <kendo-grid-column field="operator" title="Operator">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="rowIndex == 0">If</span>
        <span *ngIf="rowIndex > 0">{{ dataItem.operator }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="item" title="Item">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ ParseItemId(dataItem.item) }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="subitem" title="Subitem">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ ParseSubItemId(dataItem.item, dataItem.subitem) }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="action" title="Action"></kendo-grid-column>
    <kendo-grid-column field="remove" title="Remove">
      <ng-template kendoGridCellTemplate let-dataItem>
        <fa-icon
          [icon]="['fas', 'times']"
          style="color: orange"
          (click)="onDeleteRelationship(dataItem)"
        ></fa-icon>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <br />

  <div>
    <div>
      <div class="col-sm-2 row-seperator">Rel.</div>
      <div class="col-sm-2 row-seperator">Source</div>
      <div class="col-sm-4 row-seperator">Class</div>
      <div class="col-sm-2 row-seperator">Action</div>
      <div class="col-sm-2 row-seperator">Add</div>
    </div>
    <div>
      <div class="col-sm-2 row-seperator">
        <kendo-dropdownlist
          name="operators"
          [data]="operators"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.operator"
        ></kendo-dropdownlist>
      </div>
      <div class="col-sm-2 row-seperator">
        <kendo-dropdownlist
          name="items"
          [data]="surveyItems"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.item"
          (valueChange)="onSelectSurveyItem($event)"
        ></kendo-dropdownlist>
      </div>
      <div class="col-sm-4 row-seperator">
        <kendo-multiselect
          name="subitems"
          [data]="surveySubItems"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.subitems"
        ></kendo-multiselect>
      </div>
      <div class="col-sm-2 row-seperator">
        <kendo-dropdownlist
          name="actions"
          [data]="actions"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.action"
        ></kendo-dropdownlist>
      </div>
      <div class="col-sm-2 row-seperator">
        <fa-icon
          [icon]="['fas', 'plus']"
          style="color: orange"
          (click)="onAddRelationship()"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>
