<div *ngIf="initialized">
  <div class="contentblock">
    <h3>Add resegmentation entry</h3>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Segment</label>
      <div class="col-sm-10">
        <kendo-multiselect
          name="segments"
          [data]="surveySegments"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.source"
        ></kendo-multiselect>
      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Cross With</label>
      <div class="col-sm-10">
        <kendo-multiselect
          name="segmentGroups"
          [data]="surveySegmentGroups"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selected.target"
        ></kendo-multiselect>
      </div>
    </div>

    <div class="form-group row" *ngIf="selected.source && selected.target">
      <label for="label" class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10">
        <button (click)="doAddResegmentation()">Add Resegmentation</button>
      </div>
    </div>
  </div>

  <div class="contentblock" *ngIf="resegmentations.length > 0">
    <h3>Resegmentations</h3>

    <kendo-grid [data]="resegmentations" [sortable]="{ mode: 'single' }">
      <kendo-grid-column field="source" title="Source">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ survey.GetSurveySegmentById(dataItem.source) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="target" title="Target">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ survey.GetSurveySegmentById(dataItem.target) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="remove" title="Remove">
        <ng-template kendoGridCellTemplate let-dataItem>
          <fa-icon
            [icon]="['fas', 'times']"
            style="color: orange"
            (click)="doDeleteResegmentation(dataItem)"
          ></fa-icon>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
