import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  model: any = { email: "", password: "" };

  constructor(
    private authService: AuthService,
    private activateRoute: ActivatedRoute,
    private location: Location,
    public router: Router,
  ) {}

  ngOnInit(): void {}

  onLogin() {
    this.authService.SignIn(this.model.email, this.model.password);
  }

  onLogout() {
    this.authService.SignOut();
  }
}
