import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsersService } from "../services/users.service";
import { JobsService } from "../services/jobs.service";
import { AuthService } from "../services/auth.service";
import { process, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import { map } from "rxjs/operators";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent implements OnInit {
  clients: any = [];
  state: State = { filter: null };
  initialized: boolean = false;

  constructor(
    private usersService: UsersService,
    private jobsService: JobsService,
    private authService: AuthService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.InitializeClients();
  }

  InitializeClients() {
    this.usersService
      .getUsersList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((users) => {
        this.clients = [];

        for (let user of users) {
          if (user.hasOwnProperty("info")) {
            if (user["info"]["role"] == "client") {
              this.clients.push({
                id: user.key,
                name: user.info.name,
                email: user.info.email,
                project: user.info.project,
              });
            }
          }
        }

        this.clients.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.gridData = process(this.clients, this.state);

        this.initialized = true;
      });
  }

  public gridData: GridDataResult = process(this.clients, this.state);

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.clients, this.state);
  }

  public onSelectClient(dataItem: any) {
    let url = "client/" + dataItem.id;
    this.authService.NavigateToURL(url);
  }

  public onAddNewClient() {
    let url = "client";
    this.authService.NavigateToURL(url);
  }

  public onRemoveClient(dataItem: any) {
    let client = dataItem.id;
    if (
      confirm(
        "Are you sure you want to remove this client? He will lose all his privileges.",
      ) == true
    ) {
      this.usersService.deleteUser(client);
      this.jobsService.addJob({
        type: "delete_client_authorization",
        state: "WAITING_FOR_PROCESS",
        data: { user: this.authService.GetCurrentUser(), client: client },
      });
    }
  }

  public onSignOut() {
    this.authService.SignOut();
  }
}
