<div class="contentblock">
  <h3>Public Columns</h3>

  <ng-container *ngFor="let column of columns">
    <div class="form-group row" *ngIf="isBaseColumn(column.column)">
      <label for="{{ column.column }}" class="col-sm-2 col-form-label">{{
        column.column
      }}</label>
      <div class="col-sm-10">
        <kendo-switch
          name="{{ column.column }}"
          [(ngModel)]="selected[column.column]"
        ></kendo-switch>
      </div>
    </div>
  </ng-container>

  <button type="button" (click)="doSaveMetaData()">Save metadata</button>
</div>
