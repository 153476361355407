<div class="contentblock">
  <h3>Labels</h3>

  <fieldset class="group-border">
    <div class="form-group row">
      <label for="language" class="col-sm-2 col-form-label">Language</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="languages"
          [data]="languages"
          [textField]="'name'"
          [valueField]="'code'"
          [valuePrimitive]="true"
          [(ngModel)]="language"
          (valueChange)="onSelectLanguage($event)"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <img
              src="assets/images/flags/{{ dataItem.code }}.png"
              border="0"
            />&nbsp;{{ dataItem?.name }}
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <img
              src="assets/images/flags/{{ dataItem.code }}.png"
              border="0"
            />&nbsp;{{ dataItem?.name }}
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row" *ngIf="item.type == 'MATRIX'">
      <label for="language" class="col-sm-2 col-form-label">Subindicator</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="subindicatorsOptions"
          [data]="subindicatorsOptions"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selectedSubindicator"
          (valueChange)="onSelectSubindicator($event)"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label">Label</label>
      <div class="col-sm-10">
        <kendo-editor [(ngModel)]="label" style="height: 200px">
          <kendo-toolbar>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button
                kendoEditorBoldButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorItalicButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorUnderlineButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorStrikethroughButton
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button
                kendoEditorAlignLeftButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorAlignCenterButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorAlignRightButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorAlignJustifyButton
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-dropdownlist
              kendoEditorFormat
            ></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist
              kendoEditorFontSize
            ></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist
              kendoEditorFontFamily
            ></kendo-toolbar-dropdownlist>
            <kendo-toolbar-colorpicker
              kendoEditorForeColor
            ></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker
              kendoEditorBackColor
              view="gradient"
            ></kendo-toolbar-colorpicker>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button
                kendoEditorInsertUnorderedListButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorInsertOrderedListButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorIndentButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorOutdentButton
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button
                kendoEditorUndoButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorRedoButton
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button
                kendoEditorCreateLinkButton
              ></kendo-toolbar-button>
              <kendo-toolbar-button
                kendoEditorUnlinkButton
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button
              kendoEditorInsertImageButton
            ></kendo-toolbar-button>
            <kendo-toolbar-button
              kendoEditorViewSourceButton
            ></kendo-toolbar-button>
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <br /><br />
    <button type="button" (click)="SaveLabel()">
      Save label for language {{ language }}
    </button>
  </fieldset>
</div>
