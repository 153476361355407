import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { SurveysComponent } from "./surveys/surveys.component";
import { SurveyInfoComponent } from "./surveys/survey/setup/survey-info/survey-info.component";
import { SurveyLabelsComponent } from "./surveys/survey/setup/survey-labels/survey-labels.component";
import { SurveyCompositionComponent } from "./surveys/survey/setup/survey-composition/survey-composition.component";
import { SurveySegmentsComponent } from "./surveys/survey/setup/survey-segments/survey-segments.component";
import { SurveyMailingsComponent } from "./surveys/survey/setup/survey-mailings/survey-mailings.component";
import { SurveyDashboardComponent } from "./surveys/survey/setup/survey-dashboard/survey-dashboard.component";
import { ClientsComponent } from "./clients/clients.component";
import { ClientInfoComponent } from "./clients/client-info/client-info.component";
import { SysusersComponent } from "./sysusers/sysusers.component";
import { TemplatesComponent } from "./templates/templates.component";
import { TemplateSurveyitemInfoComponent } from "./templates/template-surveyitem-info/template-surveyitem-info.component";
import { TemplateMailingInfoComponent } from "./templates/template-mailing-info/template-mailing-info.component";
import { TemplateChaptermapInfoComponent } from "./templates/template-chaptermap-info/template-chaptermap-info.component";
import { JobsComponent } from "./jobs/jobs.component";

import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "surveys", component: SurveysComponent, canActivate: [AuthGuard] },
  {
    path: "survey/:survey/info",
    component: SurveyInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/:survey/labels",
    component: SurveyLabelsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/:survey/compose",
    component: SurveyCompositionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/:survey/segments",
    component: SurveySegmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/:survey/mailings",
    component: SurveyMailingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/:survey/dashboard",
    component: SurveyDashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "clients", component: ClientsComponent, canActivate: [AuthGuard] },
  {
    path: "client/:client",
    component: ClientInfoComponent,
    canActivate: [AuthGuard],
  },
  { path: "client", component: ClientInfoComponent, canActivate: [AuthGuard] },
  { path: "sysusers", component: SysusersComponent, canActivate: [AuthGuard] },
  { path: "jobs", component: JobsComponent, canActivate: [AuthGuard] },
  {
    path: "templates",
    component: TemplatesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/items/:template",
    component: TemplateSurveyitemInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/mailings/:template",
    component: TemplateMailingInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/chapters/:template",
    component: TemplateChaptermapInfoComponent,
    canActivate: [AuthGuard],
  },
  { path: "", redirectTo: "/surveys", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
