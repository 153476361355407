import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { JobsService } from "../../../../services/jobs.service";
import { validateEmail } from "../../../../shared/utils";

@Component({
  selector: "app-ctrl-export-file",
  templateUrl: "./ctrl-export-file.component.html",
  styleUrls: ["./ctrl-export-file.component.css"],
})
export class CtrlExportFileComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private jobsService: JobsService,
  ) {}

  @Input() survey: any;
  @Input() type: any;
  @Output() onWindowClose = new EventEmitter();

  email: string = "";

  ngOnInit(): void {}

  public IsEmailValid(): boolean {
    return validateEmail(this.email);
  }

  public onExport() {
    if (
      confirm("Are you sure you are ready to export the " + this.type + " ?") ==
      true
    ) {
      this.jobsService.addJob({
        type: "export_" + this.type,
        state: "WAITING_FOR_PROCESS",
        data: {
          email: this.email,
          survey: this.survey.id,
          user: this.authService.GetCurrentUser(),
        },
      });
      this.email = "";
    }
    this.onWindowClose.emit({});
  }
}
