import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { TemplatesService } from "../../services/templates.service";
import { AuthService } from "../../services/auth.service";
import { SurveyObject } from "../../shared/survey";
import { SurveyLabelsObject } from "../../shared/labels";
import { map } from "rxjs/operators";
import { guid } from "../../shared/utils";
import { QUESTION_LANGUAGE } from "../../shared/language";

@Component({
  selector: "app-template-surveyitem-info",
  templateUrl: "./template-surveyitem-info.component.html",
  styleUrls: ["./template-surveyitem-info.component.css"],
})
export class TemplateSurveyitemInfoComponent implements OnInit {
  surveyObj = new SurveyObject();
  labelsObj = new SurveyLabelsObject();

  templateKey: string = "items";
  templateOn: boolean = true;
  selectedItem: any = null;
  selectedItemParent: any = null;
  initialized: boolean = false;
  languageWindowActive: boolean = false;
  importWindowActive: boolean = false;
  exportWindowActive: boolean = false;
  languageQuestion: any = QUESTION_LANGUAGE;
  selectedLanguages: any = [];
  extensions: any = [".xlsx"];

  constructor(
    private templatesService: TemplatesService,
    private authService: AuthService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["template"];

    this.InitializeTemplateInfo();
    this.InitializeTemplateItems();
    this.InitializeTemplateLabels();
  }

  InitializeTemplateInfo() {
    this.templatesService
      .getTemplateInfo(this.templateKey, this.surveyObj.id)
      .valueChanges()
      .subscribe((info) => {
        if (info) {
          this.surveyObj.info = info;
        }
      });
  }

  InitializeTemplateItems() {
    this.templatesService
      .getTemplateItems(this.templateKey, this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((items) => {
        this.surveyObj.items = items;
        this.surveyObj.segments = [];

        this.surveyObj.items.sort((a, b) => (a.position > b.position ? 1 : -1));

        this.RefreshSelectedItem();

        this.initialized = true;
      });
  }

  InitializeTemplateLabels() {
    this.templatesService
      .getTemplateLabels(this.surveyObj.id)
      .valueChanges()
      .subscribe((labels) => {
        if (labels) {
          for (let key in labels) {
            this.labelsObj.setItemLabel(key, labels[key]);
          }
        }
      });
  }

  RefreshSelectedItem() {
    if (this.selectedItemParent) {
      for (let item of this.surveyObj.items) {
        if (item.id == this.selectedItemParent.id) {
          let selectedItemId = null;
          if (this.selectedItem) {
            selectedItemId = this.selectedItem.id;
          }
          this.selectedItemParent = item;
          this.selectedItem = item;
          if (selectedItemId && item.hasOwnProperty("items")) {
            for (let subitem of item["items"]) {
              if (subitem.id == selectedItemId) {
                this.selectedItem = subitem;
              }
            }
          }
        }
      }
    }
  }

  onAddSuccessFactor() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "SF"),
      type: "SUCCESSFACTOR",
      style: "",
      required: { E: false, I: false, P: false, C: false },
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddQuestion() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "QUES"),
      type: "QUESTION",
      style: "VERTICAL",
      form: "SINGLECHOICE",
      isTip: false,
      isAnonymity: false,
      isSegment: false,
      required: false,
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddLanguageQuestion() {
    let item = {};

    for (let key in this.languageQuestion) {
      if (key != "items") item[key] = this.languageQuestion[key];
    }

    if (this.selectedLanguages.length > 0) {
      item["items"] = [];
      for (let language of this.languageQuestion["items"]) {
        if (this.selectedLanguages.indexOf(language["id"]) > -1) {
          item["items"].push(language);
        }
      }

      item["position"] = this.surveyObj.items.length;
      this.templatesService.addTemplateSurveyItem(
        this.templateKey,
        this.surveyObj.id,
        item,
      );
    }
  }

  onAddMarker() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "MARKER",
      ),
      type: "MARKER",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddText() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "TEXT"),
      type: "TEXT",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddPageBreak() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "PAGEBREAK",
      ),
      type: "PAGEBREAK",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddMatrix() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(
        this.surveyObj.items,
        "",
        "MATRIX",
      ),
      type: "MATRIX",
      style: "MATRIX",
      visibility: true,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddSkip() {
    let item = {
      id: guid(),
      text: this.surveyObj.getSurveyItemText(this.surveyObj.items, "", "SKIP"),
      type: "SKIP",
      style: "",
      required: false,
      target: null,
      position: this.surveyObj.items.length,
    };
    this.templatesService.addTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onAddIndicator() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "IND",
      ),
      type: "INDICATOR",
      style: "",
      required: { E: false, I: false, P: false, C: false },
      visibility: true,
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  addQuestionToMatrix() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "Q",
      ),
      type: "QUESTION",
      form: "SINGLECHOICE",
      style: "",
      required: false,
      visibility: true,
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  onAddSubindicator() {
    if (!this.selectedItem.subindicators) {
      this.selectedItem["subindicators"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.subindicators,
        this.selectedItem.text,
        "SUBINDICATOR",
      ),
      type: "SUBINDICATOR",
      style: "",
      scale: 0,
      required: false,
      position: this.selectedItem.subindicators.length,
    };
    this.selectedItem["subindicators"].push(item);
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  addAnswer() {
    if (!this.selectedItem.items) {
      this.selectedItem["items"] = [];
    }

    let item = {
      id: guid(),
      parent: this.selectedItem.id,
      text: this.surveyObj.getSurveyItemText(
        this.selectedItem.items,
        this.selectedItem.text,
        "ANS",
      ),
      type: "ANSWER",
      style: "",
      required: false,
      visibility: true,
      position: this.selectedItem.items.length,
    };
    this.selectedItem["items"].push(item);
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItem,
    );
  }

  onCloneItem() {
    if (confirm("Are you sure you want to clone this item?") == true) {
      if (this.selectedItem) {
        let columns = [
          "key",
          "id",
          "text",
          "position",
          "items",
          "subindicators",
        ];
        let clonedItem = { id: guid(), position: this.surveyObj.items.length };

        for (let key in this.selectedItem) {
          if (columns.indexOf(key) == -1) {
            clonedItem[key] = this.selectedItem[key];
          }

          if (key == "text") {
            let prefix = "";
            if (this.selectedItem.type == "TEXT") prefix = "TEXT";
            if (this.selectedItem.type == "PAGEBREAK") prefix = "PAGEBREAK";
            if (this.selectedItem.type == "QUESTION") prefix = "QUES";
            if (this.selectedItem.type == "MATRIX") prefix = "MATRIX";
            if (this.selectedItem.type == "SUCCESSFACTOR") prefix = "SF";
            if (this.selectedItem.type == "SKIP") prefix = "SKIP";
            clonedItem[key] = this.surveyObj.getSurveyItemText(
              this.surveyObj.items,
              "",
              prefix,
            );
          }
        }

        for (let key of ["items", "subindicators"]) {
          if (this.selectedItem[key]) {
            let subcolumns = ["id", "text", "position", "parent"];

            clonedItem[key] = [];

            for (let subItem of this.selectedItem[key]) {
              let clonedSubItem = {
                id: guid(),
                position: clonedItem[key].length,
                parent: clonedItem.id,
              };

              for (let subkey in subItem) {
                if (subcolumns.indexOf(subkey) == -1)
                  clonedSubItem[subkey] = subItem[subkey];
                if (subkey == "text") {
                  let subprefix = "";

                  if (key == "items") {
                    if (subItem.type == "INDICATOR") subprefix = "IND";
                    if (subItem.type == "ANSWER") subprefix = "ANS";
                    if (subItem.type == "QUESTION") subprefix = "Q";

                    clonedSubItem[subkey] = this.surveyObj.getSurveyItemText(
                      clonedItem[key],
                      clonedItem["text"],
                      subprefix,
                    );
                  }

                  if (key == "subindicators") {
                    clonedSubItem[subkey] = this.surveyObj.getSurveyItemText(
                      clonedItem[key],
                      clonedItem["text"],
                      "SUBINDICATOR",
                    );
                  }
                }
              }

              clonedItem[key].push(clonedSubItem);
            }
          }
        }

        this.templatesService.addTemplateSurveyItem(
          this.templateKey,
          this.surveyObj.id,
          clonedItem,
        );
      }
    }
  }

  onRemoveItem() {
    if (confirm("Are you sure you want to delete this item?") == true) {
      let removeIds = [];
      let removedId = this.selectedItem.id;

      removeIds.push(removedId);

      if (this.selectedItem.parent) {
        for (let surveyItem of this.surveyObj.items) {
          if (surveyItem.id == this.selectedItem.parent) {
            let removedPos = surveyItem.items.indexOf(this.selectedItem);
            surveyItem.items.splice(removedPos, 1);
            this.templatesService.updateTemplateSurveyItem(
              this.templateKey,
              this.surveyObj.id,
              surveyItem,
            );

            this.selectedItem = null;
            for (let pos in [removedPos, removedPos - 1, 0]) {
              if (surveyItem.items[pos]) {
                if (surveyItem.items[pos].id != removedId) {
                  this.selectedItem = surveyItem.items[pos];
                  break;
                }
              }
            }
          }
        }
      } else {
        let removedPos = this.surveyObj.items.indexOf(this.selectedItem);

        if (this.selectedItem.items) {
          for (let subitem of this.selectedItem.items) {
            removeIds.push(subitem.id);
          }
        }

        this.templatesService
          .deleteTemplateSurveyItem(
            this.templateKey,
            this.surveyObj.id,
            this.selectedItem.key,
          )
          .then((res) => {
            if (this.surveyObj.items.length > 0) {
              if (this.surveyObj.items[removedPos])
                this.selectedItem = this.surveyObj.items[removedPos];
              else
                this.selectedItem =
                  this.surveyObj.items[this.surveyObj.items.length - 1];
            } else {
              this.selectedItem = null;
            }
          });
      }

      //this.CheckAndRemoveRelation(removeIds)
    }
  }

  onItemSelected(item: any) {
    this.selectedItem = item.item;

    if (item.item.hasOwnProperty("parent")) {
      for (let surveyItem of this.surveyObj.items) {
        if (surveyItem.id == item.item.parent) {
          this.selectedItemParent = surveyItem;
        }
      }
    } else {
      this.selectedItemParent = item.item;
    }
  }

  ParseItemId(itemid: string): string {
    if (itemid == "RESPONDENT") {
      return "Respondent";
    } else {
      for (let item of this.surveyObj.items) {
        if (item.id == itemid) {
          return item.text;
        } else {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == itemid) {
                return subitem.text;
              }
            }
          }
        }
      }
    }
  }

  ParseSubItemId(itemid: string, subitemid: string): string {
    if (itemid == "RESPONDENT") {
      for (let segment of this.surveyObj.segments) {
        if (segment.hasOwnProperty("items")) {
          for (let subitem of segment.items) {
            if (subitem.id == subitemid) {
              return subitem.text;
            }
          }
        }
      }
    } else {
      for (let item of this.surveyObj.items) {
        if (item.id == itemid) {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == subitemid) {
                return subitem.text;
              }
            }
          }
        }
        if (item.type == "SUCCESSFACTOR" || item.type == "MATRIX") {
          if (item.hasOwnProperty("items")) {
            for (let subitem of item.items) {
              if (subitem.id == itemid) {
                if (subitem.type == "INDICATOR")
                  return subitemid.substr(33, subitem.text.length);
                if (subitem.type == "QUESTION") {
                  for (let subindicator of item.subindicators) {
                    if (subitemid.indexOf(subindicator.id) > -1)
                      return (
                        subindicator.text +
                        subitemid.substr(66, subitem.text.length)
                      );
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  onDeleteRelationship(item: any, relationship: any) {
    let index = item.relationships.indexOf(relationship);
    item.relationships.splice(index, 1);
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      item,
    );
  }

  onItemChanged(item: any) {
    this.templatesService.updateTemplateSurveyItem(
      this.templateKey,
      this.surveyObj.id,
      this.selectedItemParent,
    );
  }

  onOpenLanguageWindow() {
    this.languageWindowActive = true;
  }

  onCloseLanguageWindow() {
    this.languageWindowActive = false;
    this.onAddLanguageQuestion();
  }

  onCloseImportWindow() {
    this.importWindowActive = false;
  }

  onCloseExportWindow() {
    this.exportWindowActive = false;
  }

  onGoToTemplatesList() {
    this.router.navigate(["/templates"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }

  onViewSurvey() {}
}
