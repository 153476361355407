import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { SurveysService } from "../../services/surveys.service";
import { UsersService } from "../../services/users.service";
import { JobsService } from "../../services/jobs.service";
import { AuthService } from "../../services/auth.service";
import { UploaderService } from "../../services/uploader.service";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";
import { map } from "rxjs/operators";
import { guid } from "../../shared/utils";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-client-info",
  templateUrl: "./client-info.component.html",
  styleUrls: ["./client-info.component.css"],
})
export class ClientInfoComponent implements OnInit {
  initialized: boolean = false;
  surveys: any = [];
  client: any = {
    info: {
      id: null,
      name: "",
      email: "",
      project: "",
      role: "client",
      image: "",
    },
    settings: {},
  };
  setting: any = {
    survey: null,
    blockMailing: false,
    mailing: null,
    blockDashboard: false,
    dashboard: "",
    blockUploads: false,
    blockReports: false,
  };
  mailings: any = [];
  iconWindowActive: boolean = false;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  HOST: string = environment.host;

  constructor(
    private surveysService: SurveysService,
    private usersService: UsersService,
    private authService: AuthService,
    private uploaderService: UploaderService,
    private jobsService: JobsService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    let client = this.activateRoute.snapshot.params["client"];

    if (client) {
      this.InitializeClient(client);
    } else {
      this.initialized = true;
    }

    this.InitializeSurveysList();
  }

  InitializeClient(client: string) {
    this.usersService
      .getUser(client)
      .valueChanges()
      .subscribe((user) => {
        if (user) {
          this.client = user;
        }

        this.initialized = true;
      });
  }

  InitializeSurveysList() {
    this.surveysService
      .getSurveysList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((surveys) => {
        this.surveys = [];

        for (let survey of surveys) {
          this.surveys.push({ id: survey.key, name: survey.info.name });
        }

        this.surveys.sort((a, b) => (a.name > b.name ? 1 : -1));
      });
  }

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public onCloseIconWindow() {
    this.iconWindowActive = false;
  }

  public onSaveProfileIcon() {
    let formData = new FormData();
    formData.append("file", base64ToFile(this.croppedImage), "image");
    formData.append("user", this.client.info.id);
    formData.append("session", this.authService.GetCurrentSession());

    this.uploaderService.uploadProfileIcon(formData).subscribe((res) => {
      if (res) {
        if (res[0]) {
          this.client.info.icon = res[0];
        }
      }
    });

    this.iconWindowActive = false;
  }

  GetSurveyName(id: string): string {
    for (let survey of this.surveys) {
      if (survey.id == id) return survey.name;
    }
    return "";
  }

  getClientSettings(): any[] {
    let settings = [];
    if (this.client.settings) {
      for (let survey in this.client.settings) {
        settings.push(this.client.settings[survey]);
      }
    }
    return settings;
  }

  onChangeSurvey(dataItem: any) {
    this.surveysService
      .getSurveyMailings(dataItem)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((mailings) => {
        this.mailings = mailings;

        this.mailings.sort((a, b) => (a.position > b.position ? 1 : -1));

        this.initialized = true;
      });
  }

  onAddSetting() {
    if (!this.client.hasOwnProperty("settings")) {
      this.client["settings"] = {};
    }
    this.client.settings[this.setting.survey] = this.setting;
    this.setting = {
      survey: null,
      blockMailing: false,
      mailing: null,
      blockDashboard: false,
      dashboard: "",
      blockUploads: false,
      blockReports: false,
    };
  }

  onRemoveSettings(setting: any) {
    delete this.client.settings[setting.survey];
  }

  SaveClient() {
    if (this.client.info.id) {
      this.usersService.updateUserInfo(this.client.info.id, this.client.info);
      this.usersService.updateUserSettings(
        this.client.info.id,
        this.client.settings,
      );
    } else {
      let id = guid();
      this.client.info.id = id;
      this.usersService.updateUserInfo(this.client.info.id, this.client.info);
      this.usersService.updateUserSettings(
        this.client.info.id,
        this.client.settings,
      );
      this.jobsService.addJob({
        type: "create_client_authorization",
        state: "WAITING_FOR_PROCESS",
        data: { client: id, user: this.authService.GetCurrentUser() },
      });
    }
    this.router.navigate(["/clients"]);
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onGoToClientsList() {
    this.router.navigate(["/clients"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
