import { Injectable, Injector } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class AppService {
  constructor(private injector: Injector) {}

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(AuthService)
        .isAuthenticated()
        .toPromise()
        .then((res) => {
          resolve();
        });
    });
  }

  /*
    initializeApp(): Promise<any> {
        return new Promise(((resolve, reject) => {
            this.injector.get(AuthService).isLoggedIn()
                .toPromise()
                .then(res => {
                    resolve();
                })
        }))
    }

*/
}
