<div class="contentblock">
  <h3>Info</h3>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">{{ item.text }}</label>
    <div class="col-sm-10"></div>
  </div>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">Change To</label>
    <div class="col-sm-10">
      <input name="text" [(ngModel)]="newname" />
    </div>
  </div>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label"
      >Update Respondents</label
    >
    <div class="col-sm-10">
      <kendo-switch
        name="updaterespondents"
        [(ngModel)]="updaterespondents"
      ></kendo-switch>
    </div>
  </div>

  <div class="form-group row">
    <label for="label" class="col-sm-2 col-form-label">&nbsp;</label>
    <div class="col-sm-10">
      <button type="button" (click)="UpdateSegmentInfo()">Update</button>
    </div>
  </div>
</div>
