<div *ngIf="initialized">
  <div class="header">
    <div class="menu pull-right">
      <ng-template [ngIf]="!isNewSurvey">
        <button
          (click)="onCopySurvey()"
          class="btn-actions"
          title="Copy survey"
        >
          <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
        </button>

        <button
          (click)="onCloseSurvey()"
          class="btn-actions"
          title="Close survey"
        >
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </button>

        <button
          (click)="onCleanSurvey()"
          class="btn-actions"
          title="Clean Survey"
        >
          <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
        </button>
      </ng-template>

      <button (click)="onGoToSurveyList()" class="btn-actions" title="Surveys">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>
    </div>
  </div>

  <div class="column-main">
    <div class="maincontent">
      <h1>{{ surveyObj.info.name }}</h1>

      <div class="contentblock">
        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Name</label>
          <div class="col-sm-10">
            <input name="text" [(ngModel)]="surveyObj.info.name" />
          </div>
        </div>

        <div class="form-group row">
          <label for="organization-name" class="col-sm-2 col-form-label"
            >Organization name</label
          >
          <div class="col-sm-10">
            <input
              id="organization-name"
              name="organization-name"
              [(ngModel)]="surveyObj.info.organizationname"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="prefix" class="col-sm-2 col-form-label"
            >Login prefix</label
          >
          <div class="col-sm-10">
            <input name="text" [(ngModel)]="surveyObj.info.prefix" />
          </div>
        </div>

        <div class="form-group row">
          <label for="startdate" class="col-sm-2 col-form-label"
            >Start Date</label
          >
          <div class="col-sm-10">
            <kendo-datepicker
              [format]="'MM/dd/yyyy'"
              [(value)]="startdate"
            ></kendo-datepicker>
            End Date
            <kendo-datepicker
              [format]="'MM/dd/yyyy'"
              [(value)]="enddate"
            ></kendo-datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label for="experience" class="col-sm-2 col-form-label"
            >Experience</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="experience"
              [(ngModel)]="surveyObj.info.subindicators.experience"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="importance" class="col-sm-2 col-form-label"
            >Importance</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="importance"
              [(ngModel)]="surveyObj.info.subindicators.importance"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="performance" class="col-sm-2 col-form-label"
            >Performance</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="performance"
              [(ngModel)]="surveyObj.info.subindicators.performance"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="comment" class="col-sm-2 col-form-label">Comment</label>
          <div class="col-sm-10">
            <kendo-switch
              name="comment"
              [(ngModel)]="surveyObj.info.subindicators.comment"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="branche" class="col-sm-2 col-form-label">Branche</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="branche"
              [data]="branches"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.branche"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="monitor" class="col-sm-2 col-form-label">Monitor</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="monitor"
              [data]="monitors"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.monitor"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="scale" class="col-sm-2 col-form-label">Scale</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="scale"
              [data]="scales"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.scale"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="access" class="col-sm-2 col-form-label">Access</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="access"
              [data]="access"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.access"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="type" class="col-sm-2 col-form-label">Type</label>
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="type"
              [data]="types"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.type"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row" *ngIf="surveyObj.info.type == 'ANONYMOUS'">
          <label for="limit" class="col-sm-2 col-form-label">Limit</label>
          <div class="col-sm-10">
            <input type="number" [(ngModel)]="surveyObj.info.limit" />
          </div>
        </div>

        <div class="form-group row" *ngIf="surveyObj.info.type == 'ANONYMOUS'">
          <label for="looping" class="col-sm-2 col-form-label"
            >Use Looping</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="looping"
              [(ngModel)]="surveyObj.info.looping"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="trending" class="col-sm-2 col-form-label">Trending</label>
          <div class="col-sm-10">
            <kendo-switch
              name="trending"
              [(ngModel)]="surveyObj.info.trending"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row" *ngIf="surveyObj.info.trending">
          <label for="style" class="col-sm-2 col-form-label"
            >Smallest trending period</label
          >
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="trendingperiod"
              [data]="trendingperiods"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.trendingperiod"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="public" class="col-sm-2 col-form-label"
            >Public (non-anonymous)</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="public"
              [(ngModel)]="surveyObj.info.public"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="customize" class="col-sm-2 col-form-label"
            >Customize</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="colors"
              [(ngModel)]="surveyObj.info.customize"
            ></kendo-switch>
          </div>
        </div>

        <ng-container *ngIf="surveyObj.info.customize">
          <div class="form-group row" *ngFor="let color of stylecolors">
            <label for="{{ color.id }}" class="col-sm-2 col-form-label">{{
              color.text
            }}</label>
            <div class="col-sm-10">
              <kendo-colorpicker
                [format]="format"
                [gradientSettings]="settings"
                [value]="GetColorValue(color.id)"
                (valueChange)="onChangeColor(color.id, $event)"
              ></kendo-colorpicker>
            </div>
          </div>
        </ng-container>

        <div class="form-group row">
          <label for="output" class="col-sm-2 col-form-label"
            >Generate output automatically</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="output"
              [(ngModel)]="surveyObj.info.output"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row" *ngIf="surveyObj.info.output">
          <label for="pdflanguage" class="col-sm-2 col-form-label"
            >Pdf language</label
          >
          <div class="col-sm-10">
            <kendo-dropdownlist
              name="pdflanguage"
              [data]="languages"
              [textField]="'name'"
              [valueField]="'code'"
              [valuePrimitive]="true"
              [(ngModel)]="surveyObj.info.pdflanguage"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <img
                  src="assets/images/flags/{{ dataItem?.code }}.png"
                  border="0"
                />&nbsp;{{ dataItem?.name }}
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <img
                  src="assets/images/flags/{{ dataItem?.code }}.png"
                  border="0"
                />&nbsp;{{ dataItem?.name }}
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group row">
          <label for="segmentlimit" class="col-sm-2 col-form-label">
            Ignore segments with ≤
          </label>
          <div class="col-sm-10">
            <input type="number" [(ngModel)]="surveyObj.info.segmentlimit" />
            &nbsp; respondents
          </div>
        </div>

        <div class="form-group row">
          <label for="translations" class="col-sm-2 col-form-label"
            >Activate translations</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="output"
              [(ngModel)]="surveyObj.info.translations"
            ></kendo-switch>
            <span *ngIf="surveyObj.info.translations">
              &nbsp;Use base language &nbsp;
              <kendo-dropdownlist
                name="baselanguage"
                [data]="baselanguages"
                [textField]="'name'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                [(ngModel)]="surveyObj.info.baselanguage"
              >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <img
                    src="assets/images/flags/{{ dataItem?.code }}.png"
                    border="0"
                  />&nbsp;{{ dataItem?.name }}
                </ng-template>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <img
                    src="assets/images/flags/{{ dataItem?.code }}.png"
                    border="0"
                  />&nbsp;{{ dataItem?.name }}
                </ng-template>
              </kendo-dropdownlist>
              &nbsp; Minimum of &nbsp;
              <input type="number" [(ngModel)]="surveyObj.info.mincomments" />
              &nbsp; comments
            </span>
          </div>
        </div>

        <div class="form-group row">
          <label for="segmentprefix" class="col-sm-2 col-form-label"
            >Use prefix in segment</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="segmentprefix"
              [(ngModel)]="surveyObj.info.segmentprefix"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="powerpoint" class="col-sm-2 col-form-label"
            >PowerPoint</label
          >
          <div class="col-sm-10">
            <kendo-switch
              name="powerpoint"
              [(ngModel)]="surveyObj.info.powerpoint"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row">
          <label for="mobile" class="col-sm-2 col-form-label">Mobile</label>
          <div class="col-sm-10">
            <kendo-switch
              name="mobile"
              [(ngModel)]="surveyObj.info.mobile"
            ></kendo-switch>
          </div>
        </div>

        <div class="form-group row" *ngIf="surveyObj.info.mobile">
          <label for="npstarget" class="col-sm-2 col-form-label"
            >NPS Target</label
          >
          <div class="col-sm-10">
            <input type="number" [(ngModel)]="surveyObj.info.npstarget" />
          </div>
        </div>

        <div class="form-group row" *ngIf="surveyObj.info.mobile">
          <label for="satisfactiontarget" class="col-sm-2 col-form-label"
            >Satisfaction Target</label
          >
          <div class="col-sm-10">
            <input
              type="number"
              [(ngModel)]="surveyObj.info.satisfactiontarget"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="save" class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <button type="button" (click)="SaveSurvey()">Save Survey</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
