import { Component, OnInit } from "@angular/core";
import { JobsService } from "../services/jobs.service";
import { SurveysService } from "../services/surveys.service";
import { UsersService } from "../services/users.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.css"],
})
export class JobsComponent implements OnInit {
  jobs: any = [];
  users: any = {};
  surveys: any = {};
  initialized: boolean = false;

  constructor(
    private surveysService: SurveysService,
    private usersService: UsersService,
    private jobsService: JobsService,
  ) {}

  ngOnInit(): void {
    this.InitializeSurveysList();
    this.InitializeUsers();
    this.InitializeJobList();
  }

  InitializeJobList() {
    this.jobsService
      .getJobsList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((jobs) => {
        this.jobs = jobs;
        this.initialized = true;
      });
  }

  InitializeSurveysList() {
    this.surveysService
      .getSurveysList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((surveys) => {
        this.surveys = {};

        for (let survey of surveys) {
          this.surveys[survey.key] = survey.info.name;
        }
      });
  }

  InitializeUsers() {
    this.usersService
      .getUsersList()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((users) => {
        this.users = {};

        for (let user of users) {
          this.users[user.key] = user.info.name;
        }
      });
  }

  GetUserName(dataItem) {
    if (this.users.hasOwnProperty(dataItem.data.user)) {
      return this.users[dataItem.data.user];
    }
    return "";
  }

  GetSurveyName(dataItem) {
    if (this.surveys.hasOwnProperty(dataItem.data.survey)) {
      return this.surveys[dataItem.data.survey];
    }
    return "";
  }

  onDeleteJob(dataItem) {
    if (confirm("Are you sure you want delete this job?") == true) {
      this.jobsService.deleteJob(dataItem.key);
    }
  }
}
