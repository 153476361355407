import { BrowserModule } from "@angular/platform-browser";
import { IntlModule } from "@progress/kendo-angular-intl";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";

import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";

import { environment } from "../environments/environment";

import { CommonModule } from "@angular/common";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { MenusModule } from "@progress/kendo-angular-menu";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { NgxPaginationModule } from "ngx-pagination";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { PopupModule } from "@progress/kendo-angular-popup";
import { SortableModule } from "@progress/kendo-angular-sortable";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { TreeListModule } from "@progress/kendo-angular-treelist";
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";

import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faCalendar,
  faObjectGroup,
  faObjectUngroup,
} from "@fortawesome/free-regular-svg-icons";
import {
  faHome,
  faDatabase,
  faPlus,
  faMinus,
  faCheck,
  faTimes,
  faKey,
  faForward,
  faBackward,
  faBell,
  faClock,
  faBars,
  faCaretRight,
  faCaretDown,
  faTable,
  faChartPie,
  faFileArchive,
  faInfo,
  faMagic,
  faDna,
  faUsers,
  faDownload,
  faEnvelope,
  faEye,
  faFlag,
  faAngleDown,
  faArrowRight,
  faLevelDownAlt,
  faMapPin,
  faQuestionCircle,
  faCodeBranch,
  faCog,
  faClone,
  faTrash,
  faSignInAlt,
  faSignOutAlt,
  faFileAlt,
  faThList,
  faStickyNote,
  faPaperPlane,
  faExchangeAlt,
  faTextWidth,
  faNetworkWired,
  faUnlock,
  faInbox,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";

import { AppService } from "./services/app.service";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./auth.guard";

import { HttpClientModule } from "@angular/common/http";
import { CalendarModule } from "@progress/kendo-angular-dateinputs";
import { RadialGaugeModule } from "@progress/kendo-angular-gauges";
import { ArcGaugeModule } from "@progress/kendo-angular-gauges";

import { InputsModule } from "@progress/kendo-angular-inputs";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { UploadModule } from "@progress/kendo-angular-upload";

import { ImageCropperModule } from "ngx-image-cropper";

import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";

import { CookieService } from "ngx-cookie-service";

import "hammerjs";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { JobsComponent } from "./jobs/jobs.component";
import { SurveysComponent } from "./surveys/surveys.component";
import { EditorModule } from "@progress/kendo-angular-editor";
import { SurveyInfoComponent } from "./surveys/survey/setup/survey-info/survey-info.component";
import { SurveyCompositionComponent } from "./surveys/survey/setup/survey-composition/survey-composition.component";
import { SurveyLabelsComponent } from "./surveys/survey/setup/survey-labels/survey-labels.component";
import { SurveyMailingsComponent } from "./surveys/survey/setup/survey-mailings/survey-mailings.component";
import { SurveyDashboardComponent } from "./surveys/survey/setup/survey-dashboard/survey-dashboard.component";
import { SurveySegmentsComponent } from "./surveys/survey/setup/survey-segments/survey-segments.component";
import { SurveyTransformationsComponent } from "./surveys/survey/setup/survey-transformations/survey-transformations.component";
import { SurveyRulesComponent } from "./surveys/survey/setup/survey-rules/survey-rules.component";
import { SurveyApiComponent } from "./surveys/survey/setup/survey-api/survey-api.component";
import { SurveyResegmentationsComponent } from "./surveys/survey/setup/survey-resegmentations/survey-resegmentations.component";
import { SurveyRespondentComponent } from "./surveys/survey/setup/survey-respondent/survey-respondent.component";
import { SurveyRespondentsComponent } from "./surveys/survey/setup/survey-respondents/survey-respondents.component";
import { SurveyMailingComponent } from "./surveys/survey/setup/survey-mailing/survey-mailing.component";
import { SurveyRecipientsComponent } from "./surveys/survey/setup/survey-recipients/survey-recipients.component";
import { SurveyMetadataComponent } from "./surveys/survey/setup/survey-metadata/survey-metadata.component";
import { SysusersComponent } from "./sysusers/sysusers.component";
import { SurveyClientkeysComponent } from "./surveys/survey/setup/survey-clientkeys/survey-clientkeys.component";
import { ClientsComponent } from "./clients/clients.component";
import { ClientInfoComponent } from "./clients/client-info/client-info.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { NotificationInfoComponent } from "./notifications/notification-info/notification-info.component";
import { TemplatesComponent } from "./templates/templates.component";
import { TemplateSurveyitemInfoComponent } from "./templates/template-surveyitem-info/template-surveyitem-info.component";
import { TemplateMailingInfoComponent } from "./templates/template-mailing-info/template-mailing-info.component";
import { TemplateChaptermapInfoComponent } from "./templates/template-chaptermap-info/template-chaptermap-info.component";
import { TemplateItemChoiceComponent } from "./templates/template-item-choice/template-item-choice.component";
import { CtrlChapterInfoComponent } from "./surveys/survey/controls/ctrl-chapter-info/ctrl-chapter-info.component";
import { CtrlImportFileComponent } from "./surveys/survey/controls/ctrl-import-file/ctrl-import-file.component";
import { CtrlExportFileComponent } from "./surveys/survey/controls/ctrl-export-file/ctrl-export-file.component";
import { CtrlItemLabelComponent } from "./surveys/survey/controls/ctrl-item-label/ctrl-item-label.component";
import { CtrlItemVisibilityComponent } from "./surveys/survey/controls/ctrl-item-visibility/ctrl-item-visibility.component";
import { CtrlItemOrderComponent } from "./surveys/survey/controls/ctrl-item-order/ctrl-item-order.component";
import { CtrlItemRequiredComponent } from "./surveys/survey/controls/ctrl-item-required/ctrl-item-required.component";
import { CtrlItemRequestedComponent } from "./surveys/survey/controls/ctrl-item-adviced/ctrl-item-requested.component";
import { CtrlItemSettingsComponent } from "./surveys/survey/controls/ctrl-item-settings/ctrl-item-settings.component";
import { CtrlItemInfoComponent } from "./surveys/survey/controls/ctrl-item-info/ctrl-item-info.component";
import { CtrlItemFormComponent } from "./surveys/survey/controls/ctrl-item-form/ctrl-item-form.component";
import { CtrlItemStyleComponent } from "./surveys/survey/controls/ctrl-item-style/ctrl-item-style.component";
import { CtrlItemShuffleComponent } from "./surveys/survey/controls/ctrl-item-shuffle/ctrl-item-shuffle.component";
import { CtrlItemAdditionalComponent } from "./surveys/survey/controls/ctrl-item-additional/ctrl-item-additional.component";
import { CtrlItemAnonymityComponent } from "./surveys/survey/controls/ctrl-item-anonymity/ctrl-item-anonymity.component";
import { CtrlItemMoveComponent } from "./surveys/survey/controls/ctrl-item-move/ctrl-item-move.component";
import { CtrlNavigationNodeComponent } from "./surveys/survey/controls/ctrl-navigation-node/ctrl-navigation-node.component";
import { CtrlSegmentInfoComponent } from "./surveys/survey/controls/ctrl-segment-info/ctrl-segment-info.component";
import { CtrlSegmentFilterComponent } from "./surveys/survey/controls/ctrl-segment-filter/ctrl-segment-filter.component";
import { CtrlSegmentDynamicComponent } from "./surveys/survey/controls/ctrl-segment-dynamic/ctrl-segment-dynamic.component";
import { CtrlSegmentExcludeComponent } from "./surveys/survey/controls/ctrl-segment-exclude/ctrl-segment-exclude.component";
import { HtmlEditorComponent } from "./shared/html-editor/html-editor.component";

export function app_init(appService: AppService) {
  return () => appService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    JobsComponent,
    SurveysComponent,
    SurveyInfoComponent,
    SurveyCompositionComponent,
    SurveyLabelsComponent,
    SurveyMailingsComponent,
    SurveyDashboardComponent,
    SurveySegmentsComponent,
    SurveyTransformationsComponent,
    SurveyRulesComponent,
    SurveyApiComponent,
    SurveyResegmentationsComponent,
    SurveyRespondentComponent,
    SurveyRespondentsComponent,
    SurveyMailingComponent,
    SurveyRecipientsComponent,
    SurveyMetadataComponent,
    SysusersComponent,
    SurveyClientkeysComponent,
    ClientsComponent,
    ClientInfoComponent,
    NotificationsComponent,
    NotificationInfoComponent,
    TemplatesComponent,
    TemplateSurveyitemInfoComponent,
    TemplateMailingInfoComponent,
    TemplateChaptermapInfoComponent,
    TemplateItemChoiceComponent,
    CtrlChapterInfoComponent,
    CtrlImportFileComponent,
    CtrlExportFileComponent,
    CtrlItemLabelComponent,
    CtrlItemVisibilityComponent,
    CtrlItemOrderComponent,
    CtrlItemRequiredComponent,
    CtrlItemRequestedComponent,
    CtrlItemSettingsComponent,
    CtrlItemInfoComponent,
    CtrlItemFormComponent,
    CtrlItemStyleComponent,
    CtrlItemShuffleComponent,
    CtrlItemAdditionalComponent,
    CtrlItemAnonymityComponent,
    CtrlItemMoveComponent,
    CtrlNavigationNodeComponent,
    CtrlSegmentInfoComponent,
    CtrlSegmentFilterComponent,
    CtrlSegmentDynamicComponent,
    CtrlSegmentExcludeComponent,
    HtmlEditorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    IndicatorsModule,
    IntlModule,
    FormsModule,
    LayoutModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(
      environment.firebase,
      environment.firebase.projectId,
    ),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxPaginationModule,
    GridModule,
    ExcelModule,
    ChartsModule,
    TreeViewModule,
    MenusModule,
    DropDownsModule,
    PopupModule,
    SortableModule,
    HttpClientModule,
    CalendarModule,
    RadialGaugeModule,
    ArcGaugeModule,
    DialogsModule,
    InputsModule,
    DateInputsModule,
    UploadModule,
    FontAwesomeModule,
    ImageCropperModule,
    TreeListModule,
    ScrollViewModule,
    ProgressBarModule,
    PDFExportModule,
    EditorModule,
  ],
  providers: [
    CookieService,
    AuthService,
    AuthGuard,
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: app_init,
      deps: [AppService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faHome,
      faDatabase,
      faPlus,
      faMinus,
      faCheck,
      faTimes,
      faKey,
      faForward,
      faBackward,
      faBell,
      faClock,
      faBars,
      faCaretRight,
      faCaretDown,
      faTable,
      faChartPie,
      faCalendar,
      faFileArchive,
      faInfo,
      faMagic,
      faDna,
      faUsers,
      faDownload,
      faEnvelope,
      faEye,
      faFlag,
      faAngleDown,
      faArrowRight,
      faLevelDownAlt,
      faMapPin,
      faQuestionCircle,
      faObjectGroup,
      faObjectUngroup,
      faCog,
      faCodeBranch,
      faClone,
      faTrash,
      faSignInAlt,
      faSignOutAlt,
      faFileAlt,
      faThList,
      faStickyNote,
      faPaperPlane,
      faExchangeAlt,
      faTextWidth,
      faNetworkWired,
      faUnlock,
      faInbox,
      faEnvelopeOpen,
    );
  }
}
