<div *ngIf="initialized">
  <div class="contentblock">
    <div class="form-group row">
      <label for="template" class="col-sm-2 col-form-label">Template</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          name="template"
          [data]="templates"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="selectedItem"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row" *ngIf="selectedItem">
      <label for="save" class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10">
        <button type="button" (click)="onChooseTemplate()">
          ChooseTemplate
        </button>
      </div>
    </div>
  </div>
</div>
