import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { SurveysService } from "../../../../services/surveys.service";
import { AuthService } from "../../../../services/auth.service";
import { SurveyObject } from "../../../../shared/survey";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-survey-labels",
  templateUrl: "./survey-labels.component.html",
  styleUrls: ["./survey-labels.component.css"],
})
export class SurveyLabelsComponent implements OnInit {
  surveyObj = new SurveyObject();

  initialized: boolean = false;
  languageWindowActive: boolean = false;
  importWindowActive: boolean = false;
  exportWindowActive: boolean = false;
  files: any = [];
  extensions: any = [".xlsx"];
  extensionsimg: any = [".gif", ".png", ".jpeg", ".bmp"];
  importbuttons: boolean = false;
  uploadedFileUrl: string = environment.host + "/static/uploads/library";

  constructor(
    private surveysService: SurveysService,
    private authService: AuthService,
    public router: Router,
    public activateRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.surveyObj.id = this.activateRoute.snapshot.params["survey"];

    this.InitializeSurveyInfo();
    this.InitializeSurveyLibrary();
  }

  InitializeSurveyInfo() {
    this.surveysService
      .getSurveyInfo(this.surveyObj.id)
      .valueChanges()
      .subscribe((survey) => {
        if (survey.info) this.surveyObj.info = survey.info;
      });
  }

  InitializeSurveyLibrary() {
    this.surveysService
      .getSurveyLibrary(this.surveyObj.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() })),
        ),
      )
      .subscribe((files) => {
        console.log(files);
        this.files = files;
        this.initialized = true;
      });
  }

  onCloseImportWindow() {
    this.importWindowActive = false;
  }

  onCloseExportWindow() {
    this.exportWindowActive = false;
  }

  onGoToSurveyCompose() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/compose"]);
  }

  onGoToSurveySegmentation() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/segments"]);
  }

  onGoToSurveyMailing() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/mailings"]);
  }

  onGoToSurveyDashbaord() {
    this.router.navigate(["/survey/" + this.surveyObj.id + "/dashboard"]);
  }

  onGoToSurveyList() {
    this.router.navigate(["/surveys"]);
  }

  onSignOut() {
    this.authService.SignOut();
  }
}
