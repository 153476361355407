import { Component, OnChanges, Input } from "@angular/core";
import { SurveysService } from "../../../../services/surveys.service";
import { TemplatesService } from "../../../../services/templates.service";
import { SYS_LANGAUGES } from "../../../../shared/language";

@Component({
  selector: "app-ctrl-item-label",
  templateUrl: "./ctrl-item-label.component.html",
  styleUrls: ["./ctrl-item-label.component.css"],
})
export class CtrlItemLabelComponent implements OnChanges {
  constructor(
    private surveysService: SurveysService,
    private templatesService: TemplatesService,
  ) {}

  @Input() survey: any;
  @Input() item: any;
  @Input() labels: any;
  @Input() template: boolean = false;

  languages: any = SYS_LANGAUGES;
  language: string = "nl";
  label: any = "";
  subindicatorsOptions: any = [];
  selectedSubindicator: any = null;

  ngOnChanges(): void {
    this.label = this.GetItemLabel(this.item.id);

    if (this.item.type == "MATRIX") {
      this.subindicatorsOptions = [];
      if (this.item.hasOwnProperty("subindicators")) {
        for (let subindicator of this.item.subindicators) {
          this.subindicatorsOptions.push({
            id: subindicator.id,
            text: subindicator.text,
          });
          for (let pos = 1; pos <= parseInt(subindicator.scale); pos++) {
            this.subindicatorsOptions.push({
              id: subindicator.id + "_" + pos,
              text: subindicator.text + "_" + pos,
            });
          }
        }
      }
    }
  }

  GetItemLabel(id: string): string {
    let itemlabel = this.labels.getItemLabelForLanguage(id, this.language);
    if (itemlabel != id)
      return this.labels.getItemLabelForLanguage(id, this.language);
    return "";
  }

  onSelectLanguage(event: any) {
    let id = this.item.id;
    if (this.item.type == "MATRIX") {
      id = this.selectedSubindicator;
    }
    this.label = this.GetItemLabel(id);
  }

  onSelectSubindicator(event: any) {
    this.label = this.GetItemLabel(this.selectedSubindicator);
  }

  SaveLabel() {
    let id = this.item.id;
    if (this.item.type == "MATRIX" && this.selectedSubindicator) {
      id = this.selectedSubindicator;
    }
    if (this.template)
      this.templatesService.updateTemplateSurveyItemLabel(
        this.survey.id,
        id,
        this.language,
        this.label,
      );
    else
      this.surveysService.updateSurveyItemLabelForLanguage(
        this.survey.id,
        id,
        this.language,
        this.label,
      );
  }
}
