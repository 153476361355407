<div *ngIf="initialized">
  <div class="header">
    <div class="icons pull-right">
      <div>
        <app-notifications></app-notifications>
      </div>
    </div>
    <div class="menu">
      <span>
        <button
          (click)="onGoToSurveyLabels()"
          class="btn btn-blue"
          title="Labels"
        >
          <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyCompose()"
          class="btn btn-green"
          title="Compose"
        >
          <fa-icon [icon]="['fas', 'magic']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveySegmentation()"
          class="btn btn-yellow"
          title="Segmentation"
        >
          <fa-icon [icon]="['fas', 'chart-pie']"></fa-icon>
        </button>

        <button
          (click)="onGoToSurveyDashbaord()"
          class="btn btn-purple"
          title="Dashbaord"
        >
          <fa-icon [icon]="['fas', 'table']"></fa-icon>
        </button>
      </span>

      <button
        (click)="onAddMailing()"
        class="btn-actions first"
        title="Add mailing"
      >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
      </button>

      <button
        (click)="templateWindowActive = true"
        class="btn-actions"
        title="Add template"
      >
        <fa-icon [icon]="['fas', 'plus']" style="color: #ffb752"></fa-icon>
      </button>

      <button
        (click)="onUpdateTimes()"
        class="btn-actions"
        title="Update times"
      >
        <fa-icon [icon]="['far', 'calendar']"></fa-icon>
      </button>

      <span *ngIf="selectedItem">
        <button
          (click)="onViewMailing()"
          class="btn-actions"
          title="View mailing"
        >
          <fa-icon [icon]="['fas', 'info']"></fa-icon>
        </button>
        <button
          (click)="importWindowActive = true"
          class="btn-actions"
          title="Import recipients"
        >
          <fa-icon [icon]="['fas', 'download']"></fa-icon>
        </button>
        <button
          (click)="onViewRecipients()"
          class="btn-actions"
          title="View recipients"
        >
          <fa-icon [icon]="['fas', 'users']"></fa-icon>
        </button>
        <button
          (click)="onSendMailing()"
          class="btn-actions"
          title="Send mailing"
        >
          <fa-icon
            [icon]="['fas', 'paper-plane']"
            style="color: #dd5a43"
          ></fa-icon>
        </button>
        <button
          (click)="onCopyMailing()"
          class="btn-actions"
          title="Copy mailing"
        >
          <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
        </button>
        <button
          (click)="onViewClientKeys()"
          class="btn-actions"
          title="Client keys"
        >
          <fa-icon [icon]="['fas', 'key']"></fa-icon>
        </button>
        <button
          (click)="onRemoveMailing()"
          class="btn-actions"
          title="Remove mailing"
        >
          <fa-icon [icon]="['fas', 'trash']" style="color: #dd5a43"></fa-icon>
        </button>
      </span>

      <button (click)="onGoToSurveyList()" class="btn-actions" title="Surveys">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>

      <button (click)="onSignOut()" class="btn-actions" title="Sign out">
        <fa-icon
          [icon]="['fas', 'sign-out-alt']"
          style="color: #dd5a43"
        ></fa-icon>
      </button>

      <span *ngIf="selectedItem">
        <span *ngIf="selectedItem.progress > 0">
          <kendo-progressbar
            [min]="0"
            [max]="100"
            [value]="selectedItem.progress"
          ></kendo-progressbar>
          %
        </span>
      </span>
    </div>
  </div>
  <div class="column-left">
    <app-ctrl-navigation-node
      [list]="mailings"
      (itemSelected)="onItemSelected($event)"
    ></app-ctrl-navigation-node>
  </div>
  <div class="column-main">
    <div class="maincontent">
      <h1>
        {{ surveyObj.info.name }}
        <span class="selection" *ngIf="selectedItem"
          >>> {{ selectedItem.text }}</span
        >
      </h1>

      <div *ngIf="selectedItem">
        <app-survey-mailing
          [survey]="surveyObj"
          [item]="selectedItem"
          [times]="times"
          (itemChanged)="onUpdateMailing($event)"
          *ngIf="showSectionType == 'MAILING'"
        ></app-survey-mailing>

        <app-survey-recipients
          [survey]="surveyObj"
          [mailing]="selectedItem"
          [columns]="columns"
          [respondents]="respondents"
          [times]="times"
          *ngIf="showSectionType == 'RECIPIENTS'"
        ></app-survey-recipients>

        <app-survey-clientkeys
          [survey]="surveyObj"
          [mailing]="selectedItem"
          *ngIf="showSectionType == 'CLIENTKEYS'"
        ></app-survey-clientkeys>
      </div>
    </div>

    <kendo-window
      [top]="200"
      title="Import recipients"
      *ngIf="importWindowActive"
      (close)="importWindowActive = false"
    >
      <app-ctrl-import-file
        [survey]="surveyObj"
        [type]="'recipients'"
        [extensions]="extensions"
        [attached]="attached"
        (onWindowClose)="onCloseImportWindow()"
      ></app-ctrl-import-file>
    </kendo-window>

    <kendo-window
      [top]="200"
      title="Templates"
      *ngIf="templateWindowActive"
      (close)="templateWindowActive = false"
    >
      <app-template-item-choice
        [type]="'mailings'"
        (itemChoosed)="onAddTemplateItem($event)"
      ></app-template-item-choice>
    </kendo-window>
  </div>
</div>
